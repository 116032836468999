import {CheckoutSession} from '@raven';
import {PatronGuarantor} from './patron-guarantor';
import {PatronAddress} from './patron-address';
import {PatronCategory} from './patron-category';
import {PatronRule} from './patron-rule';


export enum PATRON_EMAIL_TYPE {
  BUSINESS = 'BUSINESS',
  HOME = 'HOME',
  OTHER = 'OTHER',
}

export enum PATRON_PHONE_TYPE {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
}

export class Patron {
  id?: number;
  googleUid?: string;
  organizationId?: number;
  branchId?: number;
  patronCategoryId?: number;
  email?: string;
  notificationEmail?: string;
  barcode?: string;
  displayName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  photoURL?: string;
  pinCode?: string;
  password?: string;
  initials?: string;
  salutation?: string;
  gender?: string;
  dateOfBirth?: string;
  accountExpiration?: string;
  preferredLanguage?: string;
  pinGenerated?: boolean;
  legalsAccepted?: boolean;
  signedLegalURI?: string;

  phone1?: string;
  phone1Type?: PATRON_PHONE_TYPE;

  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;

  emailVerified?: boolean;
  mobileNumberVerified?: boolean;
  optInReadingHistory?: boolean;
  unsubscribeAllNotifications?: boolean;

  timezone?: string;
  passwordResetToken?: string;
  tokenExpiration?: string;
  lastLogin?: string;

  addresses?: PatronAddress[];
  category?: PatronCategory;
  guarantors?: PatronGuarantor[];
  //  ledgers?: PatronLedger[];

  checkoutSession?: CheckoutSession;
  patronRule?: PatronRule;
  accountBalance?: number;
  maxHolds?: number;
  patronStatistics?: PatronStatistics;
  // for history tab
  lastLocalAccess?: string;

  hasNotificationPreferences?: boolean;
  preventCheckout?: boolean;
}

export class PatronStatistics {
  openHolds?: number = 0;
  openLoans?: number = 0;
  claimedReturnedCount?: number = 0;
}

export class PatronAlert {
  restrictions: string[] = [];
  warnings: string[] = [];
  // Count of issues that are worth noting to staff
  insightCount?: number = 0;
  restrictCheckout = false;
  restrictHolds = false;
  restrictRenew = false;
  restrictReasons: Array<string> = [];
}

export enum PATRON_RESTRICT_REASON {
  MAX_FEE_LIMIT = 'MAX_FEE_LIMIT',
  ACCOUNT_EXPIRED = 'ACCOUNT_EXPIRED',
}

export enum BLOCK_CODE {
  LIBRARY_DEFINED_PATRON = 'LIBRARY_DEFINED_PATRON',
  FREE_TEXT_PATRON = 'FREE_TEXT_PATRON',
  MAX_FEE_LIMIT = 'MAX_FEE_LIMIT',
  MAX_ITEMS_OUT = 'MAX_ITEMS_OUT',
  MAX_ITEMS_OVERDUE = 'MAX_ITEMS_OVERDUE',
  MAX_LOST = 'MAX_LOST',
  MAX_RENEW_COUNT = 'MAX_RENEW_COUNT',
  MAX_HOLD_COUNT = 'MAX_HOLD_COUNT',
  PATRON_MAX_HOLDS_ON_BIB = 'PATRON_MAX_HOLDS_ON_BIB',
  CARD_EXPIRED = 'CARD_EXPIRED',
  ADDRESS_VERIFICATION_REQUIRED = 'ADDRESS_VERIFICATION_REQUIRED',
  GUARDIAN_REQUIRED = 'GUARDIAN_REQUIRED',
  HOLD_BLOCKS_CHECKOUT = 'HOLD_BLOCKS_CHECKOUT',
  HOLD_NOT_UNCLAIMED = 'HOLD_NOT_UNCLAIMED',
  ITEM_NOT_LOANABLE = 'ITEM_NOT_LOANABLE',
  BIB_NO_CIRCULATING_COPIES = 'BIB_NO_CIRCULATING_COPIES',
  CIRC_ITEMS_EXIST = 'CIRC_ITEMS_EXIST',
  LIBRARY_DEFINED_CIRCULATION_ITEM = 'LIBRARY_DEFINED_CIRCULATION_ITEM',
  FREE_TEXT_CIRCULATION_ITEM = 'FREE_TEXT_CIRCULATION_ITEM',
}

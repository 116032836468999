import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MxSelector, NO_NAME_PREFIX} from '@store/store.types';
import {BranchState} from './branch.state';
import {Branch, BRANCH_FEATURE_KEY} from '@store/branches/branch.types';
import {generateLoadStatusSelectorsFor, generateSelectorsDependentOn} from '@store/store.selector-generators';
import {FALLBACK_BRANCH} from './branch.fallback';
import {SelectOptions} from '@store/common/common.types';
import {convertToSelectOptions} from '@store/store.helpers';

export const entireState: MxSelector<BranchState> = createFeatureSelector(BRANCH_FEATURE_KEY);

export namespace Auth {
  const subState: MxSelector<BranchState['auth']> = createSelector(
    entireState,
    state => state.auth
  );

  export const {
    loading,
    loaded,
    finishedLoading
  } = generateLoadStatusSelectorsFor(subState);

  export const branch: MxSelector<Branch> = createSelector(
    subState,
    _subState => _subState.data ?? FALLBACK_BRANCH
  );

  export const {
    id,
    name,
    phone,
    address,
    address2,
    city,
    state,
    zipCode
  } = generateSelectorsDependentOn(branch, FALLBACK_BRANCH, NO_NAME_PREFIX);
}

export namespace List {

  const subState: MxSelector<BranchState['list']> = createSelector(
    entireState,
    state => state.list
  );

  export const {
    loading,
    awaitingFirstLoad,
    awaitingAnyLoad,
    loaded,
  } = generateLoadStatusSelectorsFor(subState);

  export const branches: MxSelector<Branch[]> = createSelector(
    subState,
    ({data}) => data
  );

  export const branchIds: MxSelector<number[]> = createSelector(
    branches,
    _branches => _branches.map(b => b.id)
  );

  export const branchesSelectOptions: MxSelector<SelectOptions<number>> = createSelector(
    branches,
    _branches => convertToSelectOptions(_branches, 'name', 'id')
  );
}


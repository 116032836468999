import {createReducer, on} from '@ngrx/store';
import {INITIAL_LOAD_STATUS_SUBSTATE, LoadStatusSubstate} from '../store.types';
import {Branch} from './branch.types';
import {FALLBACK_BRANCH} from './branch.fallback';
import {PatronActions} from '../patron/patron.actions';
import {BranchActions} from './branch.actions';
import {FAILED, LOADED, LOADING} from '@store/common/common.types';

export interface BranchState {
  auth: LoadStatusSubstate & {
    data?: Branch
  };
  list: LoadStatusSubstate & {
    data: Branch[]
  };
}

export const INITIAL_BRANCHES_STATE: BranchState = {
  auth: {
    ...INITIAL_LOAD_STATUS_SUBSTATE,
    data: FALLBACK_BRANCH
  },
  list: {
    ...INITIAL_LOAD_STATUS_SUBSTATE,
    data: []
  },
};

export const branchState = createReducer(
  INITIAL_BRANCHES_STATE,
  on(PatronActions.initializeAuthTokenSuccess, state => ({
    ...state,
    auth: {
      ...INITIAL_BRANCHES_STATE,
      loadStatus: LOADING,
    }
  })),
  on(BranchActions.loadAuthBranchSuccess, (state, {branch}) => ({
    ...state,
    auth: {
      loadStatus: LOADED,
      data: branch
    }
  })),
  on(BranchActions.loadAuthBranchFailure, state => ({
    ...state,
    auth: {
      ...INITIAL_BRANCHES_STATE,
      loadStatus: FAILED
    }
  })),

  on(BranchActions.loadActiveBranches, state => ({
    ...state,
    list: {
      ...state.list,
      loadStatus: LOADING
    }
  })),
  on(BranchActions.loadActiveBranchesSuccess, (state, {listData}) => ({
    ...state,
    list: {
      ...state.list,
      loadStatus: LOADED,
      data: listData
    }
  })),
  on(BranchActions.loadActiveBranchesFailure, state => ({
    ...state,
    list: {
      ...state.list,
      loadStatus: FAILED,
      data: []
    }
  })),
);

import {animate, state, style, transition, trigger,} from '@angular/animations';
import {SelectionModel} from '@angular/cdk/collections';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {Subject, takeUntil} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {
  CancelHoldDialog,
  Hold,
  HOLD_STATUS,
  HoldService,
  ListDataService,
  NotificationService,
  PatronLedgerService,
  PauseHoldDialog,
  RoutesService,
  UnpauseHoldDialog
} from '@raven';
import {DeleteHoldDialog} from './dialogs/delete-hold-dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'rn-holds-list',
  templateUrl: './holds.component.html',
  styleUrls: ['./holds.component.scss'],
  providers: [ListDataService, DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HoldsComponent implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') mobileActionMenuTrigger: MatMenuTrigger;
  subs = new SubSink();
  HOLD_STATUS = HOLD_STATUS;
  destroy$ = new Subject<boolean>();
  selection: SelectionModel<Hold> = new SelectionModel<Hold>(true, []);
  isMobile = false;
  canPlaceHold = false;
  desktopColumns = [
    'checkbox',
    'title',
    'materialType',
    'location',
    'status',
    'actions',
  ];
  mobileColumns = [
    'checkbox',
    'mobile',
    'actions'
  ];
  columnsToDisplay = this.desktopColumns;

  constructor(public listDataService: ListDataService,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private holdService: HoldService,
              private patronLedgerService: PatronLedgerService,
              private breakpointObserver: BreakpointObserver,
              public routingService: RoutesService) {
  }

  ngOnInit(): void {
    this.listDataService.pageEvent({page: 0, limit: 5});
    this.listDataService.setFetchData(({filter, sort, page}) => {
      return this.holdService.getPatronHolds(filter, page?.page, page?.limit)
    });
    this.refreshAccountStatus();
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        if (breakpointState.matches) {
          this.isMobile = true;
          this.columnsToDisplay = this.mobileColumns;
        } else {
          this.isMobile = false;
          this.columnsToDisplay = this.desktopColumns;
          if (this.mobileActionMenuTrigger && this.mobileActionMenuTrigger.menuOpen) {
            this.mobileActionMenuTrigger.closeMenu();
          }
        }
      });
  }

  refreshHolds(): void {
    this.selection.clear();
    this.refreshAccountStatus();
    this.listDataService.triggerRefresh();
  }

  refreshAccountStatus() {
    this.patronLedgerService.getAccountStatus()
      .pipe(PatronLedgerService.canPlaceHold$)
      .pipe(tap(canPlaceHold => this.canPlaceHold = canPlaceHold))
      .subscribe();
  }

  selectionHasPausableHolds(): boolean {
    if (!this.selection.hasValue()) {
      return false;
    }
    return HoldService.hasPausableHolds(this.selection.selected);
  }

  selectionHasUnpausableHolds(): boolean {
    if (!this.selection.hasValue()) {
      return false;
    }
    return HoldService.hasUnpausableHolds(this.selection.selected);
  }

  selectionHasCancellableHolds(): boolean {
    return (
      this.selection.hasValue() && // something is selected
      HoldService.hasCancellableHolds(this.selection.selected)
    ); // at least one of them is cancellable
  }

  selectionHasDeletableHolds(): boolean {
    return (
      this.selection.hasValue() && // something is selected
      HoldService.hasDeletableHolds(this.selection.selected)
    );
  }

  allSelected(): boolean {
    return this.selection.selected.length === this.listDataService.data.length;
  }

  toggleAll(): void {
    this.allSelected()
      ? this.selection.clear()
      : this.listDataService.data.forEach((row) => this.selection.select(row));
  }

  pauseHolds(holds: Hold[]): void {
    this.dialog.open(PauseHoldDialog, {
      maxWidth: '95vw',
      data: {holds: holds, refreshHolds: this.refreshHolds.bind(this)}
    });
  }

  unpauseHolds(holds: Hold[]): void {
    this.dialog.open(UnpauseHoldDialog, {
      maxWidth: '95vw',
      data: {holds: holds, refreshHolds: this.refreshHolds.bind(this)}
    });
  }

  cancelHolds(holds: Hold[]): void {
    this.dialog.open(CancelHoldDialog, {
      maxWidth: '95vw',
      data: {holds: holds, refreshHolds: this.refreshHolds.bind(this)}
    });
  }

  deleteHolds(holds: Hold[]): void {
    this.dialog.open(DeleteHoldDialog, {
      maxWidth: '95vw',
      data: {holds: holds, refreshHolds: this.refreshHolds.bind(this)}
    });
  }

  getBranchDirectionsURI(hold: Hold): string {
    if (hold.pickupBranchAddress) {
      return (
        'https://www.google.com/maps/search/?api=1&query=' +
        encodeURIComponent(hold.pickupBranchAddress)
      );
    }
    return '';
  }

  isRequestedStatus(hold: Hold): boolean {
    return HoldService.isRequestedStatus(hold);
  }

  isPausedStatus(hold: Hold): boolean {
    return HoldService.isPausedStatus(hold);
  }

  isCancellableStatus(hold: Hold): boolean {
    return HoldService.isCancellableStatus(hold);
  }

  isDeletableStatus(hold: Hold): boolean {
    return HoldService.isDeletableStatus(hold);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroy$.next(true);
  }
}

import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

/**
 * Delete HISTORY DIALOG
 */
@Component({
  selector: 'mx-delete-history-dialog',
  template: `
    <mat-dialog-content class="dialog-wrapper flex-col">
      <h1>Delete Borrowing History</h1>
      <h4>
        You've indicated that you no longer wish to save your borrowing history. It's important to note that this action will result in the deletion of your
        borrowing history. However, please be aware that any unpaid fees associated with items you've borrowed will still be displayed.
      </h4>
      <h4>
        Are you sure you want to proceed and delete your borrowing history?
      </h4>
      <mat-divider></mat-divider>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">Go Back</button>
        <button mat-raised-button color="primary" class="save-button-group save-button" (click)="onSubmit()">
          Delete Borrowing History
        </button>
      </div>
    </mat-dialog-content>
  `,
  styles: [`
      mat-dialog-content.dialog-wrapper {
          width: 31.25rem;
          padding-bottom: 1rem;
      }

      div.dialog-button-row {
          margin-top: 0.75rem;
      }

      button.save-button-group {
          height: 3.5rem;
      }
  `,
  ],
})
export class DeleteHistoryDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteHistoryDialogComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}

import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, retryWhen, tap} from 'rxjs/operators';
import {Environment} from '../model/environment';
import {PatronVerification} from '../model/patron/patron-verification';
import {ServiceOptions, ServiceUtilService} from './service-util.service';

const headers = new HttpHeaders({'Content-Type': 'application/json'});

@Injectable({
  providedIn: 'root',
})
export class PatronVerificationService {
  constructor(
    private serviceUtil: ServiceUtilService,
    private environment: Environment,
    private http: HttpClient
  ) {
  }

  sendEmailVerificationRequest(organizationId: number, email: string): Observable<void> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/register/email/resend`;
    return this.http.post<void>(url, {}, {headers: headers});
  }

  sendChangeEmailVerificationRequest(email: string, options: ServiceOptions<void> = {}): Observable<void> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/register/email/change`;
    return this.serviceUtil.httpInvoker(this.http.post<void>(url, { email }, {headers: headers}), Object.assign({
      successMessage: 'Verification email sent',
      errorMessage: {
        409: 'That email is not available',
        default: 'Error sending verification email'
      }
    }, options));
  }

  sendMobileVerificationRequest(): Observable<void> {
    const url = `${this.environment.apiUrl}/patrons/mobile-verification/send`;
    return this.http.post<void>(url, {headers: headers});
  }

  verifyEmail(verification: PatronVerification): Observable<PatronVerification> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/email/verify`;
    return this.http
      .patch<PatronVerification>(url, JSON.stringify(verification), {
        params: {
          verificationCode: verification.code
        },
        headers: headers,
      })
      .pipe(
        map((response: PatronVerification) => response),
        tap((response) => console.log('got created response', response)),
        retryWhen((err) => {
          console.log('in retryWhen, checking the error', err);
          throw err;
        }),
        catchError((err) => observableThrowError(err))
      );
  }

  verifyMobilePhone(verification: PatronVerification): Observable<PatronVerification> {
    const url = `${this.environment.apiUrl}/patrons/${verification.patronId}/verify-mobile`;
    return this.http
      .post<PatronVerification>(url, JSON.stringify(verification), {
        headers: headers,
      })
      .pipe(
        map((response: PatronVerification) => response),
        tap((response) => console.log('got created response', response)),
        retryWhen((err) => {
          console.log('in retryWhen, checking the error', err);
          throw err;
        }),
        catchError((err: unknown) => observableThrowError(err))
      );
  }
}

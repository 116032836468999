import {Environment} from '@raven';

export class BowkerCoverImageSource {

  constructor(environment: Environment) {
    this.apiBase = environment.apiUrl;
  }

  private BOWKER_API_PATH = '/public/v1/content/images/bowker/ean/';
  private apiBase: string;

  getImageUrl(identifier: string): string {
    return this.apiBase + this.BOWKER_API_PATH + identifier;
  }
}

import {map, Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Environment} from '@raven';
import {ResponseBase} from '../common/common.types';
import {Branch} from './branch.types';

@Injectable({providedIn: 'root'})
export class BranchService {

  private readonly http: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(Environment);

  getAuthBranch(branchId: number): Observable<Branch> {
    const url = `${this.environment.apiUrl}/patrons/v2/branches/${branchId}`;
    return this.http.get<ResponseBase<Branch>>(url, {})
      .pipe(map(response => response.objects[0]))
  }

  getBranches(): Observable<Branch[]> {
    const url = `${this.environment.apiUrl}/public/v1/branches`;
    return this.http.get<Branch[]>(url);
  }
}

import {Injectable} from '@angular/core';
import {first, from, map, Observable, of, switchMap, throwError} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private angularFireAuth: AngularFireAuth
  ) {
  }

  configureTenantId(tenantId: string): Observable<void> {
    return this.angularFireAuth.authState.pipe(
      first(),
      map(() => {
        firebase.auth().tenantId = tenantId;
      })
    );
  }

  login(email: string, password: string): Observable<UserCredential> {
    return from(this.angularFireAuth.signInWithEmailAndPassword(email, password));
  }

  loginWithToken(loginToken: string): Observable<UserCredential> {
    return from(this.angularFireAuth.signInWithCustomToken(loginToken));
  }

  logout(): Observable<void> {
    return from(this.angularFireAuth.signOut());
  }

  resetPassword(email: string): Observable<void> {
    return from(this.angularFireAuth.sendPasswordResetEmail(email));
  }

  public getAsyncToken(): Observable<string> {
    return this.angularFireAuth.authState.pipe(
      first(),
      switchMap(() => from(this.angularFireAuth.currentUser).pipe(
          switchMap((currentUser) => currentUser ?
            from(currentUser.getIdToken()) :
            of(''))
        )
      )
    );
  }

  public reauthenticate(email: string, password: string): Observable<UserCredential> {
    const credentials = firebase.auth.EmailAuthProvider.credential(
      email, password);
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return throwError(() => 'Not logged in');
    }
    return from(currentUser.reauthenticateWithCredential(credentials));
  }

  public changePassword(newPassword: string): Observable<void> {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return throwError(() => 'Not logged in');
    }
    return from(currentUser.updatePassword(newPassword));
  }
}

import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {isNull} from '@store/common/typing.helpers';

export class CommonValidators {
  /** This validator function could be used directly, but it's probably better to use it as a base from which to create other custom validators. */
  public static pattern(pattern: string | RegExp, errorObject: object): ValidatorFn {
    return (control: AbstractControl) => isNull(Validators.pattern(pattern)(control)) ? null : errorObject;
  }

  public static integer(control: AbstractControl): ValidationErrors | null {
    return CommonValidators.pattern(/^\d*$/, {mustBeInteger: true})(control);
  }

  public static positiveInteger(control: AbstractControl): ValidationErrors | null {
    if (CommonValidators.integer(control) ?? Validators.min(1)(control)) return {mustBePositiveInteger: true};

    return null;
  }

  public static nonNegativeInteger(control: AbstractControl): ValidationErrors | null {
    if (CommonValidators.integer(control) ?? Validators.min(0)(control)) return {mustBeNonNegativeInteger: true};

    return null;
  }

  public static mustBeFalse(control: AbstractControl): ValidationErrors | null {
    return control.value === false ? null : {'mustBeFalse': true};
  }

  /* The default value on some required object select fields is often 0, the normal required validator
  considers this valid, this required validator checks for positive integer instead */
  public static requiredIdSelect(control: AbstractControl): ValidationErrors | null {
    if (CommonValidators.integer(control) ?? Validators.min(1)(control)) return {required: true};
    return null;
  }

  public static readonly currencyRegex = /^\d*\.?\d{0,2}$/;

  public static currencyValidator(control: AbstractControl): ValidationErrors | null {
    return CommonValidators.pattern(CommonValidators.currencyRegex, {currency: true})(control);
  }

  public static phoneNumber(control: AbstractControl): ValidationErrors | null {
    /*
      North American phone number rules:
        - Area codes start with a number 2–9, followed any two digits.  (But there's also a rule that the last two digits can't both be 11, because of 911.)
        - The exchange code starts with a number 2–9 followed by any two digits.
        - The final four digits, known as the station code, have no restrictions.

      For this, the pattern would be something like /^\(?[2-9]\d{2}\)?[\s-]?[2-9]\d{2}[\s-]?\d{4}$/ -- not counting the "no *11" rule.

      But for now, so as to not introduce a lot of problems with test data, we're simply validating a "reasonably formatted" 10-digit phone number.
    */
    const rawValue: string = control.value?.replace(/\D/g, '') ?? ''; // Remove all non-digit characters.
    if (!rawValue) return null;

    return rawValue.length === 10
      ? CommonValidators.pattern(/^\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/, {mustBeValidPhoneNumber: true})(control)
      : {mustBeTenDigitPhoneNumber: true};
  }

  public static readonly requireDigitRegex = /\d/;

  public static requiresDigitValidator(control: AbstractControl): ValidationErrors | null {
    return CommonValidators.pattern(CommonValidators.requireDigitRegex, {requiresDigit: true})(control);
  }

  public static readonly requiresUppercaseRegex = /[A-Z]/;

  public static requiresUppercaseValidator(control: AbstractControl): ValidationErrors | null {
    return CommonValidators.pattern(CommonValidators.requiresUppercaseRegex, {requiresUppercase: true})(control);
  }

  public static readonly requiresLowercaseRegex = /[a-z]/;

  public static requiresLowercaseValidator(control: AbstractControl): ValidationErrors | null {
    return CommonValidators.pattern(CommonValidators.requiresLowercaseRegex, {requiresLowercase: true})(control);
  }

  public static readonly requiresSpecialCharsRegex = /[^a-zA-Z\d]/;

  public static requiresSpecialCharsValidator(control: AbstractControl): ValidationErrors | null {
    return CommonValidators.pattern(CommonValidators.requiresSpecialCharsRegex, {requiresSpecialChars: true})(control);
  }

  public static readonly passwordValidators: ValidatorFn[] = [
    Validators.minLength(8),
    CommonValidators.requiresDigitValidator,
    CommonValidators.requiresUppercaseValidator,
    CommonValidators.requiresLowercaseValidator,
    CommonValidators.requiresSpecialCharsValidator
  ];

  public static matchPasswordValidator(passwordControl: AbstractControl | null): ValidatorFn {
    return (confirmPasswordControl: AbstractControl): ValidationErrors | null => {
      if (!passwordControl || !confirmPasswordControl) {
        console.error('Invalid controls in validator'); // eslint-disable-line no-console -- This should never happen, so logging it is good in case it ever does.
        return null;
      }
      const password: string = passwordControl.value;
      const confirmPassword: string = confirmPasswordControl.value;

      return (!!confirmPassword.length) && password !== confirmPassword ? {passwordMismatch: true} : null;
    };
  }
}

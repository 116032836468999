import {MaterialType} from './material-type';

export class Hold {
  id?: number;

  // Patron that placed hold info
  patronFirstName: string;
  patronLastName: string;

  queueIdx: number;
  status?: HOLD_STATUS;
  expireDate?: string;
  pickupExpireDate?: string;
  notWantedBefore?: string;
  notWantedAfter?: string;
  type?: string;

  // Branch info
  pickupBranchName?: string;
  pickupBranchAddress?: string;
  pickupBranchPhone?: string;

  // Catalog record info
  materialType?: MaterialType;
  itemTitle?: string;
  coverImageUrl?: string;

  // Place hold fields
  pickupBranchId?: number;
  catalogRecordId?: number

  // Date info
  placedDate?: string;
  modifiedDate?: string;

}

// Order of hold status is the default sort order
export enum HOLD_STATUS {
  HELD = 'HELD',
  TRANSIT = 'TRANSIT',
  PULLED = 'PULLED',
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
  PAUSED = 'PAUSED',
  PICKED_UP = 'PICKED_UP',
  UNCLAIMED = 'UNCLAIMED',
  NOT_SUPPLIED = 'NOT_SUPPLIED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}

import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'rv-phone-form-field',
  template: `
    <rv-input-form-field [fieldName]="fieldName" [overrideLabel]="overrideLabel" [externalLabel]="externalLabel" [formGroup]="formGroup"
                         [includeClearButton]="includeClearButton" [subscriptSizing]="subscriptSizing"
                         (inputFocused)="onInputFocused()"/>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneFormFieldComponent implements OnInit {

  @Input() public fieldName: string;
  @Input() public overrideLabel: string;
  @Input() public externalLabel = false;
  @Input() public formGroup: FormGroup;
  @Input() public includeClearButton = true;
  @Input() public subscriptSizing: 'fixed' | 'dynamic' = 'dynamic';

  @Output() public readonly inputFocused: EventEmitter<never> = new EventEmitter<never>();

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;

    this.updateFormattingFor(this.control.value);

    this.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.updateFormattingFor(value));
  }

  public onInputFocused(): void {
    this.inputFocused.emit();
  }

  private readonly destroyed: Subject<void> = new Subject();

  private updateFormattingFor(value: string): void {
    const digitsOnlyValue: string = value?.replace(/\D/g, '') ?? '';

    // The valueChanges event fires before the values on the form object update, allowing us to get the previous value.
    // There doesn't appear to be a good way to do nesting for this case, we don't want the control.value
    const [parentName, childName] = this.fieldName.split('.');
    const formValue = childName
      ? this.formGroup.value[parentName][childName]
      : this.formGroup.value[parentName];
    const previousDigitsOnlyValue = formValue?.replace(/\D/g, '') ?? '';

    // This allows the user to backspace without automatically re-adding characters.
    if (digitsOnlyValue.length < 10 && digitsOnlyValue === previousDigitsOnlyValue) return;

    // Format as much as the user has entered to (###) ###-####.
    const formattedValue: string = [
      digitsOnlyValue.length > 0 ? `(${digitsOnlyValue.slice(0, 3)}` : '',
      digitsOnlyValue.length > 2 ? `) ${digitsOnlyValue.slice(3, 6)}` : '',
      digitsOnlyValue.length > 5 ? `-${digitsOnlyValue.slice(6, 10)}` : ''
    ].join('');

    this.control.setValue(formattedValue, {emitEvent: false});
  }
}

<mat-dialog-content class="login-container">
  <div class="login-column flex-col">
    <ng-container *ngIf="dialogRef">
      <div class="title-text">Login</div>
      <h4 class="secondary-text">Welcome back! Please enter your username and password to proceed.</h4>
    </ng-container>
    <form [formGroup]="loginForm" *ngIf="loginForm">
      <rv-input-form-field fieldName="email" [externalLabel]="true" overrideLabel="Email" [formGroup]="loginForm"/>
      <rv-password-form-field fieldName="password" [externalLabel]="true" overrideLabel="Password" [includeClearButton]="false" [formGroup]="loginForm"/>
      <div class="mat-small forgot-password-link"><a class="pointer" (click)="forgotPassword()">Forgot password?</a></div>
      <button mat-raised-button color="primary" class="form-button form-submit-button"
              [disabled]="loginForm.invalid"
              (click)="login()">Login
      </button>
      <button mat-raised-button class="form-button create-account-button" (click)="onClickCreateAccount()">Create Account</button>
    </form>
  </div>
</mat-dialog-content>

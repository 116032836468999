<div class="flex-row flex-start-space-between">
  <div class="flex-1">
    <form [formGroup]="createPasswordForm" novalidate class="registration-form">
      <div class="mat-headline-3 registration-title">Create A Password</div>
      <h4 class="registration-description">Provide a username and password to create your library account. If any account details are incorrect, please
        contact your local library directly.</h4>
      <div class="flex-col flex-gap-20">
        <rv-password-form-field fieldName="password" placeholder="Create Password" [includeClearButton]="false" [formGroup]="createPasswordForm"
                                [showAllPasswordRequirements]="true"/>
        <rv-password-form-field fieldName="confirmPassword" placeholder="Re-enter Password" [includeClearButton]="false" [formGroup]="createPasswordForm"/>
        <rv-select-form-field fieldName="branchId" [externalLabel]="true" overrideLabel="Pick-up Location" [formGroup]="createPasswordForm"
                              [options]="branchSelectors.branchesSelectOptions | select" [includeUndefinedOption]="false"/>
        <rv-checkbox-form-field fieldName="acceptTerms" [formGroup]="createPasswordForm"
                                overrideLabel="I agree to the">
          <a (click)="termsAndConditions($event)">Terms and Conditions</a>
        </rv-checkbox-form-field>
        <button mat-raised-button color="primary" class="next-button" (click)="onClickNext()" [disabled]="!acceptTermsField.value || !createPasswordForm.valid">
          Next
        </button>
      </div>
    </form>
  </div>
  <mat-card appearance="outlined" class="details-card">
    <mat-card-content class="details-card-content">
      <h2 class="mat-headline-3 details-title registration-title">Details</h2>
      <div class="detail-name">{{ patronSelectors.firstAndLastName | select }}</div>
      <div>{{ patronSelectors.address | select }}</div>
      <div>{{ patronSelectors.cityStateZip | select }}</div>
      <div class="detail-subtitle">Username:</div>
      <div>{{ patronSelectors.email | select }}</div>
      <div class="detail-subtitle" *ngIf="patronSelectors.hasCardNumber | select">Library Card Number:</div>
      <div>{{ patronSelectors.cardNumber | select }}</div>
      <div class="detail-subtitle" *ngIf="patronSelectors.hasDateOfBirth | select">DOB:</div>
      <div>{{ patronSelectors.dateOfBirth | select }}</div>
    </mat-card-content>
  </mat-card>
  <div class="create-password-img flex-1"></div>
</div>

export enum PATRON_NOTIFICATION_PREFERENCE_TYPE {
  ACCOUNT = 'ACCOUNT',
  AUTO_RENEW = 'AUTO_RENEW',
  CARD_EXPIRE = 'CARD_EXPIRE',
  RECEIPT = 'RECEIPT',
  // LOCAL_EVENTS = 'LOCAL_EVENTS',
  // MONTHLY_NEWSLETTER = 'MONTHLY_NEWSLETTER',
}

export class PatronNotificationPreference {
  patronId: number;
  notificationType: PATRON_NOTIFICATION_PREFERENCE_TYPE;
  sendNotification: boolean;
  notifyEmail: boolean;
  notifyText: boolean;
  notifyPhone: boolean;
}

<ng-template #loadingTmpl>
  <div style="background-color:white;">
    <div class="center-center" style="margin-top: 75px;">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>

<ng-template #loadingTableTmpl>
  <div style="background-color:white;">
    <div class="center-center" style="margin-top: 75px;">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>

<ng-container *rvLoadingTemplate="loadingTmpl; table: loadingTableTmpl" style="height:100%">
  <router-outlet></router-outlet>
</ng-container>


import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SelectOptions} from '@store/common/common.types';
import {Subject} from 'rxjs';
import {MatRadioChange} from '@angular/material/radio';
import {TrackBySelectOption, trackBySelectOption} from '@store/common/track-by.helpers';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'rv-radio-group-form-field',
  template: `
    <div [formGroup]="formGroup" class="hk-radio-group-form-field-body flex justify-start"
         [ngClass]="vertical ? 'flex-col items-start' : 'flex-row items-center'"
         [class.hk-radio-group-form-field-body-vertical]="vertical">
      <p *ngIf="label" class="hk-radio-group-label">{{ label }}</p>

      <mat-radio-group color="primary" [formControlName]="fieldName" [value]="control.value" (change)="onChange($event)" class="flex"
                       [ngClass]="vertical ? 'flex-col items-start' : 'flex-row place-content-evenly items-center'">
        <!-- 
          Right padding is set to visually balance with 10px radio button padding, which improves group centering.  (20x20 icon centered in a 40x40 space.) 
        -->
        <mat-radio-button *ngFor="let option of options; trackBy: trackBySelectOption" [value]="option.value" class="pe-2.5">
          {{ option.displayName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupFormFieldComponent<ValueType> implements OnInit {
  @Input() public fieldName: string;
  @Input() public formGroup: FormGroup;
  @Input() public options: SelectOptions<ValueType> = [];
  @Input() public vertical = false;
  @Input() public label: string;

  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.
  public readonly trackBySelectOption: TrackBySelectOption = trackBySelectOption;
  public selectedValue: ValueType;

  private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly destroyed: Subject<void> = new Subject();

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;

    this.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.selectedValue = value;
        this.changeDetector.markForCheck();
      });
  }

  public onChange(event: MatRadioChange): void {
    this.control.patchValue(event.value);
  }
}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NotificationService, PatronItemRequestService} from '@raven';
import {MatDialogRef} from '@angular/material/dialog';
import {BranchSelectors, PatronSelectors} from '@store/store.selectors';
import {STATE_SELECT} from '@store/common/common.types';

@Component({
  selector: 'rn-submit-request',
  templateUrl: './submit-request.component.html',
  styleUrls: ['./submit-request.component.scss'],
})
export class SubmitRequestComponent implements OnInit {
  public readonly patronSelectors = PatronSelectors.Auth;
  public readonly branchSelectors = BranchSelectors.List;
  requestForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<SubmitRequestComponent>,
    private patronItemRequestService: PatronItemRequestService,
    private notificationService: NotificationService,
    private router: Router,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      itemTitle: ['', {validators: [Validators.required]}],
      author: ['', {validators: [Validators.required]}],
      isbn: ['', {validators: []}],
      pickupLocation: [null, {validators: [Validators.required]}],
      materialTypeDesc: ['', {validators: []}],
      notes: ['', {validators: []}],
      agreeToPolicies: [false, {validators: [Validators.requiredTrue]}],
    });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  submit(): void {
    const v = this.requestForm.value;
    this.patronItemRequestService.submitItemRequest(
      v.itemTitle,
      v.author,
      v.pickupLocation,
      v.notes,
      v.isbn,
      v.materialTypeDesc
    ).subscribe((_) => {
      this.notificationService.showSnackbarSuccess('Request submitted successfully.');
      this.router.navigate(['/patron-item-requests-v2']);
    });
    console.log('submit');
  }

  protected readonly STATE_SELECT = STATE_SELECT;
}

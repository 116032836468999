<div class="flex-row flex-start-start">
  <div class="flex-1">
    <form [formGroup]="lookupForm" novalidate class="registration-form">
      <div class="mat-headline-3 registration-title">Lookup Account</div>
      <h4 class="registration-description">Enter your library card number and PIN to find your account and complete the online registration.</h4>
      <div class="flex-col flex-gap-20">
        <rv-input-form-field fieldName="cardNumber" [externalLabel]="true" overrideLabel="Library Card Number"
                             [formGroup]="lookupForm"/>
        <rv-input-form-field fieldName="PIN" [externalLabel]="true" overrideLabel="PIN"
                             [formGroup]="lookupForm"/>
      </div>
      <button mat-raised-button color="primary" class="next-button" (click)="onClickNext()" [disabled]="!lookupForm.valid">Look Up Account</button>
      <div class="option-link">Don’t have a library card? <a routerLink="/register">Sign up here</a></div>
    </form>
  </div>
  <div class="lookup-account-img flex-1"></div>
</div>

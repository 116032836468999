import {Component} from '@angular/core';
import {OrganizationSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-index',
  templateUrl: 'index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
  public readonly organizationSelectors = OrganizationSelectors.Auth;
}

import {Component} from '@angular/core';
import {PatronSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent {
  public readonly patronSelectors = PatronSelectors.Registration;
}

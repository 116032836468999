import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[rvLoadingTemplate]'
})
export class LoadingTemplateDirective {

  @Input() rvLoadingTemplate: TemplateRef<any>;
  @Input() rvLoadingTemplateTable: TemplateRef<any>;

  constructor(
    private template: TemplateRef<any>,
    private vcr: ViewContainerRef,
  ) {
    this.vcr.createEmbeddedView(this.template);
  }

}

import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CatalogRecord, FavoriteService} from '@raven';

@Component({
  selector: 'rn-favorites-grid',
  templateUrl: './favorites-grid.component.html',
  styleUrls: ['./favorites-grid.component.scss'],
})
export class FavoritesGridComponent {
  @Input() items: Array<CatalogRecord>;

  constructor(private favoriteService: FavoriteService,
              private dialog: MatDialog) {
  }

}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {generateLoadStatusSelectorsFor, generateSelectorsDependentOn} from '../store.selector-generators';
import {MxSelector, NO_NAME_PREFIX} from '../store.types';
import {FALLBACK_ORGANIZATION} from './organization.fallback';
import {OrganizationState} from './organization.state';
import {Organization, ORGANIZATION_FEATURE_KEY} from './organization.types';

export const entireState: MxSelector<OrganizationState> = createFeatureSelector(ORGANIZATION_FEATURE_KEY);

export namespace Auth {
  const subState: MxSelector<OrganizationState['auth']> = createSelector(
    entireState,
    state => state.auth
  );

  export const {
    loading,
    loaded
  } = generateLoadStatusSelectorsFor(subState);

  export const organization: MxSelector<Organization> = createSelector(
    subState,
    _subState => _subState.data ?? FALLBACK_ORGANIZATION
  );

  export const {
    name,
    phoneNumber,
    contactEmail,
    coverImageSource,
  } = generateSelectorsDependentOn(organization, FALLBACK_ORGANIZATION, NO_NAME_PREFIX);

} // namespace Auth



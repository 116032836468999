import {DateOnlyIsoString, DateOnlyRangeIsoString, DateString, DateTimeIsoString, DateTimeIsoWithMillisecondsString} from './common.types';
import {OneOrArrayOf} from '../utility-type.helpers';
import {MxSelector} from '../store.types';

export function isNull(thing: unknown): thing is null {
  return thing === null;
}

export function isUndefined<T>(thing: T | undefined): thing is undefined {
  return typeof thing === 'undefined';
}

export function isDefined<T>(thing: T | undefined): thing is T {
  return !isUndefined(thing);
}

export function hasValue<T>(thing: T | undefined | null): thing is T {
  return isDefined(thing) && !isNull(thing);
}

export function hasNoValue<T>(thing: T | undefined | null): thing is undefined | null {
  return !hasValue(thing);
}

export function isArray(thing: unknown): thing is unknown[] {
  return Array.isArray(thing);
}

export function isPopulatedArray(thing: unknown): boolean {
  return isArray(thing) && thing.length > 0;
}

export function isObject(thing: unknown): thing is Record<string, unknown> {
  return hasValue(thing) && !isArray(thing) && thing === Object(thing);
}

export function isPopulatedObject(thing: unknown): boolean {
  return isObject(thing) && Object.keys(thing).length > 0;
}

export function isString(thing: unknown): thing is string {
  return typeof thing === 'string';
}

export function isPopulatedString(thing: unknown): thing is string {
  return isString(thing) && thing.length > 0;
}

export function isNumber(thing: unknown): thing is number {
  return typeof thing === 'number' && !isNaN(thing);
}

// eslint-disable-next-line @typescript-eslint/ban-types -- We mean Function very generically here.
export function isFunction(thing: unknown): thing is Function {
  return typeof thing === 'function';
}

export function isSelector<T>(thing: unknown): thing is MxSelector<T> {
  return isObject(thing) && isFunction(thing.release) && isFunction(thing.projector) && isFunction(thing.setResult) && isFunction(thing.clearResult);
}


export function isDate(thing: unknown): thing is Date {
  return isDefined(thing) && Object.prototype.toString.call(thing) === '[object Date]';
}

/**
 * NOTE: No date or time validation here.  This type guard checks only that `thing` is a string that has the same format as this example:
 * `'2023-04-18T22:49:29Z'`.
 */
export function isDateTimeIsoString(thing: unknown): thing is DateTimeIsoString {
  return isString(thing) && (thing === '' || !!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.exec(thing));
}

/**
 * NOTE: No date or time validation here.  This type guard checks only that `thing` is a string that has the same format as this example:
 * `'2023-04-18T22:49:29.082283Z'`.
 */
export function isDateTimeIsoStringWithMilliseconds(thing: unknown): thing is DateTimeIsoWithMillisecondsString {
  return isString(thing) && (thing === '' || !!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3,9}Z$/.exec(thing));
}

/** NOTE: No date or time validation here.  This type guard checks only that `thing` is a string that has the same format as this example: `'2023-04-18'`. */
export function isDateOnlyIsoString(thing: unknown): thing is DateOnlyIsoString {
  return isString(thing) && (thing === '' || !!/^\d{4}-\d{2}-\d{2}$/.exec(thing));
}

/** NOTE: No date or time validation here.  This type guard checks only that `thing` is a string that has the same format as this example:
 *  `'2023-04-18..2023-04-22'`. */
export function isDateOnlyRangeIsoString(thing: unknown): thing is DateOnlyRangeIsoString {
  if (!isString(thing) || !thing.includes('..')) return false;

  const parts: string[] = thing.split('..');

  return parts.length === 2 && parts.every(isDateOnlyIsoString);
}

/**
 * NOTE: No date or time validation here.  This type guard checks only that `thing` is a string that has the same format as one of these examples:
 * `'2023-04-18T22:49:29Z'` or `'2023-04-18'` or the empty string.
 */
export function isDateString(thing: unknown): thing is DateString {
  return isDateTimeIsoString(thing) || isDateTimeIsoStringWithMilliseconds(thing) || isDateOnlyIsoString(thing);
}

/**
 * NOTE: No date or time validation here.  This type guard checks only that `thing` is a string that has the same format as one of these examples:
 * `'2023-04-18T22:49:29Z'` or `'2023-04-18'` or the empty string.
 */
export function isPopulatedDateString(thing: unknown): boolean {
  return isPopulatedString(thing) && isDateString(thing);
}

export function asArray<T>(thing?: OneOrArrayOf<T>): T[] {
  if (isUndefined(thing)) return [];

  return isArray(thing) ? thing : [thing];
}

interface HasCreatedField {
  created: DateTimeIsoString;
}

export function createdCompare(a: HasCreatedField, b: HasCreatedField) {
  return a.created?.localeCompare(b.created) ?? 0;
}

export function reverseCreatedCompare(a: HasCreatedField, b: HasCreatedField) {
  return b.created?.localeCompare(a.created) ?? 0;
}

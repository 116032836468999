<ng-container *ngIf="patronSelectors.loaded | select">
  <div class="mycard-header">
    <div class="flex-row-wrap-col-responsive flex-center-start flex-gap-30">
      <div class="mycard-title">
        <div class="mat-headline-4" style="margin-bottom: 16px">My Library Card</div>
      </div>
      <ng-container *ngIf="patronAccountStatus | async as accountStatus">
        <div *ngIf="accountStatus.minimumBalanceDue <= 0 && PatronLedgerService.isExpired(accountStatus)" class="mycard-message">
          <div class="mat-small">Your account is in good standing.</div>
          <button id="renewAccountBtn" class="mat-raised-button mat-primary" (click)="renewAccount()">
            Renew Account
          </button>
        </div>
        <div *ngIf="accountStatus.minimumBalanceDue > 0 && PatronLedgerService.isExpired(accountStatus)" class="mycard-message">
          <div class="mat-small">You must settle your account before you may renew.</div>
          <a class="pointer" (click)="routingService.goToPatronBilling()">Go to billing</a>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mycard-section flex-row-wrap-col-responsive flex-center-start flex-gap-30">
    <mat-card appearance="outlined" class="mycard-front mat-elevation-z3">
      <h3 class="mycard-lib-name" *ngIf="(organizationSelectors.organization | select) as organization">
        <ng-container *ngIf="organization.iconUrl; else placeholderOrgIcon">
          <img [alt]="organization.name" [src]="organization.iconUrl"/>
        </ng-container>
        <ng-template #placeholderOrgIcon>
          <img alt="Millonex Logo" src="/assets/mx-logo.png"/>
        </ng-template>
        {{ organization.name }}
      </h3>
      <div class="mycard-details mat-small" *ngIf="(branchSelectors.branch | select) as branch">
        <ng-container *ngIf="branch.address && branch.phone; else placeholderBranchInfo">
          <span>{{ branch.address }}</span><br/>
          <span *ngIf="branch.address2">{{ branch.address2 }}<br/></span>
          <span>{{ branch.city }}, {{ branch.state }} {{ branch.zipCode }}</span><br/>
          <span>{{ branch.phone }}</span>
        </ng-container>
        <ng-template #placeholderBranchInfo>
          <span>1234 Street Name</span><br/>
          <span>City, ST, 12345</span><br/>
          <span>123-456-7790</span>
        </ng-template>
      </div>
      <h2 class="mycard-patron-name">
        {{ patronSelectors.firstName | select }} {{ patronSelectors.lastName | select }}
      </h2>
    </mat-card>
    <mat-card appearance="outlined" class="mycard-back mat-elevation-z3" *ngIf="(patronSelectors.barcode | select) as barcode">
      <div class="mycard-barcode">
        <ngx-barcode [bc-value]="barcode" [bc-display-value]="false"></ngx-barcode>
      </div>
      <div class="mat-small">
        {{ barcode ? barcode : '0000 0000 0000 0000' }}
      </div>
      <div class="mycard-expiration mat-small">
        <span>Expiration Date: {{ patronSelectors.accountExpiration | select | date: 'M/d/y' }}</span>
      </div>
    </mat-card>
  </div>
</ng-container>

import {Component, ElementRef, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'loading-wrapper',
  templateUrl: './loading-wrapper.component.html',
  styleUrls: ['./loading-wrapper.component.css'],
})
export class LoadingWrapperComponent {

  @Input() mainContent: TemplateRef<any>;
  @Input() loadingIndicator: TemplateRef<any>;

  @ViewChild('loadingContainerDiv') loadingContainerDiv: ElementRef;
  @ViewChild('loadingContentDiv') loadingContentDiv: ElementRef;
  @ViewChild('loadingIndicatorDiv') loadingIndicatorDiv: ElementRef;

  @Input() set loading(val) {
    if (!this.loadingIndicatorDiv || !this.loadingContentDiv) return;
    const _el = this.loadingIndicatorDiv.nativeElement as HTMLDivElement;
    const el = _el.children.item(0) as HTMLElement;
    if (val) {
      const rect = this.loadingContentDiv.nativeElement.getBoundingClientRect();
      el.style.width = rect.width + 'px';
      el.style.height = rect.height + 'px';

      _el.style.display = 'block';
      el.style.display = 'block';
      el.style.opacity = '0.5';
    } else {
      _el.style.display = 'none';
      el.style.display = 'none';
    }
  }

  constructor(
    private _hostEl: ElementRef
  ) {
    (_hostEl.nativeElement as HTMLElement).classList.add('patron-details');
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {map, Observable} from 'rxjs';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {PATH_FOR} from '../../app.routes';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard {

  constructor(
    private angularFireAuthGuard: AngularFireAuthGuard,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.angularFireAuthGuard.canActivate(route, state)
      .pipe(
        map(canActivate => !canActivate || this.router.createUrlTree([PATH_FOR['DASHBOARD']]))
      );
  }

}

import {createReducer, on} from '@ngrx/store';
import {INITIAL_LOAD_STATUS_SUBSTATE, LoadStatusSubstate} from '../store.types';
import {Patron, PatronRegistrationValue} from './patron.types';
import {PatronActions} from './patron.actions';
import {FALLBACK_PATRON, FALLBACK_REGISTRATION_VALUE} from './patron.fallback';
import {FAILED, LOADED, LOADING} from '@store/common/common.types';

export interface PatronState {
  auth: LoadStatusSubstate & {
    data?: Patron
  };
  registration: {
    completedSteps: string[],
    cardNumber: string,
    PIN: string,
    email: string,
    value: PatronRegistrationValue
  };
  emailValidation: {
    isAvailable: boolean | 'UNKNOWN'
  }
}

export const INITIAL_PATRONS_STATE: PatronState = {
  auth: {
    ...INITIAL_LOAD_STATUS_SUBSTATE,
    data: FALLBACK_PATRON
  },
  registration: {
    completedSteps: [],
    email: '',
    cardNumber: '',
    PIN: '',
    value: FALLBACK_REGISTRATION_VALUE
  },
  emailValidation: {
    isAvailable: 'UNKNOWN'
  }
};

export const patronState = createReducer(
  INITIAL_PATRONS_STATE,
  on(PatronActions.initializeAuthTokenSuccess, state => ({
    ...state,
    auth: {
      ...INITIAL_LOAD_STATUS_SUBSTATE,
      loadStatus: LOADING
    }
  })),
  on(PatronActions.loadAuthPatronSuccess, (state, {patron}) => ({
    ...state,
    auth: {
      loadStatus: LOADED,
      data: patron
    }
  })),
  on(PatronActions.initializeAuthTokenNoAuth, (state) => ({
    ...state,
    auth: {
      loadStatus: LOADED
    }
  })),
  on(PatronActions.loadAuthPatronFailure, state => ({
    ...state,
    auth: {
      ...INITIAL_LOAD_STATUS_SUBSTATE,
      loadStatus: FAILED
    }
  })),

  on(PatronActions.validatePatronEmailAvailable, state => ({
    ...state,
    emailValidation: {
      isAvailable: 'UNKNOWN' as const
    }
  })),
  on(PatronActions.validatePatronEmailAvailableSuccess, (state, {isAvailable}) => ({
    ...state,
    emailValidation: {
      isAvailable
    }
  })),
  on(PatronActions.lookupAccountSuccess, (state, {cardNumber, PIN, patron}) => ({
    ...state,
    registration: {
      ...state.registration,
      email: patron.email,
      cardNumber,
      PIN,
      value: {
        ...state.registration.value,
        firstName: patron.firstName,
        lastName: patron.lastName,
        address: patron.address,
        city: patron.city,
        state: patron.state,
        zip: patron.zipCode,
        dateOfBirth: patron.dateOfBirth
      }
    }
  })),
  on(PatronActions.updateRegistration, (state, {value}) => ({
    ...state,
    registration: {
      ...state.registration,
      value: {
        ...state.registration.value,
        ...value
      }
    }
  })),
  on(PatronActions.completeRegistrationStep, (state, {step}) => ({
    ...state,
    registration: {
      ...state.registration,
      completedSteps: [
        ...state.registration.completedSteps,
        step
      ]
    }
  })),
  on(PatronActions.requestVerificationEmailSuccess, (state, {email, verificationUUID}) => ({
    ...state,
    registration: {
      ...state.registration,
      email,
      value: {
        ...state.registration.value,
        verificationUUID
      }
    }
  })),
);

import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {PatronVerificationService} from '@raven';

/**
 * EMAIL UPDATE DIALOG COMPONENT
 */
@Component({
  selector: 'fn-email-update-dialog',
  template: `
    <div mat-dialog-content>
      <mat-card-title><h1>Update Email</h1></mat-card-title>
      <mat-card-content>
        <form [formGroup]="emailUpdateForm">
          <rv-input-form-field fieldName="newEmail" [externalLabel]="true" overrideLabel="New Email" [formGroup]="emailUpdateForm"/>
          <mat-card-actions align="end" style="margin-bottom: 0; margin-top: 15px;" class="button-row-right">
            <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel" (click)="onNoClick()"
                    data-cy="on-hold-button">
              Cancel
            </button>
            <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                    [disabled]="!this.emailUpdateForm.valid" (click)="onSubmit(); $event.stopPropagation()">
              Update Email
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </div>
  `,
  styles: [`
      form {
          padding-top: 20px;
      }
  `]
})
export class EmailUpdateDialog {

  emailUpdateForm: FormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<EmailUpdateDialog>,
    private readonly patronVerificationService: PatronVerificationService,
    private readonly fb: FormBuilder,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.emailUpdateForm = this.fb.group(
      {
        newEmail: ['', [Validators.required, Validators.email]],
      },
    );
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if (!this.emailUpdateForm.valid) {
      return;
    }
    const newEmail = this.emailUpdateForm.value.newEmail;

    this.patronVerificationService.sendChangeEmailVerificationRequest(newEmail, {
      onSuccess: () => {
        console.log('Verification email sent');
        this.dialogRef.close(true);
      }
    });
  }
}

import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {endOfDay, endOfYesterday, isAfter, isBefore, isFuture, isPast, startOfToday, startOfTomorrow} from 'date-fns';
import {DateOnlyIsoString} from '@store/common/common.types';
import {toLocalMidnight} from '@store/common/date.helpers';

export namespace DateValidators { // eslint-disable-line @typescript-eslint/no-namespace -- Keeps these validators parallel to the standard Validators.* naming.
  /** Validates that the control's date is before today's date. */
  export function pastDate(control: AbstractControl): ValidationErrors | null {
    return isPast(endOfDay(toLocalMidnight(control.value))) ? null : {mustBePastDate: true};
  }

  /** Validates that the control's date is today's date or after. */
  export function notPastDate(control: AbstractControl): ValidationErrors | null {
    return isPast(endOfDay(toLocalMidnight(control.value))) ? {cannotBePastDate: true} : null;
  }

  /** Validates that the control's date is tomorrow's date or after. */
  export function futureDate(control: AbstractControl): ValidationErrors | null {
    return isFuture(toLocalMidnight(control.value)) ? null : {mustBeFutureDate: true};
  }

  export function optionalFutureDate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    return futureDate(control);
  }

  export function notBeforeOld(control: AbstractControl): ValidationErrors | null {
    const defaultValue = (control as FormControl).defaultValue;
    if (!control.value || !defaultValue) {
      return null;
    }
    return isBefore(toLocalMidnight(control.value), toLocalMidnight(defaultValue)) ? {cannotBeBeforeOldDate: true} : null;
  }

  export function notBefore(date: DateOnlyIsoString, dateDescription: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isBefore(toLocalMidnight(control.value), toLocalMidnight(date))
        ? {cannotBeBeforeDate: {dateDescription}}
        : null;
    };
  }

  export function notAfter(date: DateOnlyIsoString, dateDescription: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isAfter(toLocalMidnight(control.value), toLocalMidnight(date))
        ? {cannotBeAfterDate: {dateDescription}}
        : null;
    };
  }
}

export function calculateMinDateFromValidatorsFor(control: FormControl): Date | undefined {
  if (control.hasValidator(DateValidators.futureDate || DateValidators.optionalFutureDate)) return startOfTomorrow();
  if (control.hasValidator(DateValidators.notPastDate)) return startOfToday();

  return undefined;
}

export function calculateMaxDateFromValidatorsFor(control: FormControl): Date | undefined {
  if (control.hasValidator(DateValidators.pastDate)) return endOfYesterday();

  return undefined;
}

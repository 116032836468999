import {Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Hold, HoldService, RoutesService} from '@raven';
import {FormBuilder, Validators} from '@angular/forms';
import {DateValidators} from '../../shared/form-fields/date-form-field/date.form-validators';

/**
 * PAUSE HOLD DIALOG COMPONENT
 */
@Component({
  selector: 'rn-pause-hold-dialog',
  template: `
    <mat-dialog-content class="hold-dialog flex-col">
      <h3>Pause Hold{{ pauseableHolds.length | plural }} ({{ pauseableHolds.length }})</h3>
      <div class="flex-col flex-start-start">
        <ng-container *ngIf="cantPause > 0">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ cantPause }} of the selected items cannot be paused.</span>
          </div>
        </ng-container>
        <span class="Text-Style dialog-body">
          Are you sure you want to pause {{ pauseableHolds.length }} item hold{{ pauseableHolds.length | plural }}?
        </span>

        <form [formGroup]="pauseHoldForm">
          <rv-date-form-field fieldName="pauseUntilDate" [externalLabel]="true" overrideLabel="Pause Until Date" [formGroup]="pauseHoldForm"/>
        </form>
      </div>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()" data-cy="on-hold-button">No</button>
        <button mat-raised-button color="primary" class="save-button-group save-button" [disabled]="!pauseHoldForm.valid" (click)="onSubmit()">
          Yes, Pause
        </button>
      </div>
    </mat-dialog-content>
  `
})
export class PauseHoldDialog {
  pauseableHolds: Hold[];
  cantPause: number;

  public readonly pauseHoldForm = inject(FormBuilder).group({
    pauseUntilDate: ['', [Validators.required, DateValidators.futureDate]],
  });

  constructor(
    public dialogRef: MatDialogRef<PauseHoldDialog>,
    private breakpointObserver: BreakpointObserver,
    private holdService: HoldService,
    public routesService: RoutesService,
    @Inject(MAT_DIALOG_DATA) public data: { holds: Hold[], refreshHolds: () => void }
  ) {
    this.pauseableHolds = HoldService.getPauseableHolds(data.holds);
    this.cantPause = data.holds.length - this.pauseableHolds.length;
  }

  // Check if device is phone or tablet
  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if (!this.pauseHoldForm.valid) {
      return;
    }
    this.holdService.pause(
      this.pauseableHolds.map((hold) => hold.id),
      this.pauseHoldForm.value.pauseUntilDate,
      {onSuccess: this.onSuccess.bind(this)}
    );
  }

  onSuccess(result: boolean): void {
    if (result) {
      this.data.refreshHolds();
      this.dialogRef.close(true);
    }
  }
}

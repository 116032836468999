<div class="search-bar">
  <div class="search-center" class="browse-wrapper flex-row" [formGroup]="searchForm">
    <mat-select formControlName="searchType">
      <mat-option value="keyword">Keyword</mat-option>
      <mat-option value="title">Title</mat-option>
      <mat-option value="author">Author</mat-option>
      <mat-option value="isbn">ISBN</mat-option>
    </mat-select>
    <input (keydown.enter)="submitForm()" formControlName="searchTerm" placeholder="Search term"/>
    <button (click)="submitForm()">
      <img alt="magnifying glass" src="/assets/magnifying-glass.svg" style="width: 20px; height: 20px; fill: white"/>
    </button>
  </div>
</div>
<div *ngIf="searchResponse$ | async as response; else loading_template" class="browse-wrapper">
  <ng-container *ngIf="page$ | async as page">
    <div *ngIf="isMobile" class="grid-header">
      <div class="flex-row flex-center-space-between">
        <h1>Results ({{ response.totalResults }})</h1>
        <div class="grid-controls">
          Showing {{ page.page * page.limit + 1 }}-{{
            page.page * page.limit + page.limit
          }}
        </div>
      </div>
      <div class="flex-row flex-center-space-between">
        <a class="mobile-filter-trigger pointer center" (click)="facetDrawer.toggle()" [ngClass]="{ 'filter-active' : hasFilters(response)}">
          <mat-icon>filter_list</mat-icon>
          <span style="padding-top: 2px">Filters</span>
        </a>
        <ng-container *ngTemplateOutlet="sortAndViewSelect"></ng-container>
      </div>
    </div>
    <div class="grid-header flex-row-wrap" *ngIf="!isMobile">
      <h1>Results ({{ response.totalResults }})</h1>
      <div class="grid-controls">
        Showing {{ page.page * page.limit + 1 }}-{{
          page.page * page.limit + page.limit
        }}
        <ng-container *ngTemplateOutlet="sortAndViewSelect"></ng-container>
        <span class="page-length">
          <button [ngClass]="{ selected: page.limit === 25 }" (click)="setPageSize(25)">25</button>
          <button [ngClass]="{ selected: page.limit === 50 }" (click)="setPageSize(50)">50</button>
        </span>
      </div>
    </div>
  </ng-container>

  <div class="main-body">
    <mat-drawer-container class="facet-drawer-container">
      <mat-drawer #facetDrawer [mode]="facetDrawerMode" [(opened)]="filtersOpened" class="facet-drawer">
        <rn-catalog-facet-collection [facets]="response.facets"></rn-catalog-facet-collection>
      </mat-drawer>
      <div class="facet-drawer-content">
        <div class="items">

          <div class="items item-grid" *ngIf="this.displayMode === 'grid'">
            <div *ngFor="let item of response.resultsPage" class="item browse-item">
              <rn-grid-item [catalogRecord]="item"></rn-grid-item>
            </div>
          </div>

          <div *ngIf="this.displayMode === 'list'" class="items item-list" style="width: 100%; max-width: 1000px">
            <ng-container *ngFor="let item of response.resultsPage; let first = first">
              <rn-list-item [catalogRecord]="item" [topBorder]="first"></rn-list-item>
            </ng-container>
          </div>
          <div class="center" *ngIf="response.resultsPage.length === 0">
            <h4>No results matching your search parameters.</h4>
          </div>
          <ng-container *ngIf="page$ | async as page">
            <div *ngIf="page.page > 2 || response.totalResults === 0" style="margin: 30px 0">
              <ng-container *ngTemplateOutlet="itemRequestBanner"></ng-container>
            </div>
          </ng-container>
          <hr/>
          <div class="page-control" onselectstart="return false" *ngIf="page$ | async as page">
            <div class="flex-row flex-center-center">
              <span class="left-arrow" (click)="setPageNumber(page.page - 1)" [ngClass]="{ disable: page.page === 0 }">
                <mat-icon>arrow_backward</mat-icon>
              </span>
              <span *ngFor="let i of getPageNumbersForControl(page.page, response.totalResults, page.limit)"
                    class="wrapper" [ngClass]="{ circle: page.page === (i - 1) }" (click)="setPageNumber(i - 1)">
              <span class="page-number">{{ i }}</span>
              </span>
              <span class="right-arrow" (click)="setPageNumber(page.page + 1)"
                    [ngClass]="{ disable: page.page >= getPageCount(response.totalResults, page.limit) - 1 }">
                <mat-icon>arrow_forward</mat-icon>
              </span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showExpandedSearch">
          <ng-container *ngTemplateOutlet="expandedSearchNoResults"></ng-container>
        </ng-container>
      </div>
    </mat-drawer-container>
  </div>
</div>

<ng-template #loading_template>
  <div class="flex-container" style="position: relative; padding-top: 30px;">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #expandedSearchNoResults>
  <div style="width: 100%; margin-left: 12px">
    <div *ngIf="showExpandedSearchWarning" class="expanded-search-warning flex-row flex-center-start">
      <em style="color: #0071ad; font-size: 24px" class="ri-information-line"></em>
      <span class="heading">Expanded Search Results</span>
      <span>These results will include items that may not be at your library.</span>
      <img (click)="showExpandedSearchWarning = false" class="pointer" alt="close" src="/assets/slicons/close.svg"
           style="margin-left: auto"/>
    </div>
    <div class="empty-list">No search results</div>
    <ng-container *ngTemplateOutlet="itemRequestBanner"></ng-container>
  </div>
</ng-template>

<ng-template #sortAndViewSelect>
  <span class="sort-by">
          Sort by:
          <select (change)="sortChanged($event)" [ngModel]="sortSelectValue">
            <option value="">Relevance</option>
            <option value="title-asc">Title, a-z</option>
            <option value="title-desc">Title, z-a</option>]
            <option value="author-asc">Author, a-z</option>
            <option value="author-desc">Author, z-a</option>
            <option value="publicationDate-desc">Publication Date, newest first</option>
            <option value="publicationDate-asc">Publication Date, oldest first</option>
          </select>
        </span>
  <span class="display-mode">
          <button [ngClass]="{ selected: displayMode === 'grid' }" (click)="setDisplayMode('grid')"><ri class="ri-layout-grid-line mode-icon"></ri></button>
          <button [ngClass]="{ selected: displayMode === 'list' }" (click)="setDisplayMode('list')"><ri class="ri-list-check mode-icon"></ri></button>
        </span>
</ng-template>

<ng-template #itemRequestBanner>
  <div class="message-item-request flex-col flex-start-center">
    <div class="message-item-request-header">
      <img alt="close" src="/assets/slicons/messages.svg" class="message-item-request-icon"/>
      <div>
        <div class="cant-find" style="margin-bottom: 12px">Can't find what you're looking for?</div>
        <div>Send an item request.</div>
      </div>
    </div>
    <div class="flex-row-wrap-col-responsive flex-start-space-between full-width">
      <rn-account-status-warning class="item-request-warning-banner" context="placeHold"></rn-account-status-warning>
      <button mat-raised-button color="primary" class="save-button-group save-button item-request-button"
              [disabled]="!patronCanRequest" (click)="openItemRequestModal()">
        Item Request
      </button>
    </div>
  </div>
</ng-template>

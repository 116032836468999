import {Component} from '@angular/core';
import {PatronSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-lookup-account',
  templateUrl: './lookup-account.component.html',
  styleUrls: ['./lookup-account.component.scss'],
})
export class LookupAccountComponent {
  public readonly patronSelectors = PatronSelectors.Registration;

}

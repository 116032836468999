import {ChangeDetectorRef, inject, Injector, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {MxSelector} from '@store/store.types';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'select',
  pure: false // eslint-disable-line @angular-eslint/no-pipe-impure -- This pipe wraps the async pipe, which is also impure.
})
/**
 * Given a selector, selects it from the store and runs it through Angular's async pipe.  Also converts any `null` value coming from `async` to `undefined`
 * instead.  An optional fallback parameter can be used to replace `undefined` if desired (or if needed -- see below [*]).
 * <hr/>
 * Usage examples:
 *
 * Given a container defined in the component like `public selectors = PatronsSelectors.Current;`:
 * <ul>
 *   <li><code>&lt;span>{{ selectors.id | select }}&lt;/span></code></li>
 *   <li><code>&lt;span *ngIf="selectors.patronNameIs('Fred') | select">Fred is here!&lt;/span></code></li>
 *   <li><code>&lt;span>{{ selectors.phone | select:'' | phone }}&lt;/span></code></li>
 * </ul>
 * [*] For the last example, the `selectors.phone | select` is incorrectly marked as a type error by IntelliJ's `JavaScript and TypeScript` plugin.  We can give
 * it a hint by supplying a fallback value of the correct type (`''` is a `string`).  Unfortunately, we need to do this even if the selector already prevents
 * runtime errors with its own fallback.
 */
export class SelectPipe implements PipeTransform, OnDestroy {
  constructor(injector: Injector) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }

  public ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy(); // eslint-disable-line @angular-eslint/no-lifecycle-call -- We must prevent subscription leaks in the async pipe.
  }

  public transform<T>(selector: MxSelector<T>): T | undefined;
  public transform<T>(selector: MxSelector<T>, asyncNullFallbackValue: T): T;
  public transform<T>(selector: MxSelector<T>, asyncNullFallbackValue?: T): T | undefined {
    return this.asyncPipe.transform(this.store.select(selector)) ?? asyncNullFallbackValue; // If no fallback value is supplied, we'll fall back to undefined.
  }

  private asyncPipe: AsyncPipe;
  private store: Store = inject(Store);
}

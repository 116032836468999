import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {Card, payments, TokenResult} from '@square/web-sdk';
import {PatronPaymentService, PaymentMethodSavePayload, STATES} from '@raven';

@Component({
  selector: 'rn-add-payment-method-dialog',
  templateUrl: './add-payment-method-dialog.component.html',
  styleUrls: ['./add-payment-method-dialog.component.scss'],
})
export class AddPaymentMethodDialogComponent implements OnInit {
  STATES = STATES;

  saveCardForm: FormGroup;
  private appId: string;
  private locationId: string;
  private loadingSubject = new BehaviorSubject(true);
  loading$ = this.loadingSubject.asObservable();
  private card: Card;
  enableSubmit = true;

  cardFormStyles = {
    input: {
      fontSize: '14px',
      fontWeight: '400',
    },
  }

  constructor(private fb: FormBuilder,
              private dialogRef: MatDialogRef<AddPaymentMethodDialogComponent>,
              private patronPaymentService: PatronPaymentService,) {
  }

  async ngOnInit() {
    this.saveCardForm = this.fb.group({
      cardholderName: ['', {validators: [Validators.required]}],
      // address: ['', {validators: [Validators.required]}],
      // address2: ['', {validators: []}],
      // city: ['', {validators: [Validators.required]}],
      // state: ['AK', {validators: [Validators.required]}],
      // zip: ['', {validators: [Validators.required]}],
    });

    this.appId = this.patronPaymentService.getSquareAppId();
    this.locationId = this.patronPaymentService.getSquareLocationId();

    try {
      const sq = await payments(this.appId, this.locationId);
      this.card = await sq.card({style: this.cardFormStyles});
      await this.card.attach('#card-container');
      this.loadingSubject.next(false);
    } catch (e) {
      console.error('Error initializing card form', e);
      this.dialogRef.close(false);
    }
  }

  async submit() {
    if (!this.enableSubmit || !this.saveCardForm.valid) {
      return;
    }
    this.loadingSubject.next(true);

    try {
      const tokenResult = await this.card.tokenize();
      if (!this.tokenValid(tokenResult)) {
        this.loadingSubject.next(false);
      }

      const body = {
        squareToken: tokenResult.token,
        cardholderName: this.saveCardForm.get('cardholderName').value
      } as PaymentMethodSavePayload;
      const result = this.patronPaymentService.savePaymentMethod(body);
      if (result) {
        this.dialogRef.close(true);
      } else {
        this.enableSubmit = true;
        this.loadingSubject.next(false);
      }
    } catch (e) {
      console.log('Error tokenizing card', e);
    }
  }

  tokenValid(tokenResult: TokenResult): boolean {
    if (tokenResult.errors) {
      console.error(tokenResult.errors);
    }
    return tokenResult.status === 'OK';
  }

  async tokenize(paymentMethod: Card) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }
}

import {NgModule} from '@angular/core';
import {FavoritesModule} from '../favorites/favorites.module';
import {HoldsModule} from '../holds/holds.module';
import {ItemRequestsModule} from '../item-requests/item-requests.module';
import {SharedModule} from '../shared/shared.module';
import {AuthorCarouselComponent} from './carousel/author-carousel.component';
import {PopularSubjectCarouselComponent} from './carousel/popular-subject-carousel.component';
import {CatalogBrowseComponent} from './catalog-browse/catalog-browse.component';
import {CatalogFacetsComponent} from './catalog-browse/components/catalog-facets/catalog-facets.component';
import {ExpandedSearchFacetComponent} from './catalog-browse/components/expanded-search-facet.component';
import {CatalogFacetCollectionComponent} from './catalog-browse/components/catalog-facet-collection/catalog-facet-collection.component';
import {
  CatalogDetailsAvailabilityTabComponent
} from './catalog-item-details/components/catalog-details-availability-tab/catalog-details-availability-tab.component';
import {
  CatalogDetailsBranchAvailabilityComponent
} from './catalog-item-details/components/catalog-details-branch-availability/catalog-details-branch-availability.component';
import {CatalogFooterComponent} from './catalog-footer.component';
import {CatalogHomeComponent} from './catalog-home.component';
import {CatalogItemDetailsComponent} from './catalog-item-details/catalog-item-details.component';
import {CatalogDetailsSummaryTabComponent} from './catalog-item-details/components/catalog-details-summary-tab/catalog-details-summary-tab.component';
import {ShowMoreSectionComponent} from './catalog-item-details/components/show-more-section/show-more-section.component';
import {CatalogDetailsReviewsTabComponent} from './catalog-item-details/components/catalog-details-reviews-tab/catalog-details-reviews-tab.component';
import {AvailabilityCountPillComponent} from './catalog-item-details/components/availability-count-pill/availability-count-pill.component';
import {CirculationItemStatusPillComponent} from './catalog-item-details/components/circulation-item-status-pill/circulation-item-status-pill.component';

@NgModule({
  imports: [
    HoldsModule,
    FavoritesModule,
    ItemRequestsModule,
    SharedModule,
  ],
  exports: [CatalogFooterComponent, ExpandedSearchFacetComponent, CatalogFacetCollectionComponent],
  declarations: [
    AuthorCarouselComponent,
    CatalogHomeComponent,
    CatalogFooterComponent,
    CatalogItemDetailsComponent,
    ExpandedSearchFacetComponent,
    PopularSubjectCarouselComponent,
    CatalogDetailsSummaryTabComponent,
    ShowMoreSectionComponent,
    CatalogDetailsAvailabilityTabComponent,
    CatalogDetailsBranchAvailabilityComponent,
    CatalogDetailsReviewsTabComponent,
    AvailabilityCountPillComponent,
    CirculationItemStatusPillComponent,
    CatalogFacetCollectionComponent,
    CatalogBrowseComponent,
    CatalogFacetsComponent,
  ],
})
export class CatalogModule {
}

<div style="max-width: 1200px; margin: auto" [rvIsLoading]="!(templateData$ | async)">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #content>
  <ng-container *ngTemplateOutlet="header"></ng-container>
  <ng-container *ngTemplateOutlet="overview;"></ng-container>
  <ng-container *ngTemplateOutlet="makeAPayment"></ng-container>
  <ng-container *ngIf="(filteredLedgers$ | async) as ledgers">
    <ng-container
      *ngTemplateOutlet="accountActivity; context: {ledgers:ledgers}"
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template #header>
  <div class="header flex-row flex-end-start">
    <h1>Balance & Payments</h1>
    <span class="info-circle" matTooltip="Review your current balance and recent payments">i</span>
    <a (click)="contactUs()">Contact Us</a>
  </div>
</ng-template>

<ng-template #overview>
  <ng-container *ngIf="(templateData$ | async) as data">
    <ng-container *ngTemplateOutlet="overviewRender; context:{
          recentCharges: data.recentCharges,
          account: data.account
        }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #overviewRender let-account="account" let-recentCharges="recentCharges">
  <!--  <h2>Overview</h2>-->
  <div class="overview">
    <div>
      <rn-billing-account-status-content [account]="account"></rn-billing-account-status-content>
    </div>
    <div class="hide-xs">
      <h3>Recent Charges</h3>
      <div class="overview-value">{{ recentCharges | currency }}</div>
      In the last 7 days
    </div>
    <div class="hide-xs">
      <h3>Total Amount Due</h3>
      <div class="overview-value">
        {{ account.currentAccountBalance | currency }}
      </div>
    </div>
    <div class="hide-xs">
      <h3>Minimum Payment Due
        <em class="ri-information-line" matTooltip="The minimum payment you must make to keep your account active"></em>
      </h3>
      <div class="overview-value">
        {{ account.minimumBalanceDue | currency }}
      </div>
    </div>
    <div class="flex-col flex-start-space-between hide-gt-xs">
      <div class="flex-row" style="width: 100%">
        <h3 class="mobile-balance-row">Recent Charges: </h3>
        <span>{{ recentCharges | currency }}</span>
      </div>
      <div class="flex-row" style="width: 100%">
        <h3 class="mobile-balance-row">Total Amount Due: </h3>
        <span>{{ account.currentAccountBalance | currency }}</span>
      </div>
      <div class="flex-row" style="width: 100%">
        <h3 class="mobile-balance-row">Minimum Payment Due:</h3>
        <span>&nbsp;{{ account.minimumBalanceDue | currency }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #makeAPayment>
  <ng-container *ngIf="templateData$ | async as data">
    <rn-make-a-payment-form [patronAccountStatus]="data.account" [startExpanded]="!isMobile"
                            (paymentCreated)="refreshLedgersAndStatus()"></rn-make-a-payment-form>
  </ng-container>
</ng-template>

<ng-template #accountActivity let-ledgers="ledgers">
  <div class="accountActivity">
    <div class="flex-row flex-center-start">
      <h1 style="margin-bottom: 0">Account Activity</h1>
      <ng-container *ngTemplateOutlet="activityFilters" style="margin-left: auto"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="showActivity; context: {ledgers:ledgers}"></ng-container>
    <ng-container *ngIf="ledgers.length === 0 then noActivity"></ng-container>
  </div>
</ng-template>

<ng-template #activityFilters>
  <rn-activity-filter [changes]="activityFilterChanges" style="margin-left: auto"></rn-activity-filter>
</ng-template>

<ng-template #noActivity>
  <div class="empty">No activity.</div>
</ng-template>

<ng-template #showActivity let-ledgers="ledgers">
  <table mat-table aria-label="Ledgers" [dataSource]="ledgers" matSort (matSortChange)="sort($event)">
    <ng-container matColumnDef="date">
      <th mat-header-cell mat-sort-header="created" *matHeaderCellDef scope="col">
        Date
      </th>
      <td mat-cell *matCellDef="let ledger">
        {{ ledger.created | date:'shortDate' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef scope="col">Type</th>
      <td mat-cell *matCellDef="let ledger">
        <ng-container *ngIf="!isCharge(ledger) else chargeType">
          Payment<br>({{ ledger.paymentMethod | titlecase }})
        </ng-container>
        <ng-template #chargeType>
          Charge<br>({{ ledger.chargeType | titlecase }})
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="description-item">
      <th mat-header-cell *matHeaderCellDef scope="col">Description/Item</th>
      <td mat-cell *matCellDef="let ledger">
        <ng-container *ngIf="isCharge(ledger) else paymentDescription">
          <ng-container *ngIf="ledger.itemTitle">
            {{ ledger.itemTitle }}
            <rn-barcode-popover *ngIf="ledger.circulationItemBarcode" [barcode]="ledger.circulationItemBarcode"></rn-barcode-popover>
            <br/>
            <ng-container *ngIf="ledger.itemDueDate">
              Due {{ ledger.itemDueDate | date: 'shortDate' }}
            </ng-container>
          </ng-container>
          {{ ledger.chargeNotes }}
        </ng-container>
        <ng-template #paymentDescription> Account payment</ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="barcode">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let ledger">
        <rn-barcode-popover *ngIf="ledger.circulationItemBarcode" [barcode]="ledger.circulationItemBarcode"></rn-barcode-popover>
      </td>
    </ng-container>
    <ng-container matColumnDef="person">
      <th mat-header-cell mat-sort-header="patronName" *matHeaderCellDef scope="col">
        Person
      </th>
      <td mat-cell *matCellDef="let ledger">
        <ng-container *ngIf="isCharge(ledger)">
          {{ ledger.patronLastName }}, {{ ledger.patronFirstName.charAt(0) }}
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="charges">
      <th mat-header-cell *matHeaderCellDef scope="col">Charges</th>
      <td mat-cell *matCellDef="let ledger">
        {{ isCharge(ledger) ? (ledger.amount | currency) : '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="payments">
      <th mat-header-cell *matHeaderCellDef scope="col">Payments</th>
      <td mat-cell *matCellDef="let ledger">
        {{ !isCharge(ledger) ? (ledger.amount | currency) : '-' }}
      </td>
    </ng-container>
    <!--      <ng-container matColumnDef="total">-->
    <!--        <th mat-header-cell *matHeaderCellDef scope="col">Total Due</th>-->
    <!--        <td mat-cell *matCellDef="let ledger">-->
    <!--          {{ ledger.balance.toFixed(2) }}-->
    <!--        </td>-->
    <!--      </ng-container>-->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef scope="col">Status</th>
      <td mat-cell *matCellDef="let ledger">
        <mat-chip-set>
          <mat-chip [class]="getStatusColorClass(ledger)">
            {{ getStatusDisplayValue(ledger) }}
          </mat-chip>
        </mat-chip-set>
      </td>
    </ng-container>
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let ledger">
        <ng-container *ngTemplateOutlet="mobileTableEntry; context:{ledger: ledger}"></ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumns" class="mx-bold-underline"></tr>
    <tr mat-row *matRowDef="let ledger; columns: ledgers.length === 0 ? [] : displayColumns" class="mx-taller-row"></tr>
  </table>
</ng-template>

<ng-template #mobileTableEntry let-ledger="ledger">
  <div class="mobile-activity-grid">
    <div>Date:</div>
    <div>{{ ledger.created | date:'shortDate' }}</div>
    <mat-chip-set class="mobile-activity-pill">
      <mat-chip [class]="getStatusColorClass(ledger)">
        {{ getStatusDisplayValue(ledger) }}
      </mat-chip>
    </mat-chip-set>
    <ng-container *ngIf="!isCharge(ledger) else chargeType">
      <div>Type:</div>
      <div>{{ ledger.chargeNotes }}Payment ({{ ledger.paymentMethod | titlecase }})</div>
    </ng-container>
    <ng-template #chargeType>
      <div>Type:</div>
      <div>Charge ({{ ledger.chargeType | titlecase }})</div>
    </ng-template>
    <div>Amount:</div>
    <div>{{ (ledger.amount | currency) }}</div>
    <ng-container *ngIf="isCharge(ledger) else paymentDescription">
      <ng-container *ngIf="ledger.itemTitle; else chargeNotes">
        <div>Item:</div>
        <div class="flex-row flex-center-space-between">
          {{ ledger.itemTitle }}
          <rn-barcode-popover *ngIf="ledger.circulationItemBarcode" [barcode]="ledger.circulationItemBarcode"></rn-barcode-popover>
        </div>
        <ng-container *ngIf="ledger.itemDueDate">
          <div>Due:</div>
          <div>{{ ledger.itemDueDate | date: 'shortDate' }}</div>
        </ng-container>
      </ng-container>
      <ng-template #chargeNotes>
        <div>Note:</div>
        <div>{{ ledger.chargeNotes }}</div>
      </ng-template>
      <div>Person:</div>
      <div>{{ ledger.patronLastName }}, {{ ledger.patronFirstName.charAt(0) }}</div>
    </ng-container>
    <ng-template #paymentDescription>
      <div>Note:</div>
      <div>Account payment</div>
    </ng-template>
  </div>
</ng-template>

import {inject, Injectable} from '@angular/core';
import {AsyncValidator, FormControl, Validators} from '@angular/forms';
import {AsyncValidateFunction, ObjectValidators} from '@store/common/common.types';
import {filter, map, of, take} from 'rxjs';
import {PatronActions} from './patron.actions';
import {PatronSelectors} from '@store/store.selectors';
import {Store} from '@ngrx/store';
import {PatronAccountEditValue} from './patron.types';
import {CommonValidators} from '@store/common/common.validators';

@Injectable({providedIn: 'root'})
export class PatronsValidators {
  public readonly patronAccountEdit: ObjectValidators<PatronAccountEditValue> = {
    firstName: [Validators.required, Validators.maxLength(50)],
    lastName: [Validators.required, Validators.maxLength(50)],
    address: [Validators.required, Validators.minLength(3), Validators.maxLength(100)],
    address2: [Validators.maxLength(100)],
    city: [Validators.required, Validators.minLength(3), Validators.maxLength(20)],
    state: [Validators.required],
    zipCode: [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')],
    phone1: [CommonValidators.phoneNumber],
    branchId: [],
    optInReadingHistory: []
  };
}

@Injectable({providedIn: 'root'})
export class PatronEmailAvailableValidator implements AsyncValidator {
  private store: Store = inject(Store);
  public validate: AsyncValidateFunction = control => {
    if (control.pristine || control.value === '' || control.value === (control as FormControl).defaultValue) {
      return of(null);
    }
    this.store.dispatch(PatronActions.validatePatronEmailAvailable(control.value));
    return this.store.select(PatronSelectors.EmailValidation.isAvailable).pipe(
      filter(isAvailable => isAvailable !== 'UNKNOWN'),
      take(1),
      map(isAvailable => isAvailable ? null : {patronEmailUnavailable: true}),
    );
  }
}

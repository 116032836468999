import {Organization} from './organization.types';

export const FALLBACK_ORGANIZATION: Organization = {
  id: 0,
  googleStaffTenantId: '',
  googlePatronTenantId: '',
  name: '',
  slug: '',
  websiteUrl: '',
  staffUrl: '',
  opacUrl: '',
  iconUrl: '',
  bannerUrl: '',
  manualAddressVerificationUrl: '',
  policyUrl: '',
  invitationEmail: '',
  contactEmail: '',
  arcgisBoundaryUrl: '',
  phoneNumber: '',
  hooplaLibraryId: '',

  coverImageSource: 'CHILIFRESH',
  active: false,
  created: '',
  modified: '',

  demoOrganization: false,
  demoStaffUsername: '',
  demoStaffPassword: '',
  demoOpacUsername: '',
  demoOpacPassword: '',
}

import {createActionGroup, emptyProps} from '@ngrx/store';
import {Branch, BRANCH_FEATURE_KEY} from './branch.types';

export const BranchActions = createActionGroup({
  source: BRANCH_FEATURE_KEY,
  events: {
    'Load Auth Branch Success': (branch: Branch) => ({branch}),
    'Load Auth Branch Failure': (error: unknown) => ({error}),

    'Load Active Branches': emptyProps(),
    'Load Active Branches Success': (listData: Branch[]) => ({listData}),
    'Load Active Branches Failure': (error: unknown) => ({error}),
  },
});

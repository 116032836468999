import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CatalogFacetsComponent} from './catalog-facets/catalog-facets.component';

@Component({
  selector: 'rn-catalog-expanded-search-facet',
  template: `
    <div>
      <li>
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="showExpandedSearch"
          (change)="onChange()"
        >
          Expanded Search
        </mat-slide-toggle>
      </li>
      <div
        *ngIf="showExpandedSearchNote && showExpandedSearch"
        class="expanded-search-note"
        style=""
      >
        <div class="flex-row">
          <span class="header">Important Note</span>
          <img
            (click)="showExpandedSearchNote = false"
            class="pointer"
            alt="close"
            src="/assets/slicons/close.svg"
          />
        </div>
        <span
        >Expanded search will include additional item results outside of your
          network.</span
        >
      </div>
    </div>
  `,
  styleUrls: [
    './catalog-facets/catalog-facets.component.scss',
    '../catalog-browse.component.scss',
  ],
  styles: [`
      div > div > div > img {
          height: 18px;
          width: 18px;
          margin-left: auto;
      }

      .expanded-search-note {
          background-color: var(--info-light);
          margin-bottom: 15px;
          padding: 12px;
          border: solid 1px var(--info);

          span {
              color: var(--info);
              margin-bottom: 0.75em;
          }
      }
  `],
  providers: [
    {
      provide: CatalogFacetsComponent,
      useExisting: ExpandedSearchFacetComponent,
    },
  ],
})
export class ExpandedSearchFacetComponent
  extends CatalogFacetsComponent
  implements OnInit {
  // expandedSearchToggleState ;
  showExpandedSearchNote = true;

  @Input() showExpandedSearch: boolean;
  @Output() showExpandedSearchChange = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    // this.checkStates = [
    //   {
    //     value: {displayValue: 'Expanded Search', filterValue: 'Expanded Search', count: 0},
    //     selected: false,
    //   },
    // ];
  }

  onChange(): void {
    // this.checkStates[0] = {
    //   value: {displayValue: 'Expanded Search', filterValue: 'Expanded Search', count: 0},
    //   selected: this.showExpandedSearch,
    // };
    // this.showExpandedSearchChange.emit(this.showExpandedSearch);
  }
}

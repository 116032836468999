import {Injectable} from '@angular/core';
import {RouterStateSerializer} from '@ngrx/router-store';
import {ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot} from '@angular/router';
import {SerializedRouterState} from '@store/router/router.types';
import {hasNoValue, isString} from '@store/common/typing.helpers';
import {omitPropertiesByKeyFrom} from '@store/transformation.helpers';

interface RouteMetadatum {
  title: string;
  path: string;
  name: string;
  params: Params;
  includeInBreadcrumbs: boolean;
}

type RouteMetadata = RouteMetadatum[];

@Injectable({providedIn: 'root'})
export class RouterSerializer implements RouterStateSerializer<SerializedRouterState> {
  public serialize(routerState: RouterStateSnapshot): SerializedRouterState {
    const metadata: RouteMetadata = this.buildRouteMetadataFor(routerState.root);

    return {
      url: routerState.url,
      path: metadata[metadata.length - 1].path,
      breadcrumbs: metadata.filter(metadatum => metadatum.includeInBreadcrumbs).map(metadatum => omitPropertiesByKeyFrom(metadatum, 'includeInBreadcrumbs')),
      params: this.flattenRouterStateFor(routerState.root, route => route.params),
      queryParams: routerState.root.queryParams,
      data: this.flattenRouterStateFor(routerState.root, route => route.data)
    };
  }

  private buildRouteMetadataFor(route: ActivatedRouteSnapshot, path = ''): RouteMetadata {
    const pathToAppend = route.url.map(seg => seg.path).join('/');
    const foundPathToAppend: boolean = pathToAppend.length > 0;
    const updatedPath = foundPathToAppend ? `${path}/${pathToAppend}` : path;
    const title: string = isString(route.routeConfig?.title) && route.routeConfig?.title || 'MISSING TITLE';
    const name: string = route.routeConfig?.data?.name ?? 'MISSING NAME';
    const params: Params = route.params;
    const metadatum: RouteMetadatum = {
      title,
      path: updatedPath,
      name,
      params,
      includeInBreadcrumbs: hasNoValue(route.routeConfig?.data?.tabLabel)
    };

    if (route.children.length === 0) return [metadatum];

    return route.children.reduce(
      (accumulator: RouteMetadata, childRoute: ActivatedRouteSnapshot) => [
        ...accumulator,
        ...foundPathToAppend ? [metadatum] : [],
        ...this.buildRouteMetadataFor(childRoute, updatedPath)
      ],
      []
    );
  }

  private flattenRouterStateFor(route: ActivatedRouteSnapshot, getter: (route: ActivatedRouteSnapshot) => Params | Data): Params | Data {
    if (route.children.length === 0) return getter(route);

    return route.children.reduce(
      (accumulator: Params | Data, childRoute: ActivatedRouteSnapshot) => ({
        ...accumulator,
        ...getter(route),
        ...this.flattenRouterStateFor(childRoute, getter)
      }),
      {}
    );
  }
}

import {map, Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Environment} from '@raven';
import {Patron, PatronRegistrationValue} from './patron.types';
import {ResponseBase} from '@store/common/common.types';

@Injectable({providedIn: 'root'})
export class PatronService {

  private readonly http: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(Environment);

  loadAuthPatron(): Observable<Patron> {
    const url = `${this.environment.apiUrl}/patrons/v2/patron`;
    return this.http.get<ResponseBase<Patron>>(url)
      .pipe(map(response => response.objects[0]))
  }

  public lookupAccount(cardNumber: string, PIN: string): Observable<{ patron: Patron, loginToken: string }> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/login`;
    return this.http.post<ResponseBase<{ patron: Patron, loginToken: string }>>(url, {barcode: cardNumber, pin: PIN})
      .pipe(
        map(response => response.objects[0]),
        map(({patron, loginToken}) =>
          ({patron, loginToken: loginToken.slice()}))
      );
  }

  public validatePatronEmailAvailable(email: string): Observable<boolean> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/existing-email?email=${encodeURIComponent(email)}`;
    return this.http.get<boolean>(url);
  }

  public requestVerificationEmail(email: string): Observable<string> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/request-verification-email`;
    return this.http.post<ResponseBase<string>>(url, {email})
      .pipe(map(response => response.objects[0]));
  }

  public verifyEmail(code: string, verificationUUID: string): Observable<never> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/verify-email`;
    return this.http.post<never>(url, {code, verificationUUID});
  }

  public register(value: PatronRegistrationValue): Observable<never> {
    const url = `${this.environment.apiUrl}/public/v2/patrons/register`;
    const data = {
      ...value,
      mobilePhone: (value.mobilePhone ?? '').replace(/\D/g, '') || null
    };
    return this.http.post<never>(url, data);
  }

  public registerExisting(value: PatronRegistrationValue, verificationUUID: string): Observable<never> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/register-existing`;
    const data = {
      password: value.password,
      branchId: value.branchId,
      verificationUUID: verificationUUID
    };
    return this.http.post<never>(url, data);
  }

}

import {AddressType, DateOnlyIsoString, DateTimeIsoString, State} from '../common/common.types';

export const PATRON_FEATURE_KEY = 'patron';

export interface Patron {
  readonly id: number;

  readonly organizationId: number;
  readonly googleUid: string;
  readonly branchId: number;
  readonly patronCategoryId: number;
  readonly barcode: string;
  readonly pinCode: string;
  readonly preferredName: string;
  readonly email: string;
  readonly notificationEmail: string;
  readonly password: string;
  readonly firstName: string;
  readonly middleName: string;
  readonly lastName: string;
  readonly preferredLanguage: string;
  readonly initials: string;
  readonly salutation: string;
  readonly gender: string;
  readonly dateOfBirth: DateOnlyIsoString;
  readonly accountExpiration: DateOnlyIsoString;
  readonly lastLogin: DateTimeIsoString;
  readonly timezone: string;

  readonly phone1: string;
  readonly phone1Type: string;

  readonly address: string;
  readonly address2: string;
  readonly city: string;
  readonly state: State;
  readonly zipCode: string;
  readonly country: string;

  readonly emailVerified: boolean;
  readonly pinGenerated: boolean;
  readonly mobileNumberVerified: boolean;
  readonly optInReadingHistory: boolean;
  readonly unsubscribeAllNotifications: boolean;
  readonly lookupAttempts: number;

  readonly active: boolean;
  readonly created: DateTimeIsoString;
  readonly modified: DateTimeIsoString;

  readonly underBalanceLimit: boolean;

  readonly category: PatronCategory;
  readonly addresses: PatronAddress[];
  readonly guarantors: PatronGuarantor[];
  readonly checkoutSession: CheckoutSession;
  readonly openHolds: number;

  readonly openLoans: number;
  readonly accountBalance: number;
  readonly patronRule: PatronRule;

  readonly patronStatistics: PatronStatistics;
}

export interface PatronCategory {
  readonly id: number;
  readonly organizationId: number;
  readonly name: string;
  readonly chipColor: string;
  readonly guarantorRequired: boolean;
  readonly dobRequired: boolean;
  readonly boundaryRestricted: boolean;
  readonly expireAtMaxAge: boolean;
  readonly expirationDate: DateOnlyIsoString;
  readonly minAge: number;
  readonly maxAge: number;
  readonly registrationTerm: number;
  readonly registrationFee: number;
  readonly renewalFee: number;
  readonly holdFee: number;
  readonly overdueNotice: boolean;
  readonly showLostItems: boolean;
  readonly active: boolean;
  readonly created: DateTimeIsoString;
  readonly modified: DateTimeIsoString;
}

export interface PatronAddress {
  readonly id: number;
  readonly patronId: number;
  readonly addressTypeId: number;
  readonly addressType: AddressType;
  readonly address: string;
  readonly address2: string;
  readonly city: string;
  readonly state: State;
  readonly zipCode: string;
  readonly country: string;
  readonly verified: boolean;
  readonly inDistrict: boolean;
  readonly primary: boolean;
}

export interface PatronGuarantor {
  readonly id: number;
  readonly organizationId: number;
  readonly: number;
  readonly patronGuarantorId: number;
  readonly hasRenewalPermission: boolean;
  readonly guarantorRelation: string;
  readonly guarantorName: string;
  readonly guarantorPhoneNumber: string;
  readonly guarantorAddress: string;
  readonly active: boolean;
  readonly created: DateTimeIsoString;
  readonly modified: DateTimeIsoString;
}

export interface PatronStatistics {
  readonly openHolds: number;
  readonly openLoans: number;
  readonly claimedReturnedCount: number;
  readonly heldItems: number;
  readonly overdueItems: number;
}

export interface PatronRule {
  readonly id: number;
  readonly organizationId: number;
  readonly patronId: number;
  readonly patronCategoryId: number;
  readonly maxItemsOut: number;
  readonly maxItemsOutBorrowBlocked: boolean;
  readonly maxItemsOutRenewalBlocked: boolean;
  readonly maxItemsOutHoldBlocked: boolean;
  readonly maxItemsOutBlockNote: string;
  readonly maxItemsLost: number;
  readonly maxItemsLostBorrowBlocked: boolean;
  readonly maxItemsLostRenewalBlocked: boolean;
  readonly maxItemsLostHoldBlocked: boolean;
  readonly maxItemsLostBlockNote: string;
  readonly maxItemsOverdue: number;
  readonly maxItemsOverdueBorrowBlocked: boolean;
  readonly maxItemsOverdueRenewalBlocked: boolean;
  readonly maxItemsOverdueHoldBlocked: boolean;
  readonly maxItemsOverdueBlockNote: string;
  readonly maxFeeBalance: number;
  readonly maxFeeBalanceBorrowBlocked: boolean;
  readonly maxFeeBalanceRenewalBlocked: boolean;
  readonly maxFeeBalanceHoldBlocked: boolean;
  readonly maxFeeBalanceBlockNote: string;
  readonly maxHoldsPlaced: number;
  readonly maxPausedHoldsPlaced: number;
  readonly expiredCardHoldBlocked: boolean;
  readonly holdsLimitOnePerCatalogPerPatron: boolean;
  readonly maxHoldsNote: string;
  readonly active: boolean;
  readonly created: DateTimeIsoString;
  readonly modified: DateTimeIsoString;

  readonly patronCategory: PatronCategory;
}

export interface CheckoutSession {
  readonly id: number;
  readonly patronId: number;
  readonly pdfReceiptServingUrl: string;
  readonly pdfFileUri: string;
  readonly circulationItemCount: number;
  readonly active: boolean;
  readonly created: string;
  readonly modified: string;
  readonly checkoutSessionCirculationItems: CheckoutSessionCirculationItem[];
}

export interface CheckoutSessionCirculationItem {
  readonly sessionId: number;
  readonly circulationItemId: number;
  readonly barcode: string;
  readonly title: string;
  readonly mediaType: string;
  readonly dueDate: string;
  readonly dateAdded: string;
}

export const PATRON_ACCOUNT_EDIT_PROPERTIES = [
  'firstName', 'lastName',
  'address', 'address2', 'city', 'state', 'zipCode',
  'phone1', 'branchId', 'optInReadingHistory'
] as const;

export type PatronAccountEditValue = Pick<Patron, typeof PATRON_ACCOUNT_EDIT_PROPERTIES[number]>;

export const PATRON_REGISTRATION_PROPERTIES = [
  'firstName', 'lastName', 'address', 'city', 'state',
  'dateOfBirth', 'notificationEmail', 'password', 'branchId'
] as const;

export type PatronRegistrationValue = Pick<Patron, typeof PATRON_REGISTRATION_PROPERTIES[number]> & {
  zip: string;
  mobilePhone: string;
  notifyEmail: boolean;
  notifyText: boolean;
  verificationUUID: string;
};

export const REGISTRATION_CREATE_ACCOUNT_STEPS = ['enterEmail', 'verifyEmail', 'enterDetails', 'setNotifications', 'createPassword'];
export const REGISTRATION_LOOKUP_ACCOUNT_STEPS = ['lookupAccount', 'verifyEmail', 'createPassword'];
export type RegistrationStep =
  typeof REGISTRATION_CREATE_ACCOUNT_STEPS[number]
  & typeof REGISTRATION_LOOKUP_ACCOUNT_STEPS[number];

import {NgModule} from '@angular/core';
import {HoldStatusFilterComponent} from './components/hold-status-filter.component';
import {DeleteHoldDialog} from './dialogs/delete-hold-dialog';
import {HoldsComponent} from './holds.component';
import {HoldStatusComponent} from './hold-status/hold-status.component';
import {CancelHoldDialog, PauseHoldDialog, UnpauseHoldDialog} from '@raven';
import {SharedModule} from '../shared/shared.module';
import {RvFormsModule} from '../shared/form-fields/rv-forms.module';

@NgModule({
  imports: [SharedModule, RvFormsModule],
  exports: [HoldsComponent, HoldStatusComponent],
  declarations: [
    HoldsComponent,
    PauseHoldDialog,
    UnpauseHoldDialog,
    CancelHoldDialog,
    DeleteHoldDialog,
    HoldStatusComponent,
    HoldStatusFilterComponent
  ],
  providers: []
})
export class HoldsModule {
}

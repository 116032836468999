import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'rv-password-form-field',
  template: `
    <rv-input-form-field [fieldName]="fieldName" [inputType]="showPassword ? 'text' : 'password'" [overrideLabel]="overrideLabel"
                         [externalLabel]="externalLabel" [placeholder]="placeholder" [formGroup]="formGroup" [includeClearButton]="includeClearButton"
                         [auxiliaryButtonIconName]="showPassword ? 'visibility_off' : 'visibility'"
                         [auxiliaryButtonIconClass]="'material-icons-outlined'" [customError]="showAllPasswordRequirements"
                         (auxiliaryButtonClick)="onAuxiliaryButtonClick()"/>
    <mat-error *ngIf="showAllPasswordRequirements && control.touched && control.invalid">
      <span>Your password needs to:
        <ul>
          <li>include both uppercase and lowercase characters.</li>
          <li>include at least one number and one symbol.</li>
          <li>be at least 8 characters long.</li>
        </ul>
      </span>
    </mat-error>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFormFieldComponent implements OnInit {
  @Input() public fieldName: string;
  @Input() public overrideLabel?: string;
  @Input() public externalLabel = false;
  @Input() public placeholder = '';
  @Input() public formGroup: FormGroup;
  @Input() public includeClearButton = true;
  @Input() public showAllPasswordRequirements = false;

  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.
  public showPassword = false;

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;
  }

  public onAuxiliaryButtonClick(): void {
    this.showPassword = !this.showPassword;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PatronAccountStatus} from '../model/patron/patron-account-status';
import {BLOCK_CODE} from '../model/patron/patron';
import {Environment} from '../model/environment';
import {NotificationService} from './notification.service';
import {PatronLedger} from '../model/patron/patron-ledger';
import {PatronTransactions} from '../model/patron/patron-transactions';
import {ResponseBase} from '@raven';
import {snapshot} from '@store/store.helpers';
import {OrganizationSelectors, PatronSelectors} from '@store/store.selectors';
import {Organization} from '@store/organization/organization.types';
import {Patron} from '@store/patron/patron.types';
import {authenticated} from '@store/patron/patron.selectors';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PatronLedgerService {
  patronLedgersIn = new BehaviorSubject<PatronTransactions>(null);
  patronLedgers = this.patronLedgersIn.asObservable();
  patronStatusSubject = new Subject<PatronAccountStatus>();

  organization: Organization = snapshot(OrganizationSelectors.Auth.organization);
  patron: Patron = snapshot(PatronSelectors.Auth.patron);

  constructor(private environment: Environment,
              private notificationService: NotificationService,
              private http: HttpClient,
              private store: Store) {
    if (authenticated()) {
      this.getLedger().subscribe(ledgers => this.patronLedgersIn.next(ledgers));
    }
  }

  getById(patronLedgerId: number): Observable<PatronLedger> {
    const url = `${this.environment.apiUrl}/v1/patron-ledgers/${patronLedgerId}`;

    if (!patronLedgerId) {
      return of(null);
    }

    return this.http.get<PatronLedger>(url).pipe(
      catchError(() => {
        this.notificationService.showSnackbarError(
          'Unable to load patron ledger record'
        );
        return of(null);
      })
    );
  }

  getLedger(): Observable<PatronTransactions> {
    if (!authenticated(this.store)) return of(new PatronTransactions())
    return this.http.get<ResponseBase<PatronTransactions>>(`${this.environment.apiUrl}/patrons/v2/patrons/account/transactions`)
      .pipe(map(rb => rb.objects[0]));
  }

  refreshLedgers(): void {
    this.getLedger().subscribe((_) => this.patronLedgersIn.next(_));
  }

  refreshStatus(): void {
    if (!authenticated(this.store)) return;
    this.http.get<ResponseBase<PatronAccountStatus>>(`${this.environment.apiUrl}/patrons/v2/patrons/account/status`)
      .pipe(map(rb => rb.objects[0]))
      .subscribe(status => this.patronStatusSubject.next(status));
  }

  getAccountStatus(): Observable<PatronAccountStatus> {
    this.refreshStatus();
    return this.patronStatusSubject.asObservable();
  }

  static canCheckout$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return accountStatus$.pipe(map((accountStatus) => {
      return PatronLedgerService.canCheckout(accountStatus);
    }));
  }

  static canPlaceHold$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canRenew$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canRequestItems$(accountStatus$: Observable<PatronAccountStatus>): Observable<boolean> {
    return PatronLedgerService.canCheckout$(accountStatus$);
  }

  static canCheckout(accountStatus: PatronAccountStatus): boolean {
    return accountStatus?.blocks?.length === 0;
  }

  static canPlaceHold(accountStatus: PatronAccountStatus): boolean {
    return PatronLedgerService.canCheckout(accountStatus);
  }

  static canRenew(accountStatus: PatronAccountStatus): boolean {
    return PatronLedgerService.canCheckout(accountStatus);
  }

  static underMaxBalance(accountStatus: PatronAccountStatus): boolean {
    return accountStatus.currentAccountBalance < accountStatus.maximumAccountBalance;
  }

  static isExpired(accountStatus: PatronAccountStatus): boolean {
    return (accountStatus?.blocks?.indexOf(BLOCK_CODE.CARD_EXPIRED) ?? 0) >= 0;
  }

  delete(patronLedgerId: number): Observable<PatronLedger> {
    return new Observable<PatronLedger>(null);
  }
}

<mat-dialog-content class="dialog-wrapper">
  <div class="dialog-close-row flex-row flex-center-end">
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>

  <ng-container *ngIf="(loading$ | async)">
    <div class="flex-container">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <div class="payment-form-colum flex-col flex-gap-18" [class.hidden]="(loading$ | async)">
    <div class="flex-row flex-center-space-between">
      <h3 class="dialog-title">Add a Credit/Debit Card</h3>
      <mat-icon>credit_card</mat-icon>
    </div>
    <span>We accept all major credit and debit cards. Your card will be saved.</span>
    <div class="card-row flex-row flex-gap-4">
      <img src="assets/visa-dark@3x.png" alt="Visa Card">
      <img src="assets/master-card-dark@3x.png" alt="Mastercard Card">
      <img src="assets/american-express-dark@3x.png" alt="American Express Card">
      <img src="assets/discover-dark@3x.png" alt="Discover Card">
    </div>
    <div class="card-details">
      <form [formGroup]="saveCardForm">
        <rv-input-form-field fieldName="cardholderName" [externalLabel]="true" overrideLabel="Cardholder Name"
                             [formGroup]="saveCardForm" subscriptSizing="fixed"/>
      </form>
      <div id="card-container"></div>
      <button [disabled]="!enableSubmit || !saveCardForm.valid" (click)="submit()" mat-raised-button color="primary" class="save-button">Add Card</button>
    </div>
  </div>
</mat-dialog-content>

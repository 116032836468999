import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CheckoutSession, Environment, Patron} from '@raven';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  constructor(private environment: Environment, private router: Router) {
  }

  public goToIndex(): Promise<boolean> {
    return this.router.navigate(['/']);
  }

  // *********** REGISTRATION ROUTES ***********

  public continueRegistration(patron: Patron): Promise<boolean> {
    return this.router.navigate(['/switch-organization']);
  }

  // *********** SWITCH ROUTES ***********

  public goToSwitchOrganization(): Promise<boolean> {
    return this.router.navigate(['/switch-organization']);
  }

  public goToSwitchBranch(): Promise<boolean> {
    return this.router.navigate(['/switch-branch']);
  }

  // *********** BRANCH ROUTES ***********

  public goToBranches(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/branches']);
  }

  public goToBranchDetail(
    organizationId: number,
    branchId: number
  ): Promise<boolean> {
    return this.router.navigate(['/branches', branchId]);
  }

  public goToBranchCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/branches', 'new']);
  }

  public goToBranchEdit(
    organizationId: number,
    branchId: number
  ): Promise<boolean> {
    return this.router.navigate(['/branches', branchId, 'edit']);
  }

  // *********** CHECKIN ROUTES ***********

  public goToCheckin(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/checkin']);
  }

  // *********** CHECKOUT-SESSION ROUTES ***********
  public goToCheckoutSessionDashboard(
    organizationId: number
  ): Promise<boolean> {
    return this.router.navigate(['/checkout-sessions', 'dashboard']);
  }

  public goToCheckoutSession(
    organizationId: number,
    patronId: number
  ): Promise<boolean> {
    return this.router.navigate([
      '/patrons',
      patronId,
      'checkout-sessions',
      'new',
    ]);
  }

  // public goToResumeCheckoutSession(organizationId: number, patronId: number, sessionId: number): Promise<boolean> {
  //   return this.router.navigate(['/checkout-sessions', sessionId, 'patrons', patronId]);
  // }

  public goToResumeCheckoutSession(
    organizationId: number,
    sessionId: number,
    checkoutSession: CheckoutSession
  ): Promise<boolean> {
    return this.router.navigate(['/checkout-sessions', sessionId], {
      state: {data: checkoutSession},
    });
  }

  public goToCheckouts(): Promise<boolean> {
    return this.router.navigate(['/checkouts']);
  }

  // *********** CIRCULATION-EVENT ROUTES ***********

  public goToCirculationEvents(organizationId: number): Promise<boolean> {
    return this.router.navigate([
      '/organizations',
      organizationId,
      'circulation',
      'events',
    ]);
  }

  public goToCirculationEventDetail(organizationId: number, circulationEventId: number): Promise<boolean> {
    return this.router.navigate(['/organizations', organizationId, 'circulation', 'events', circulationEventId]);
  }

  // *********** CIRCULATION ITEM ROUTES ***********

  public goToCirculationDashboard(): Promise<boolean> {
    return this.router.navigate(['/circulation']);
  }

  public goToCheckoutCirculationItem(organizationId: number, circulationItemId: number): Promise<boolean> {
    return this.router.navigate(['/circulation-items', circulationItemId, 'checkout',]);
  }

  public goToCirculationItemCreate(organizationId: number, itemId: number): Promise<boolean> {
    return this.router.navigate(['/catalog-records', itemId, 'circulation-items', 'new',]);
  }

  public goToCirculationItemDetail(circulationItemId: number): Promise<boolean> {
    return this.router.navigate(['/circulation-items', circulationItemId]);
  }

  public goToCirculationItemEdit(circulationItemId: number): Promise<boolean> {
    return this.router.navigate(['/circulation-items', circulationItemId, 'edit',]);
  }

  public goToLoanedCirculationItems(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/circulation-items', 'loaned']);
  }

  public goToCirculationItemsForItemId(organizationId: number, itemId: number): Promise<boolean> {
    return this.router.navigate(['/circulation-items', itemId]);
  }

  // *********** CIRCULATION RULES ROUTES ***********

  public goToCirculationRules(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/circulation-rules']);
  }

  public goToCirculationRulesCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/circulation-rules', 'new']);
  }

  // *********** DEFAULT DEPARTMENTS ROUTES ***********

  public goToDefaultDepartments(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/settings/default-departments']);
  }

  public goToDefaultDepartmentCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/settings/default-departments', 'new']);
  }

  // ***** DEPARTMENT ROUTES *****

  public goToDepartments(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/departments']);
  }

  public goToDepartmentDetail(organizationId: number, departmentId: number): Promise<boolean> {
    return this.router.navigate(['/departments', departmentId]);
  }

  public goToDepartmentCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/departments', 'new']);
  }

  public goToDepartmentEdit(organizationId: number, departmentId: number): Promise<boolean> {
    return this.router.navigate(['/departments', departmentId, 'edit']);
  }

  //
  // public goToDepartments(organizationId: number, branchId: number): Promise<boolean> {
  //   return this.router.navigate(['/branches', branchId, 'departments'])
  // }
  //
  // public goToDepartmentDetail(organizationId: number, departmentId: number, branchId: number): Promise<boolean> {
  //   return this.router.navigate(['/branches', branchId, '/departments', departmentId])
  // }
  //
  // public goToDepartmentCreate(organizationId: number, branchId: number): Promise<boolean> {
  //   return this.router.navigate(['/branches', branchId, '/departments', 'new'])
  // }
  //
  // public goToDepartmentEdit(organizationId: number, branchId: number, departmentId: number): Promise<boolean> {
  //   return this.router.navigate(['/branches', branchId, '/departments', departmentId, 'edit'])
  // }

  // ***** ITEM DAMAGE ROUTES *****

  public goToDamageReports(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/damage-reports']);
  }

  public goToReviewDamageReports(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/damage-reports', 'review']);
  }

  public goToDamageReportCreateByCheckin(
    organizationId: number,
    checkinRecordId: number
  ): Promise<boolean> {
    return this.router.navigate([
      '/damage-reports',
      'new',
      'check-in',
      checkinRecordId,
    ]);
  }

  public goToDamageReportCreateByCirculationItem(
    organizationId: number,
    circulationItemId: number
  ): Promise<boolean> {
    return this.router.navigate([
      '/damage-reports',
      'new',
      'circulation-item',
      circulationItemId,
    ]);
  }

  public goToDamageReportEdit(
    organizationId: number,
    damageReportId: number
  ): Promise<boolean> {
    return this.router.navigate(['/damage-reports', damageReportId, 'edit']);
  }

  // *********** ITEM REPAIR ROUTES ***********

  public goToItemRepairs(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/item-repairs-dashboard']);
  }

  public goToItemRepairDetail(repairReportId: number): Promise<boolean> {
    return this.router.navigate(['/item-repairs-dashboard', repairReportId]);
  }

  // *********** ITEM REQUESTS FROM PATRON ***********

  public goTo(repairReportId: number): Promise<boolean> {
    return this.router.navigate(['/item-repairs-dashboard', repairReportId]);
  }

  // *********** ITEM ROUTES ***********

  public goToCatalogHome(): Promise<boolean> {
    return this.router.navigate(['/catalog']);
  }

  public goToCatalogRecords(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/catalog-search']);
  }

  public goToCatalogRecordDetail(itemId: number): Promise<boolean> {
    return this.router.navigate(['/catalog-item', itemId]);
  }

  public goToCatalogRecordCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/catalog-records', 'new']);
  }

  public goToCatalogRecordEdit(
    organizationId: number,
    itemId: number
  ): Promise<boolean> {
    return this.router.navigate(['/catalog-records', itemId, 'edit']);
  }

  // *********** FEE TYPE ROUTES ***********

  public goToFeeTypes(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/fee-types']);
  }

  public goToFeeTypeDetail(
    organizationId: number,
    feeTypeId: number
  ): Promise<boolean> {
    return this.router.navigate(['/fee-types', feeTypeId]);
  }

  public goToFeeTypeCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/fee-types', 'new']);
  }

  public goToFeeTypeEdit(
    organizationId: number,
    feeTypeId: number
  ): Promise<boolean> {
    return this.router.navigate(['/fee-types', feeTypeId, 'edit']);
  }

  // *********** HOLD ROUTES ***********

  public goToHolds(): Promise<boolean> {
    return this.router.navigate(['/holds']);
  }

  public goToHoldsPullList(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/holds/pick-list']);
  }

  public goToHoldsShelfList(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/holds'], {
      queryParams: {status: 'pulled'},
    });
  }

  public goToClearHoldsShelf(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/holds'], {
      queryParams: {status: 'expired'},
    });
  }

  public goToHoldsQueue(
    organizationId: number,
    catalogueRecordId: number
  ): Promise<boolean> {
    return this.router.navigate(['/holds', 'queue', catalogueRecordId]);
  }

  // *********** MARC 21 RECORD ROUTES ***********

  public goToMarcDashboard(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/marc']);
  }

  public goToMarcDetail(
    organizationId: number,
    marcId: number
  ): Promise<boolean> {
    return this.router.navigate([
      '/organizations',
      organizationId,
      'marc',
      marcId,
    ]);
  }

  public goToMarcEdit(
    organizationId: number,
    marcId: number
  ): Promise<boolean> {
    return this.router.navigate([
      '/organizations',
      organizationId,
      'marc',
      marcId,
      'edit',
    ]);
  }

  // *********** MEDIA TYPE ROUTES ***********

  public goToMediaTypes(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/media-types']);
  }

  public goToMediaTypeDetail(
    organizationId: number,
    mediaTypeId: number
  ): Promise<boolean> {
    return this.router.navigate(['/media-types', mediaTypeId]);
  }

  public goToMediaTypeCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/media-types', 'new']);
  }

  public goToMediaTypeEdit(
    organizationId: number,
    mediaTypeId: number
  ): Promise<boolean> {
    return this.router.navigate(['/media-types', mediaTypeId, 'edit']);
  }

  // *********** ORGANIZATION ROUTES ***********

  public goToOrganizations(): Promise<boolean> {
    return this.router.navigate(['/organizations']);
  }

  public goToDetail(
    objectType: string,
    organizationId: number
  ): Promise<boolean> {
    //
    if (objectType === 'organization') {
      return this.router.navigate(['/organizations', organizationId]);
    }
  }

  public goToOrganizationDetail(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/organizations', organizationId]);
  }

  public goToOrganizationCreate(): Promise<boolean> {
    return this.router.navigate(['/organizations', 'new']);
  }

  public goToOrganizationEdit(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/organizations', organizationId, 'edit']);
  }

  // *********** PATRON ITEM REQUEST ROUTES ***********

  goToPatronItemRequestDashboard(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-item-request-dashboard']);
  }

  public goToPatronItemRequest(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-item-requests']);
  }

  public goToPatronItemRequestDetails(
    organizationId: number,
    patronItemRequestId: number
  ): Promise<boolean> {
    return this.router.navigate([
      '/patron-item-request-details',
      patronItemRequestId,
    ]);
  }

  goToPatronItemRequestHistory(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-item-request-history']);
  }

  // ***** PATRON ROUTES *****

  public goToPatrons(organizationId: number): Promise<boolean> {
    return this.router.navigate(['patrons']);
  }

  public goToPatronDetail(
    organizationId: number,
    patronId: number
  ): Promise<boolean> {
    return this.router.navigate(['/patrons', patronId]);
  }

  public goToBilling(): Promise<boolean> {
    return this.router.navigate(['/billing', 'account']);
  }

  public goToBillingCreate(): Promise<boolean> {
    return this.router.navigate(['/billing', 'new']);
  }

  public goToBillingDetail(ledgerId: number): Promise<boolean> {
    return this.router.navigate(['/billing', ledgerId]);
  }

  //
  // public goToPatronCreate(organizationId: number) : Promise<boolean> {
  //   return this.router.navigate(['/organizations', organizationId, 'patrons', 'new'])
  // }

  public goToPatronCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patrons', 'new']);
  }

  public goToPatronEdit(
    organizationId: number,
    patronId: number
  ): Promise<boolean> {
    return this.router.navigate(['/patrons', patronId, 'edit']);
  }

  // *********** PATRON-FEES ROUTES ***********

  public goToPatronFees(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-fees']);
  }

  public goToPatronFeeCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-fees', 'new']);
  }

  public goToPatronFeesDashboard(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-fees-dashboard']);
  }

  // *********** PATRON RULES ROUTES ***********

  public goToPatronRules(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-rules']);
  }

  public goToPatronRulesCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-rules', 'new']);
  }

  // *********** PATRON CATEGORIES ROUTES ***********

  public goToPatronCategories(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-categories']);
  }

  public goToPatronCategoriesCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/patron-categories', 'new']);
  }

  // *********** REPAIR REVIEW ROUTES ***********

  public goToRepairReviewList(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/repair-reports']);
  }

  // *********** TRANSFER REASONS ROUTES ***********

  public goToTransferReasons(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/transfer-reasons']);
  }

  public goToTransferReasonsCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/transfer-reasons', 'new']);
  }

  // *********** SETTINGS ROUTES ***********

  public goToSettings(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/settings']);
  }

  public goToSettingsIntegrations(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/settings/integrations']);
  }

  public goToSettingIntegrationBakerTaylor(
    organizationId: number
  ): Promise<boolean> {
    return this.router.navigate(['/settings/integrations/baker-taylor']);
  }

  public goToBakerTaylorPurchase(organizationId: number): Promise<boolean> {
    return this.router.navigate([
      'acquisitions',
      'baker-taylor',
      'purchases',
      'new',
    ]);
  }

  public goToBakerTaylorPurchases(organizationId: number): Promise<boolean> {
    return this.router.navigate(['acquisitions', 'baker-taylor', 'purchases']);
  }

  // *********** SHELF ROUTES ***********

  public goToShelves(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/shelves']);
  }

  public goToShelfDetail(
    organizationId: number,
    shelfId: number
  ): Promise<boolean> {
    return this.router.navigate(['/shelves', shelfId]);
  }

  public goToShelfCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/shelves', 'new']);
  }

  public goToShelfEdit(
    organizationId: number,
    shelfId: number
  ): Promise<boolean> {
    return this.router.navigate(['/shelves', shelfId, 'edit']);
  }

  // *********** USER ROUTES ***********

  public goToUserDetail(
    organizationId: number,
    userId: number
  ): Promise<boolean> {
    return this.router.navigate(['/users', userId]);
  }

  public goToUserCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/users', 'new']);
  }

  public goToUserEdit(
    organizationId: number,
    userId: number
  ): Promise<boolean> {
    return this.router.navigate(['/users', userId, 'edit']);
  }

  public goToUserRoles(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/user-roles']);
  }

  public goToUserRoleDetail(
    organizationId: number,
    userRoleId: number
  ): Promise<boolean> {
    return this.router.navigate(['/user-roles', userRoleId]);
  }

  public goToUserRoleCreate(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/user-roles', 'new']);
  }

  public goToUserRoleEdit(
    organizationId: number,
    userRoleId: number
  ): Promise<boolean> {
    return this.router.navigate(['/user-roles', userRoleId, 'edit']);
  }

  public goToUsers(organizationId: number): Promise<boolean> {
    return this.router.navigate(['/users']);
  }

  public goToProfile(): Promise<boolean> {
    return this.router.navigate(['/profile'], {fragment: 'account'});
  }

  public goToNotificationPreferences(): Promise<boolean> {
    return this.router.navigate(['/profile'], {
      fragment: 'notification-preferences',
    });
  }

  public goToPatronBilling(): Promise<boolean> {
    return this.router.navigate(['/profile'], {fragment: 'billing'});
  }

  public goToPatronPaymentInfo(): Promise<boolean> {
    return this.router.navigate(['/profile'], {
      fragment: 'payment-information',
    });
  }

  public goToPatronHistory(): Promise<boolean> {
    return this.router.navigate(['/profile'], {fragment: 'history'});
  }

  public goToMyCard(): Promise<boolean> {
    return this.router.navigate(['/my-card']);
  }

  public openPDF(servingURL: string): Promise<boolean> {
    console.log('opening pdf location: ', servingURL);
    window.open(servingURL, '_blank');
    return Promise.resolve(true);
  }

  /* ********** ERROR PAGES ********** */

  public goTo401(): Promise<boolean> {
    return this.router.navigate(['/401']);
  }

  public goTo403(): Promise<boolean> {
    return this.router.navigate(['/403']);
  }

  public goTo404(): Promise<boolean> {
    return this.router.navigate(['/404']);
  }

  public goTo500(): Promise<boolean> {
    return this.router.navigate(['/500']);
  }
}

import {Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Organization} from './organization.types';
import {Environment} from '@raven';

@Injectable({providedIn: 'root'})
export class OrganizationService {

  private readonly http: HttpClient = inject(HttpClient);
  private readonly environment: Environment = inject(Environment);

  loadAuthOrganization(): Observable<Organization> {
    const url = `${this.environment.apiUrl}/public/v1/organizations/tenant/patron-ui`;
    return this.http.get<Organization>(url, {});
  }
}

import {createReducer, on} from '@ngrx/store';
import {INITIAL_LOAD_STATUS_SUBSTATE, LoadStatusSubstate} from '../store.types';
import {OrganizationActions} from './organization.actions';
import {Organization} from './organization.types';
import {FALLBACK_ORGANIZATION} from './organization.fallback';
import {FAILED, LOADED, LOADING} from '@store/common/common.types';

export interface OrganizationState {
  auth: LoadStatusSubstate & {
    data?: Organization
  };
}

export const INITIAL_ORGANIZATION_STATE: OrganizationState = {
  auth: {
    ...INITIAL_LOAD_STATUS_SUBSTATE,
    data: FALLBACK_ORGANIZATION
  }
};

export const organizationState = createReducer(
  INITIAL_ORGANIZATION_STATE,
  on(OrganizationActions.initializeApp, state => ({
    ...state,
    auth: {
      ...INITIAL_LOAD_STATUS_SUBSTATE,
      loadStatus: LOADING
    }
  })),
  on(OrganizationActions.loadAuthOrganizationSuccess, (state, {organization}) => ({
    ...state,
    auth: {
      loadStatus: LOADED,
      data: organization
    }
  })),
  on(OrganizationActions.loadAuthOrganizationFailure, state => ({
    ...state,
    auth: {
      ...INITIAL_LOAD_STATUS_SUBSTATE,
      loadStatus: FAILED
    }
  }))
);

export const BRANCH_FEATURE_KEY = 'branches';

export interface Branch {
  readonly id: number;
  readonly organizationId: number;
  readonly name: string;
  readonly address: string;
  readonly address2: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly country: string;
  readonly phone: string;
  readonly url: string;
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Hold, HoldService, RoutesService} from '@raven';

/**
 * UNPAUSE HOLD DIALOG COMPONENT
 */
@Component({
  selector: 'rn-unpause-hold-dialog',
  template: `
    <mat-dialog-content class="hold-dialog flex-col">
      <h3>Resume Hold{{ unpauseableHolds.length | plural }} ({{ unpauseableHolds.length }})</h3>
      <div class="flex-col flex-start-start">
        <ng-container *ngIf="cantUnpause > 0">
          <div class="warn-box dialog-warn-box flex-row flex-center-start">
            <span>{{ cantUnpause }} of the selected items cannot be un-paused.</span>
          </div>
        </ng-container>
        <span class="Text-Style dialog-body">
                Are you sure you want to unpause/resume {{ unpauseableHolds.length }} item hold{{ unpauseableHolds.length | plural }}?
              </span>
      </div>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">No</button>
        <button mat-raised-button color="primary" class="save-button-group save-button" (click)="onUnpause()">
          Yes, resume
        </button>
      </div>
    </mat-dialog-content>
  `
})
export class UnpauseHoldDialog {
  unpauseableHolds: Hold[];
  cantUnpause: number;

  constructor(
    public dialogRef: MatDialogRef<UnpauseHoldDialog>,
    private holdService: HoldService,
    public routesService: RoutesService,
    @Inject(MAT_DIALOG_DATA) public data: { holds: Hold[], refreshHolds: () => void }
  ) {
    this.unpauseableHolds = HoldService.getUnpausableHolds(data.holds);
    this.cantUnpause = data.holds.length - this.unpauseableHolds.length;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onUnpause(): void {
    this.holdService.resume(
      this.unpauseableHolds.map((hold) => hold.id),
      {onSuccess: this.onSuccess.bind(this)}
    );
  }

  onSuccess(result: boolean): void {
    if (result) {
      this.data.refreshHolds();
      this.dialogRef.close(true);
    }
  }
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {Checkout, CirculationItemClaimedReturned, RoutesService} from '@raven';

/**
 * CLAIM RETURNED DIALOG COMPONENT
 */
@Component({
  selector: 'rn-claim-returned-dialog',
  template: `
    <mat-dialog-content class="dialog-wrapper flex-col">
      <h1>Submit Claim</h1>
      <h4>
        <div class="details-title">{{ checkout.itemTitle }}</div>
        <div class="details-text">{{ checkout.itemMediaType }}</div>
        <div class="details-text">{{ checkout.itemBarcode }}</div>
      </h4>
      <form [formGroup]="claimForm">
        <mat-radio-group aria-label="Select an option" formControlName="claimType" class="flex-col flex-gap-8">
          <mat-radio-button value="CLAIMED_RETURNED">I returned this item.</mat-radio-button>
          <ng-container *ngIf="claimForm.value.claimType === 'CLAIMED_RETURNED'">
            <p>Please provide any relevant details, such as when the item was returned and what location it was returned to:</p>
            <rv-textarea-form-field fieldName="comment" overrideLabel="Comment" [formGroup]="claimForm" [initialRows]="6"/>
          </ng-container>
          <mat-radio-button value="CLAIMED_NEVER_HAD">I never checked out this item.
          </mat-radio-button>
          <ng-container *ngIf="claimForm.value.claimType === 'CLAIMED_NEVER_HAD'">
            <p>Please provide any relevant details, such as if you checked out any items on the same day and what location you most recently visited:</p>
            <rv-textarea-form-field fieldName="comment" overrideLabel="Comment" [formGroup]="claimForm" [initialRows]="6"/>
          </ng-container>
        </mat-radio-group>
      </form>
      <mat-divider></mat-divider>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" class="save-button-group save-button" [disabled]="!claimForm.valid" (click)="onSubmit()">
          Submit Claim
        </button>
      </div>
    </mat-dialog-content>
  `,
  styles: [`
      mat-dialog-content.dialog-wrapper {
          width: 31.3rem;
          padding-bottom: 1rem;
      }

      .details-title {
          font-weight: 600;
          line-height: 1.5rem;
      }

      .details-text {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
      }

      mat-divider {
          margin-top: 1rem;
      }

      div.dialog-button-row {
          margin-top: 0.75rem;
      }

      button.save-button-group {
          height: 3.5rem;
      }
  `]
})
export class ClaimReturnedDialog {
  public readonly claimForm = this.fb.group({
    claimType: [null, {validators: [Validators.required]}],
    comment: ['', {validators: []}],
  });
  public readonly checkout: Checkout;

  constructor(
    public dialogRef: MatDialogRef<ClaimReturnedDialog>,
    public routesService: RoutesService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      checkout: Checkout;
    }) {
    this.checkout = data.checkout;
  }

  length(str): number {
    return str ? str.length : 0;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    const request: CirculationItemClaimedReturned = {
      checkoutId: this.checkout.checkoutId,
      type: this.claimForm.value.claimType,
      comment: this.claimForm.value.comment
    };
    this.dialogRef.close(request);
  }
}

import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from '@raven';
import {PatronService} from '../../../services/patron.service';
import {CommonValidators} from '@store/common/common.validators';

/**
 * PIN UPDATE DIALOG COMPONENT
 */
@Component({
  selector: 'fn-pin-update-dialog',
  template: `
    <div mat-dialog-content>
      <mat-card-title><h1>Update PIN</h1></mat-card-title>
      <mat-card-content>
        <form [formGroup]="pinUpdateForm" (keydown.enter)="onSubmit()" class="flex-col flex-start-end">
          <rv-password-form-field fieldName="pin" [externalLabel]="true" overrideLabel="New PIN" [includeClearButton]="false"
                                  [formGroup]="pinUpdateForm"/>
          <mat-card-actions align="end" style="margin-bottom: 0; margin-top: 15px;" class="button-row-right">
            <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel" (click)="onNoClick()" data-cy="on-hold-button">
              Cancel
            </button>
            <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button" [disabled]="!this.pinUpdateForm.valid"
                    (click)="onSubmit()">
              Update PIN
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </div>
  `,
  styles: [`
      form {
          padding-top: 20px;
      }

      rv-password-form-field {
          width: 100%;
      }
  `]
})
export class PinUpdateDialog {
  pinUpdateForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<PinUpdateDialog>,
    private patronService: PatronService,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    this.pinUpdateForm = this.fb.group({
      pin: ['', [Validators.required, CommonValidators.pattern(/^\d{4,10}$/, {mustBePIN: true})]],
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    const pin = this.pinUpdateForm.value.pin;

    // Check if we have a valid pin
    if (PinUpdateDialog.validPin(pin)) {
      this.patronService.updatePin(pin, {
        onSuccess: (success) => {
          if (success) {
            this.notificationService.showSnackbarSuccess('PIN has been updated');
          } else {
            this.notificationService.showSnackbarError('Unable to update PIN. Please try again later.');
          }
          this.dialogRef.close(success);
        },
        errorMessage: 'Unable to update PIN. Please try again later.'
      });
    } else {
      this.notificationService.showSnackbarError(
        'Invalid PIN entered. Length must be between 4 - 10 digits long and cannot contain any characters.'
      );
    }
  }

  private static validPin(pin: string): boolean {
    const onlyDigits = /^\d+$/.test(pin);
    return onlyDigits && pin.length > 3 && pin.length < 11;
  }
}

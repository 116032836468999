import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, take} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Environment} from '../model/environment';
import {ResponseBase} from '../model/response-base/response-base';
import {CatalogRecord} from '../model/catalog-record';
import {authenticated} from '@store/patron/patron.selectors';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {

  favoritesPipe = new BehaviorSubject(null);
  favorites = this.favoritesPipe.asObservable();

  favorites$: Observable<any>;

  constructor(private environment: Environment,
              private http: HttpClient) {
    this.http = http;
    this.environment = environment;
    if (!authenticated()) return;
    // we are logged in, initialize favorites:
    this.getFavoritesIds()
      .pipe(
        map((response) => {
          const index = {};
          response.objects.forEach((cr) => {
            index[cr] = true;
          });
          return index;
        })
      )
      .subscribe((index) => {
        this.favoritesPipe.next(index);
      });
  }

  setPatronFavoriteFlag(response: ResponseBase<CatalogRecord>) {
    response.objects.forEach((cr) => {
      cr.patronFavorite = true;
    });

    return response;
  }

  getFavoritesIds(): Observable<ResponseBase<number>> {
    return this.http
      .get<ResponseBase<number>>(`${this.environment.apiUrl}/patrons/favorites/ids`, {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
      });
  }

  getFavorites(page: number, limit: number): Observable<ResponseBase<CatalogRecord>> {
    if (!(page && page >= 0)) {
      page = 0;
    }
    if (!(limit && limit > 0)) {
      limit = 25;
    }
    return this.http
      .get<ResponseBase<CatalogRecord>>(`${this.environment.apiUrl}/patrons/favorites`, {
        params: {page: page, limit: limit},
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
      })
      .pipe(map(this.setPatronFavoriteFlag.bind(this)));
  }

  addFavorite(catalogRecordId: number): Observable<any> {
    return this.http
      .put<ResponseBase<void>>(
        `${this.environment.apiUrl}/patrons/v2/favorites`,
        '',
        {
          params: {catalogRecordId: catalogRecordId},
          headers: new HttpHeaders({'Content-Type': 'application/json'}),
        }
      )
      .pipe(
        tap(() => {
          this.favorites
            .pipe(take(1))
            .pipe(
              tap((index) => {
                index[catalogRecordId] = true;
                this.favoritesPipe.next(index);
              })
            )
            .subscribe();
        })
      );
  }

  deleteFavorite(catalogRecordId: number): Observable<any> {
    return this.http
      .delete<HttpResponse<number>>(
        `${this.environment.apiUrl}/patrons/favorite`,
        {
          params: {catalogRecordId: catalogRecordId},
          headers: new HttpHeaders({'Content-Type': 'application/json'}),
        }
      )
      .pipe(
        tap(() => {
          this.favorites
            .pipe(take(1))
            .pipe(
              tap((index) => {
                delete index[catalogRecordId];
                this.favoritesPipe.next(index);
              })
            )
            .subscribe();
        })
      );
  }

  deleteAllFavorites(): Observable<any> {
    return this.http
      .delete<HttpResponse<number>>(
        `${this.environment.apiUrl}/patrons/favorites`,
        {
          headers: new HttpHeaders({'Content-Type': 'application/json'}),
        }
      )
      .pipe(
        tap(() => {
          this.favorites
            .pipe(take(1))
            .pipe(
              tap((index) => {
                this.favoritesPipe.next({});
              })
            )
            .subscribe();
        })
      );
  }
}

<ng-container *ngIf="active$ | async as active">
  <div class="flex-row-wrap">
    <div class="mat-headline-4 item-requests-title">Active Requests ({{ active.length }})</div>
  </div>
  <table
    mat-table
    aria-label="Item Requests"
    #aSort="matSort"
    matSort
    matSortActive="created"
    matSortDirection="asc"
    [dataSource]="activeDataSource"
    (matSortChange)="handleSortChange($event)">
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let request">
        <ng-container *ngTemplateOutlet="mobileTableEntry; context:{request: request}"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header sortActionDescription="Sort by date">Date</th>
      <td mat-cell *matCellDef="let request" class="cell-width">
        {{ request.created | date: 'M/d/yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef scope="col">Title</th>
      <td mat-cell *matCellDef="let request" class="cell-width">
        {{ request.title }}
      </td>
    </ng-container>
    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef scope="col">Author</th>
      <td mat-cell *matCellDef="let request" class="cell-width">
        {{ request.author }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef scope="col">Status</th>
      <td mat-cell *matCellDef="let request" class="cell-width">
        <ng-container *ngTemplateOutlet="statusPill; context: { status: request.status }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef scope="col" class="header-cell-actions">Actions</th>
      <td mat-cell *matCellDef="let request" class="cell-actions cell-width" (click)="$event.stopPropagation()">
        <div class="action-buttons">
          <button
            mat-icon-button
            aria-label="Open item request details"
            matTooltip="Open item request details"
            matTooltipPosition="above"
            matBadge="!"
            matBadgeColor="warn"
            [ngClass]="{'hide-badge': !request.hasUnreadStaffComments}"
            (click)="goToRequest(request)">
            <mat-icon class="material-icons-outlined">chat</mat-icon>
          </button>
          <button
            mat-icon-button
            aria-label="Cancel request"
            matTooltip="Cancel request"
            matTooltipPosition="above"
            (click)="deleteItemRequest(request)">
            <mat-icon class="material-icons-outlined">close</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="mx-bold-underline"></tr>
    <tr mat-row *matRowDef="let request; columns: columnsToDisplay" class="mx-taller-row pointer" (click)="goToRequest(request)"></tr>
  </table>
</ng-container>

<ng-container *ngIf="closed$ | async as closed">
  <div class="flex-row-wrap">
    <div class="mat-headline-4 item-requests-title">Closed Requests ({{ closed.length }})</div>
  </div>
  <table
    mat-table
    aria-label="Closed Item Requests"
    #cSort="matSort"
    matSort
    matSortActive="created"
    matSortDirection="asc"
    [dataSource]="closedDataSource"
    (matSortChange)="handleSortChange($event)">
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let request">
        <ng-container *ngTemplateOutlet="mobileTableEntry; context:{request: request}"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header sortActionDescription="Sort by date">Date</th>
      <td mat-cell *matCellDef="let request" class="cell-width">
        {{ request.created | date: 'M/d/yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef scope="col">Title</th>
      <td mat-cell *matCellDef="let request" class="cell-width" [ngClass]="{'text-bold': request.hasLinkedCatalogRecord}">
        {{ request.hasLinkedCatalogRecord ? request.linkedCatalogRecord.title : request.title }}
      </td>
    </ng-container>
    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef scope="col">Author</th>
      <td mat-cell *matCellDef="let request" class="cell-width">
        {{ request.hasLinkedCatalogRecord ? request.linkedCatalogRecord.author : request.author }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef scope="col">Outcome</th>
      <td mat-cell *matCellDef="let request" class="cell-width">
        <ng-container *ngTemplateOutlet="statusPill; context: { status: request.status }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef scope="col" class="header-cell-actions">Actions</th>
      <td mat-cell *matCellDef="let request" class="cell-actions cell-width" (click)="$event.stopPropagation()">
        <div class="action-buttons">
          <!-- note: more backend work required before we can link directly to the hold information -->
          <button
            *ngIf="request.hasLinkedCatalogRecord"
            mat-icon-button
            aria-label="A hold was placed"
            matTooltip="A hold was placed"
            matTooltipPosition="above"
            routerLink="/catalog-item/{{request.linkedCatalogRecord.id}}"
          >
            <mat-icon class="material-icons-outlined">book</mat-icon>
          </button>
          <button
            mat-icon-button
            aria-label="Open item request details"
            matTooltip="Open item request details"
            matTooltipPosition="above"
            matBadge="!"
            matBadgeColor="warn"
            [ngClass]="{'hide-badge': !request.hasUnreadStaffComments}"
            (click)="goToRequest(request)">
            <mat-icon class="material-icons-outlined">chat</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="mx-bold-underline"></tr>
    <tr mat-row *matRowDef="let request; columns: columnsToDisplay" class="mx-taller-row pointer" (click)="goToRequest(request)"></tr>
  </table>
</ng-container>

<div class="clear-for-footer"></div>
<div class="save-footer" style="background-color: white">
  <div class="save-footer-content flex-row flex-center-end">
    <div class="spacer"></div>
    <div class="button-row-right">
      <button mat-raised-button
              color="primary"
              class="create-button"
              [disabled]="false"
              (click)="openItemRequestModal()">
        Create New Request
      </button>
    </div>
  </div>
</div>

<ng-template #mobileTableEntry let-request="request">
  <div class="mobile-table-entry flex-col">
    <a class="mobile-title-link">{{ request.title }}</a>
    <span>Author: {{ request.author }}</span>
    <span>
      Request Date:
      {{ request.created }}
    </span>
    <div class="mobile-status">
      <ng-container *ngTemplateOutlet="statusPill; context: { status: request.status }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #statusPill let-status="status">
  <mat-chip-set>
    <div class="status-pending" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.PENDING">Pending</div>
    <div class="status-requested" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.REQUESTED">Requested</div>
    <div class="status-approved" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.APPROVED">Approved</div>
    <div class="status-declined" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.CANCELED">Canceled</div>
    <div class="status-declined" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.DECLINED">Declined</div>
  </mat-chip-set>
</ng-template>

import {Component} from '@angular/core';

@Component({
  selector: 'rn-terms',
  template: `
    <div class="term-container mat-small">
      <h2 class="term-title">Terms and Conditions</h2>
      <br/>
      <p>PATRON CARD POLICY</p>

      <p>All residents or property/business owners of Lincoln Charter Township, Baroda Township, Royalton Township, the villages of Baroda and Stevensville are
        eligible for a patron borrowing card providing the following conditions are adhered to:</p>

      <p>Proof of current address must be supplied before a library card is issued.</p>

      <p>a. This may be from a valid government issued photo id such as: driver's license, passport, state id, military id or any other government issued id. If
        a
        resident’s address does not match their id they must show proof of residency via rental lease, mortgage papers, utility bill, automobile insurance, real
        estate tax bill, or credit card statement (within 60 days), or current bank statement (within 60 days). P.O. Boxes are not accepted.</p>

      <p>Individuals from birth through 17 years old will be issued a juvenile card with their own library card number provided the application is signed by a
        parent or guardian. The Library Privacy Act, PA 455 of 1982(MCL 397.605) guarantees the privacy of library records.</p>
      <ul>
        <li>
          a. The Library Privacy Act Amendment of 1996, PA 188, states that a record of items may be released to the parent or guardian of a minor in an effort
          to secure the return of the items.
        </li>
        <li>
          b. In the case of minor patrons, bills or delinquent notices may be sent to the parent or legal guardian only if that parent or legal guardian accepts
          responsibility for the returned items. By completing and signing the form on the back of the minor’s library card application, the record of items may
          be released to the parent or legal guardian. The parent and/or legal guardian is the sole person responsible for all material and fines incurred by
          the
          child.
        </li>
        <li>
          c. Restricting access of library materials to minor’s rests solely with the individual parent and/or legal guardian, not the library.
        </li>
      </ul>

      <p>Adult cards will be issued to those 18 and older upon presentation of picture ID and proof of date of birth. A minor who has a library card established
        will have their privileges updated to an adult card when their card is renewed after their 18th birthday.</p>

      <p>Parents may check out books for their children on the parent's library card.</p>

      <p>Resident cards are to be renewed on a three-year basis.</p>

      <p>A fee of $2.00 will be charged for replacing a lost patron card.</p>
    </div>
  `,
  styles: [`
      .term-container {
          overflow: auto;
          max-width: 60vh;
          max-height: 80vh;
          margin: 1.5rem;
      }

      .term-title {
          margin: 0;
      }
  `]
})
export class TermsComponent {
}

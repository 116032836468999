import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {map, of, switchMap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {OrganizationActions} from './organization.actions';
import {OrganizationService} from './organization.service';

@Injectable({providedIn: 'root'})
export class OrganizationEffects implements OnInitEffects {

  private readonly actions: Actions = inject(Actions);
  private readonly service: OrganizationService = inject(OrganizationService);

  ngrxOnInitEffects() {
    return OrganizationActions.initializeApp();
  }

  loadOrganization = createEffect(() =>
    this.actions.pipe(
      ofType(OrganizationActions.initializeApp),
      switchMap(() => this.service.loadAuthOrganization().pipe(
        map(response => OrganizationActions.loadAuthOrganizationSuccess(response)),
        catchError((error: unknown) => of(OrganizationActions.loadAuthOrganizationFailure(error)))
      ))
    )
  );
}

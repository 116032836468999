import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OrganizationSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-contact-us-dialog',
  template: `
    <mat-dialog-content>
      <h2 mat-dialog-title>Contact Us</h2>
      Call a librarian at {{ organizationSelectors.phoneNumber | select }}
      <br/>
      <br/>
      Or, email us at <a href="mailto:{{organizationSelectors.contactEmail | select}}">{{ organizationSelectors.contactEmail | select }}</a>
      <mat-card-actions class="flex-row flex-start-center">
        <button mat-dialog-close="true" mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button">
          Close
        </button>
      </mat-card-actions>
    </mat-dialog-content>
  `,
  styles: [`
      mat-card-actions {
          margin-top: 60px;
      }
  `],
})
export class ContactUsDialogComponent {
  public readonly organizationSelectors = OrganizationSelectors.Auth;

  constructor(
    public dialogRef: MatDialogRef<ContactUsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirmButton: string;
      cancelButton: string;
    }
  ) {
  }
}

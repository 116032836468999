<div class="flex-row flex-start-start">
  <div class="flex-1">
    <form [formGroup]="verifyForm" novalidate class="registration-form">
      <div class="mat-headline-3 registration-title">Verify Email</div>
      <h4 class="registration-description">
        An email was sent to:<br/>
        {{ patronSelectors.email | select }}<br/><br/>
        Please enter the six-digit verification code below:</h4>
      <rv-input-form-field fieldName="verificationCode" [externalLabel]="true" overrideLabel="Verification Code"
                           [formGroup]="verifyForm"/>
      <button mat-raised-button color="primary" class="next-button" (click)="onClickNext()" [disabled]="verifyForm.invalid">Submit</button>
      <div class="option-link">Didn't receive an email? <a class="pointer" (click)="onClickResend()">Resend email</a></div>
    </form>
  </div>
  <div class="verify-email-img flex-1"></div>
</div>

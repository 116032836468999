import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {NotificationService, PatronAccountStatus, PatronLedgerService, RoutesService} from '@raven';
import {tap} from 'rxjs/operators';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'rn-account-status-warning',
  templateUrl: './account-status-warning.component.html',
  styleUrls: ['./account-status-warning.component.scss'],
})
export class AccountStatusWarningComponent implements OnInit {
  @Input() context: 'dropdown' | 'notification' | 'banner' | 'placeHold';

  accountStatus$: Observable<PatronAccountStatus>;
  notificationOpened: boolean;
  statusTitle?: string;
  statusMessage?: string = null;
  statusActionLabel?: string = null;
  statusActionLink?: () => unknown = null;

  constructor(
    private patronLedgerService: PatronLedgerService,
    public routingService: RoutesService,
    private notificationService: NotificationService,
    private currencyPipe: CurrencyPipe,
  ) {
  }


  ngOnInit() {
    this.accountStatus$ = this.patronLedgerService.getAccountStatus()
      .pipe(
        tap((status: PatronAccountStatus) => {
          this.setStatus(status);
          this.openNotification();
        })
      );
  }

  setStatus(act: PatronAccountStatus): void {
    this.statusTitle = 'Account Frozen';
    if (act.minimumBalanceDue > 0) {
      const formattedBalance = this.currencyPipe.transform(act.maximumAccountBalance);
      this.statusMessage = (this.context === 'placeHold')
        ? `There are charges on your account that exceed ${formattedBalance}. In order to place a hold on any items you need to pay down the charges.`
        : `Your account balance is over ${formattedBalance} and your lending privileges have been suspended. Please make a payment to restore your account.`;
      this.statusActionLabel = 'Make a Payment.';
      this.statusActionLink = this.routingService.goToPatronBilling.bind(this.routingService);
    } else if (act.overdueLimit) {
      this.statusMessage = 'The number of overdue items on your account exceeds the library\'s limit and your lending privileges have been suspended. Please return or renew your items to restore your account.';
      this.statusActionLabel = 'Renew Items.';
      this.statusActionLink = this.routingService.goToCheckouts.bind(this.routingService);
    } else if (act.checkoutLimit) {
      this.statusMessage = 'You have checked out the maximum number of items and your lending privileges have been paused until some items have been returned.';
    } else if (act.damagedOrLostLimit) {
      this.statusMessage = 'The number of damaged or lost items exceeds the library\'s threshold and your lending privileges have been suspended. Please contact the library for assistance.';
    } else if (act.blocks.length > 0) {
      this.statusMessage = 'Your account has been frozen, please contact the library for assistance.';
    } else {
      this.statusMessage = undefined;
      this.statusTitle = undefined;
    }
  }

  openNotification(): void {
    if (this.context === 'notification' && this.statusMessage && !this.notificationOpened) {
      const snackBarRef = this.notificationService.showSnackbarError(this.statusMessage, this.statusActionLabel, 0);
      snackBarRef.onAction().subscribe(() => {
        this.statusActionLink();
        snackBarRef.dismiss();
      });
      this.notificationOpened = true;
    }
  }
}

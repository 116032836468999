import {isRouterTabDatum, NamedRouteChildNames, NamedRoutePaths, NamedRouteTabData, NamedRouteTabIndices, RouterTabData} from '@store/router/router.types';
import {Routes} from '@angular/router';
import {isDefined} from '@store/common/typing.helpers';

export function extractRoutePathsByNameFrom(routesArray: Routes, parentPath = ''): NamedRoutePaths {
  return routesArray.reduce(
    (accumulator, route) => {
      const name: string | undefined = route.data?.name;
      const path: string = route.path ? parentPath + '/' + route.path : parentPath;

      return {
        ...accumulator,
        ...name && {[name]: path},
        ...route.children && extractRoutePathsByNameFrom(route.children, path)
      };
    },
    {} as NamedRoutePaths
  );
}

export function extractRouteChildNamesByNameFrom(routesArray: Routes): NamedRouteChildNames {
  return routesArray.reduce(
    (accumulator, route) => {
      const name: string | undefined = route.data?.name;
      const children: Routes = route.children ?? [];
      const childNames: string[] = children.map(child => child.data?.name).filter(isDefined);

      return {
        ...accumulator,
        ...name && {[name]: childNames},
        ...children.length > 0 && extractRouteChildNamesByNameFrom(children)
      };
    },
    {} as NamedRouteChildNames
  );
}

export function extractTabDataByNameFrom(routesArray: Routes): NamedRouteTabData {
  return routesArray.reduce(
    (accumulator, route) => {
      const name: string | undefined = route.data?.name;
      const children: Routes = route.children ?? [];
      const tabData: RouterTabData = extractTabDataFrom(children, name);

      return {
        ...accumulator,
        ...name && {[name]: tabData},
        ...children.length > 0 && extractTabDataByNameFrom(children)
      };
    },
    {} as NamedRouteTabData
  );
}

function extractTabDataFrom(routesArray: Routes, parentRouteName?: string): RouterTabData {
  return routesArray
    .map((child, index) => ({
      index,
      routeName: child.data?.name,
      parentRouteName,
      label: child.data?.tabLabel
    }))
    .filter(isRouterTabDatum);
}

export function extractTabIndicesByNameFrom(namedRouterTabData: NamedRouteTabData): NamedRouteTabIndices {
  return Object.values(namedRouterTabData).reduce(
    (accumulator, tabData) => ({
      ...accumulator,
      ...tabData.length > 0 && extractTabIndicesFrom(tabData)
    }),
    {}
  );
}

function extractTabIndicesFrom(tabData: RouterTabData): NamedRouteTabIndices {
  return tabData.reduce(
    (accumulator, tabDatum) => ({
      ...accumulator,
      [tabDatum.routeName]: tabDatum.index
    }),
    {}
  );
}
import {Component, Input} from '@angular/core';
import {PatronAccountStatus} from '@raven';

@Component({
  selector: 'rn-billing-account-status-content',
  template: `
    <div class="flex-row">
      <h3>Account Status</h3>
    </div>
    <div class="status-detail flex-row">
      <ng-container *ngIf="balanceOverMax(account); else balanceOk">
        <mat-icon class="red-close">close</mat-icon>
        <span>Balance greater than {{ account.maximumAccountBalance | currency }}</span>
      </ng-container>
      <ng-template #balanceOk>
        <mat-icon class="green-check">done</mat-icon>
        <span>Balance less than {{ account.maximumAccountBalance | currency }}</span>
      </ng-template>
    </div>
    <div class="status-detail flex-row">
      <mat-icon class="green-check">done</mat-icon>
      <span>No lost or missing items</span>
    </div>
    <div class="status-detail flex-row">
      <ng-container *ngIf="cardExpired(account); else cardActive">
        <mat-icon class="red-close">close</mat-icon>
        <span>Card expired {{ account.accountExpiration | date:'shortDate' }}</span>
      </ng-container>
      <ng-template #cardActive>
        <mat-icon class="green-check">done</mat-icon>
        <span>Card valid through {{ account.accountExpiration | date:'shortDate' }}</span>
      </ng-template>
    </div>
  `,
  styles: [`
      .red-close {
          color: var(--error);
          margin: -4px 5px 0 0;
      }

      .green-check {
          color: var(--success);
          margin: -4px 5px 0 0;
      }

      .status-detail {
          margin-top: 8px;
      }
  `],
})
export class AccountStatusContentComponent {

  @Input() account: PatronAccountStatus;

  balanceOverMax(account: PatronAccountStatus): boolean {
    return account.currentAccountBalance > account.maximumAccountBalance;
  }

  cardExpired(account: PatronAccountStatus): boolean {
    return new Date(account.accountExpiration).getTime() < Date.now();
  }

}

import {DateOnlyIsoString} from '@store/common/common.types';
import {isString} from '@store/common/typing.helpers';
import {truncateTo} from '@store/transformation.helpers';

const ABBREVIATED_LAST_NAME_LENGTH: number = 4;
const ABBREVIATED_FIRST_NAME_LENGTH: number = 4;
const ABBREVIATED_MIDDLE_NAME_LENGTH: number = 1;

export function firstAndLastNameFrom(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`.trim();
}

export function firstInitialAndLastNameFrom(firstName: string, lastName: string): string {
  const firstInitial: string = firstName.length > 0 ? `${firstName.charAt(0)}.` : '';

  return `${firstInitial} ${lastName}`.trim();
}

/** `'Firstname Middle Lastname'` &rarr; `'Last, Firs M'` */
export function abbreviatedNameFrom(firstName: string, middleName: string, lastName: string): string {
  return (
    `${truncateTo(ABBREVIATED_LAST_NAME_LENGTH, lastName, '')},`
    + ` ${truncateTo(ABBREVIATED_FIRST_NAME_LENGTH, firstName, '')}`
    + ` ${truncateTo(ABBREVIATED_MIDDLE_NAME_LENGTH, middleName, '')}`
  ).trim();
}

export function firstAndLastNameWithPronounsFrom(firstName: string, lastName: string, pronouns?: string): string {
  const parenthesizedPronounsOrNothing: string = pronouns ? `(${pronouns})` : '';

  return `${firstName} ${lastName} ${parenthesizedPronounsOrNothing}`.trim();
}

export function initialsFrom(firstName: string, lastName: string): string {
  return `${(firstName ?? '').charAt(0)}.${(lastName.charAt(0) ?? '')}.`;
}

export function yearsSince(date: DateOnlyIsoString) {
  return (date === '') ? 0 : getDiffYears(Date.now(), new Date(date));
}

export function getDiffYears(startDate, endDate) {
  return Math.floor(Math.abs(startDate - endDate) / (1000 * 60 * 60 * 24 * 365));
}

export function notificationPreferencesTextFrom(notifyByEmail: boolean, notifyByText: boolean, notifyByPhone: boolean): string {
  const activePreferences: string[] = [
    ...notifyByEmail ? ['Email'] : [],
    ...notifyByText ? ['Text'] : [],
    ...notifyByPhone ? ['Phone'] : []
  ];

  return activePreferences.length === 0 ? 'None' : activePreferences.join(', ');
}

export function getAdditionalFieldIdentifier(fieldName: string): string {
  if (!isString(fieldName)) return '';
  return 'additional_' + fieldName.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
}

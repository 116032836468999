import {Component, inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {PatronActions} from '@store/patron/patron.actions';
import {FALLBACK_REGISTRATION_VALUE} from '@store/patron/patron.fallback';
import {BranchSelectors} from '@store/store.selectors';
import {STATE_SELECT} from '@store/common/common.types';
import {DateValidators} from '../../shared/form-fields/date-form-field/date.form-validators';
import {CommonValidators} from '@store/common/common.validators';

@Component({
  selector: 'rn-registration-details-form',
  templateUrl: './registration-details-form.component.html',
  styleUrls: ['./registration-details-form.component.scss'],
})
export class RegistrationDetailsFormComponent {
  public readonly STATE_SELECT = STATE_SELECT;
  public readonly branchSelectors = BranchSelectors.List;
  public readonly detailsForm = inject(FormBuilder).group({
    firstName: ['', [Validators.required, Validators.maxLength(50)]],
    lastName: ['', [Validators.required, Validators.maxLength(50)]],
    dateOfBirth: ['', [Validators.required, DateValidators.pastDate]],
    address: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
    city: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
    state: [FALLBACK_REGISTRATION_VALUE.state, [Validators.required]],
    zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],
    branchId: [0, [CommonValidators.requiredIdSelect]],
  });
  public readonly store = inject(Store);

  public onClickNext() {
    this.store.dispatch(PatronActions.updateRegistration(this.detailsForm.value));
    this.store.dispatch(PatronActions.completeRegistrationStep('enterDetails'));
  }
}

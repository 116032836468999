import {Component, inject, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {CommonValidators} from '@store/common/common.validators';
import {PatronActions} from '@store/patron/patron.actions';
import {BranchSelectors, PatronSelectors} from '@store/store.selectors';
import {passwordConfirmValidator, TermsComponent} from '@raven';
import {MatDialog} from '@angular/material/dialog';
import {snapshot} from '@store/store.helpers';

@Component({
  selector: 'rn-registration-create-password-form',
  templateUrl: './registration-create-password-form.component.html',
  styleUrls: ['./registration-create-password-form.component.scss'],
})
export class RegistrationCreatePasswordFormComponent {
  @Input() forLookupContext = false;

  @Input() set branchId(branchId: string) {
    this.branchIdField.patchValue(branchId);
  }

  public readonly patronSelectors = PatronSelectors.Registration;
  public readonly branchSelectors = BranchSelectors.List;
  private readonly store = inject(Store);
  private readonly dialog = inject(MatDialog);
  public readonly createPasswordForm = inject(FormBuilder).group({
      password: ['', {validators: [Validators.required, ...CommonValidators.passwordValidators]}],
      confirmPassword: ['', {validators: [Validators.required]}],
      branchId: [snapshot(this.patronSelectors.branchId), [CommonValidators.requiredIdSelect]],
      acceptTerms: []
    },
    {
      validators: passwordConfirmValidator(
        'password',
        'confirmPassword'
      ),
    });
  public readonly branchIdField = this.createPasswordForm.get('branchId') as FormControl;
  public readonly acceptTermsField = this.createPasswordForm.get('acceptTerms') as FormControl;


  public onClickNext() {
    this.store.dispatch(PatronActions.updateRegistration(this.createPasswordForm.value));
    this.forLookupContext ?
      this.store.dispatch(PatronActions.registerExisting()) :
      this.store.dispatch(PatronActions.register());
  }

  termsAndConditions(event: MouseEvent): void {
    event.preventDefault();
    this.dialog.open(TermsComponent);
  }
}

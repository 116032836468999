import {Component, inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'rn-create-account-dialog',
  template: `
    <mat-dialog-content class="dialog-content">
      <h1>Do you have a library card yet?</h1>
      <h4 class="secondary-text">If you visited us in person, you may already have a library card number.</h4>
      <mat-divider class="divider"/>
      <div class="flex-row flex-center-end flex-gap-8">
        <button mat-raised-button class="dialog-button cancel-button" (click)="navRegister(false)">No</button>
        <button mat-raised-button color="primary" class="dialog-button confirm-button" (click)="navRegister(true)">Yes, I have a library card</button>
      </div>
    </mat-dialog-content>
  `,
  styles: [`
      .dialog-content {
          max-width: 500px;
      }

      .divider {
          margin: 20px 8px 24px;
      }

      .dialog-button {
          padding: 16px 40px;
          color: white;
          font-size: 14px;
          line-height: 1.5rem;
          letter-spacing: 0.0625rem;
          text-align: center;
      }

      .cancel-button {
          border: 1px solid var(--med-gray-2);
          color: var(--dk-gray-2);
          background-color: white;
      }

      .confirm-button {
          color: white;
          background-color: var(--active);
      }
  `]
})
export class CreateAccountDialogComponent {
  private readonly router: Router = inject(Router);
  private readonly dialogRef: MatDialogRef<CreateAccountDialogComponent> = inject(MatDialogRef);

  navRegister(hasCard: boolean): void {
    this.dialogRef.close();
    this.router.navigateByUrl(hasCard ? '/register-existing-card' : '/register');
  }
}

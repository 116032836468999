import {Environment} from '../app/model/environment';

export const environment: Environment = {
  name: 'simplicity-dev',
  app: 'raven',
  production: false,
  apiUrl: 'https://api.dev.simplicitylibrary.com/api',
  snackBarTimeout: 6000,
  schedulerLicenseKey: '0119883474-fcs-1591401600',
  firebase: {
    // millonex-dev
    apiKey: 'AIzaSyBjH-C3b7_Sv1rpRedw57bWzhdGS7zs8Ow',
    authDomain: 'simplicity-dev-n373019.firebaseapp.com',
    projectId: 'simplicity-dev-n373019',
    storageBucket: 'simplicity-dev-n373019.appspot.com',
    messagingSenderId: '265288267234',
    appId: '1:265288267234:web:bae6b7df65fa97cea7f5bb',
    measurementId: 'G-PXW621DMQ5',
  },
  squareWebSdkUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
};

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {ConnectionPositionPair} from '@angular/cdk/overlay';
import {SelectOptions} from '@store/common/common.types';

@Component({
  selector: 'rn-activity-filter',
  template: `
    <ng-container *ngIf="isMobile; else filterForm">
      <div class="flex-col">
        <div class="flex-row flex-center-end" (click)="collapsed = !collapsed;" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
          <mat-icon color="primary">filter_list</mat-icon>
          <a>Filter</a>
        </div>
        <ng-template cdkConnectedOverlay
                     [cdkConnectedOverlayOrigin]="trigger"
                     [cdkConnectedOverlayOpen]="!collapsed"
                     [cdkConnectedOverlayPositions]="positions"
                     [cdkConnectedOverlayHasBackdrop]="true"
                     (backdropClick)="collapsed=true">
          <div class="filter-overlay-body">
            <ng-container *ngTemplateOutlet="filterForm"></ng-container>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #filterForm>
      <form [formGroup]="activityFilterForm" class="activityFilters flex-row-col-responsive flex-gap-8">
        <rv-select-form-field fieldName="lastXDays" class="last-x-days-select" overrideLabel="" [externalLabel]="true"
                              [options]="lastXDaysOptions" [includeUndefinedOption]="false" [formGroup]="activityFilterForm"/>
        <rv-date-range-form-field fieldName="dateRange" overrideLabel="" [externalLabel]="true" [formGroup]="activityFilterForm"/>
        <rv-select-form-field *ngIf="showTransactionType" class="transaction-select" fieldName="transactionType" overrideLabel="" [externalLabel]="true"
                              [formGroup]="activityFilterForm" [options]="transactionTypesOptions" [includeUndefinedOption]="false"/>
      </form>
    </ng-template>
  `,
  styles: [`
      :host {
          display: inline-block;
      }

      .activityFilters {
          margin-left: auto;
      }

      .filter-overlay-body {
          background-color: white;
          padding: 8px 8px 0px 16px;
          border: 1px solid var(--dk-gray);
          border-radius: 8px;
      }

      rv-select-form-field.last-x-days-select {
          width: 180px;
      }

      rv-date-range-form-field {
          width: 270px;
      }

      rv-select-form-field.transaction-select {
          width: 250px;
      }
  `],
})
export class ActivityFilterComponent implements OnInit, OnDestroy {
  @Input() changes: Subject<any>;
  @Input() showTransactionType = true;
  public activityFilterForm: FormGroup;
  public readonly lastXDaysOptions: SelectOptions<number | 'ALL'> = [
    {value: 30, displayName: '30 days'},
    {value: 60, displayName: '60 days'},
    {value: 90, displayName: '90 days'},
    {value: 120, displayName: '120 days'},
    {value: 'ALL', displayName: 'All Activity'},
  ];
  public readonly transactionTypesOptions: SelectOptions<string> = [
    {value: 'PAYMENT', displayName: 'Payments'},
    {value: 'CHARGE', displayName: 'Charges'},
    {value: 'ALL', displayName: 'All Transaction Types'},
  ];
  isMobile = false;
  collapsed = false;
  positions = [
    new ConnectionPositionPair(
      {originX: 'end', originY: 'bottom'},
      {overlayX: 'end', overlayY: 'top'}),
  ];

  initialValues = {
    lastXDays: 'ALL',
    dateRange: '',
    transactionType: 'ALL',
  };

  destroy$ = new Subject<boolean>();

  constructor(private fb: FormBuilder,
              private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.activityFilterForm = this.fb.group({
      lastXDays: ['lastXDays', {validators: []}],
      dateRange: ['', {validators: []}],
      transactionType: ['transactionType', {validators: []}],
    });

    this.activityFilterForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.changes);

    this.activityFilterForm.setValue(this.initialValues);

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        this.isMobile = breakpointState.matches;
        this.collapsed = breakpointState.matches;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}

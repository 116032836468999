import {createActionGroup, emptyProps} from '@ngrx/store';
import {Organization, ORGANIZATION_FEATURE_KEY} from './organization.types';

export const OrganizationActions = createActionGroup({
  source: ORGANIZATION_FEATURE_KEY,
  events: {
    'Initialize App': emptyProps(),
    'Load Auth Organization Success': (organization: Organization) => ({organization}),
    'Load Auth Organization Failure': (error: unknown) => ({error})
  },
});

import {Component} from '@angular/core';

@Component({
  selector: 'rn-registration-age-validation',
  templateUrl: './registration-age-validation.component.html',
  styleUrls: ['./registration-age-validation.component.scss'],
})
export class RegistrationAgeValidationComponent {


}

import {Component, OnInit} from '@angular/core';
import {MxConstants} from '@raven';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrganizationSelectors} from '@store/store.selectors';
import {withLatestFromSelectors} from '@store/common/effects.helpers';

@Component({
  selector: 'rn-component',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      }),
      withLatestFromSelectors(OrganizationSelectors.Auth.organization)
    ).subscribe(([title, org]) => {
      const setTitle = title ? (title + ' - ' + org.name) : org.name;
      this.titleService.setTitle(setTitle);
    });
  }

  async ngOnInit(): Promise<void> {
    localStorage.removeItem(MxConstants.CATALOG_SAVED_FILTERS_KEY);
  }
}

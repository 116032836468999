import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {capitalize} from '@store/transformation.helpers';

@Component({
  // The Angular style guide would say that we should use attribute selectors on Components: https://angular.io/guide/styleguide#style-05-03
  // Material seems on board with the idea though https://angular.io/guide/accessibility#augmenting-native-elements
  // It seems the lesson is to use this pattern sparingly
  selector: 'mat-error[hk-field-error]',
  template: `
    <ng-container *ngIf="field.invalid && getErrorMessage() as message">
      <span>{{ message }}</span>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.Default
  // Async validator error messages fail to show with ChangeDetectionStrategy.OnPush
})
export class FieldErrorComponent {
  @Input() field: AbstractControl;
  @Input() fieldLabel: string;

  getErrorMessage(): string | undefined {
    const lowercaseLabel: string = this.fieldLabel.toLowerCase();
    const capitalizedLabel: string = capitalize(lowercaseLabel);

    if (this.field.hasError('required')) {
      return `${capitalizedLabel} is required.`;
    }

    if (this.field.hasError('maxlength')) {
      return `${capitalizedLabel} is too long. Please use no more than ${this.field.getError('maxlength').requiredLength} characters.`;
    }

    if (this.field.hasError('minlength')) {
      return `${capitalizedLabel} is too short. Please use at least ${this.field.getError('minlength').requiredLength} characters.`;
    }

    if (this.field.hasError('max')) {
      return `${capitalizedLabel} must be no more than ${this.field.getError('max').max}.`;
    }

    if (this.field.hasError('min')) {
      return `${capitalizedLabel} must be at least ${this.field.getError('min').min}.`;
    }

    if (this.field.hasError('requiresUppercase') || this.field.hasError('requiresLowercase')) {
      return `${capitalizedLabel} must contain at least one uppercase and one lowercase letter.`;
    }

    if (this.field.hasError('requiresDigit') || this.field.hasError('requiresSpecialChars')) {
      return `${capitalizedLabel} must contain at least one symbol and one number.`;
    }

    if (this.field.hasError('mustBePIN')) {
      return `${capitalizedLabel} is invalid. Please use a number between 4 to 10 digits.`;
    }

    if (this.field.hasError('mustBeVerificationCode')) {
      return `${capitalizedLabel} is invalid. Please use a 6-digit number`;
    }

    if (this.field.hasError('mismatchedPasswords')) {
      return 'Passwords do not match.';
    }

    if (this.field.hasError('mustBeTenDigitPhoneNumber')) {
      return `${capitalizedLabel} must be a 10-digit number.`;
    }

    if (this.field.hasError('mustBeValidPhoneNumber')) {
      return `${capitalizedLabel} is invalid. Please use a 10-digit number with a valid area code and exchange.`;
    }

    if (this.field.hasError('currency')) {
      return 'Please use a valid currency value.';
    }

    if (this.field.hasError('email')) {
      return 'Please use a valid email address.';
    }

    // this case has html formatting with a link, let the component deal with this special case
    if (this.field.hasError('patronEmailUnavailable')) {
      return undefined;
    }

    if (this.field.hasError('mustBePastDate')) {
      return `${capitalizedLabel} must be in the past.`;
    }

    if (this.field.hasError('cannotBePastDate')) {
      return `${capitalizedLabel} cannot be in the past.`;
    }

    if (this.field.hasError('mustBeFutureDate')) {
      return `${capitalizedLabel} must be in the future.`;
    }

    if (this.field.hasError('cannotBeBeforeOldDate')) {
      return `${capitalizedLabel} cannot be set earlier than the previous ${lowercaseLabel}.`;
    }

    if (this.field.hasError('cannotBeBeforeDate')) {
      return `${capitalizedLabel} cannot be before ${this.field.getError('cannotBeBeforeDate').dateDescription}.`;
    }

    if (this.field.hasError('cannotBeAfterDate')) {
      return `${capitalizedLabel} cannot be after ${this.field.getError('cannotBeAfterDate').dateDescription}.`;
    }

    if (this.field.hasError('mustBeValidRecordSet')) {
      return `Look up the name of an existing record set here.`;
    }

    if (this.field.hasError('mustBeInteger')) {
      return `${capitalizedLabel} must be an integer.`;
    }

    if (this.field.hasError('mustBePositiveInteger')) {
      return `${capitalizedLabel} must be an integer greater than zero.`;
    }

    if (this.field.hasError('mustBeNonNegativeInteger')) {
      return `${capitalizedLabel} must be an integer and cannot be negative.`;
    }

    if (this.field.hasError('queueIndexMustBeNumeric')) {
      return `${capitalizedLabel} must be a positive number.`;
    }

    if (this.field.hasError('barcodeNotUnique')) {
      return 'This barcode is unavailable. Please use a different barcode.';
    }

    return `Please enter a valid ${lowercaseLabel}.`;
  }
}

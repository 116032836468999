import {TrackByFunction} from '@angular/core';
import {SelectOption} from './common.types';

export type TrackByIndexFunction = TrackByFunction<never>;
export type ObjectContainingId = { id: number };

/**
 * The simplest kind of trackBy function -- simply tracks by an array element's index.  Use this for arrays of primitives or even arrays of objects that don't
 * have a unique identifier property.
 */
export const trackByIndex: TrackByIndexFunction = index => index;

/**
 * A trackBy function generator.  Given a generic object type T and a property with unique values in each instance of T, generates a trackBy for that property.
 */
export function trackByProperty<T>(propertyName: keyof T): TrackByFunction<T> {
  return (_index: number, item: T) => item[propertyName];
}

export type TrackBySelectOption = TrackByFunction<SelectOption<unknown>>;

/**
 * A trackBy function generator for SelectOption.
 */
export function trackBySelectOption(): TrackBySelectOption {
  return trackByProperty<SelectOption<unknown>>('value');
}

/**
 * A pre-generated special case of `trackByProperty()` for objects with an `id` property.  This is just a convenience function for a common use case.
 */
export const trackById: TrackByFunction<ObjectContainingId> = trackByProperty<ObjectContainingId>('id');

/**
 * THE TRUE LAST RESORT!  `trackByIndex` is *usually* the last resort, but in cases where we can't even use that (for example, an array whose indices we expect
 * to change due to element insertion and removal), we can use this one.  Pretty much equivalent to excluding "trackBy" altogether, but it satisfies the linter.
 */
export function trackByItem<T>(_index: number, item: T): T {
  return item;
}

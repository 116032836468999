import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {NotificationService, PatronAccountStatus, PatronLedgerService, RoutesService} from '@raven';
import {PatronService} from '../../services/patron.service';
import {BranchSelectors, OrganizationSelectors, PatronSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-my-card',
  templateUrl: './my-card.component.html',
  styles: [`
      .mycard-header {
          max-width: var(--content-width);
          margin: 32px auto 0;
      }

      .mycard-title {
          width: 325px;
          padding-right: 20px;
      }

      .mycard-section {
          max-width: var(--content-width);
          margin: 32px auto;
      }

      .mycard-front {
          position: relative;
          width: 325px;
          height: 174px;
          padding: 0;
          margin-bottom: 30px;
          background-image: url('/assets/card-background.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
      }

      .mycard-lib-name {
          position: absolute;
          top: 22px;
          left: 23px;
      }

      .mycard-lib-name > img {
          height: 18px;
      }

      .mycard-details {
          position: absolute;
          top: 58px;
          left: 23px;
          line-height: 18px;
      }

      .mycard-patron-name {
          position: absolute;
          left: 24px;
          bottom: 14px;
          font-size: 18px;
          font-weight: bold;
      }

      .mycard-back {
          position: relative;
          width: 325px;
          height: 174px;
          padding: 0;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
      }

      .mycard-barcode {
          width: 278px;
          height: 65px;
      }

      .mycard-blank-barcode {
          width: 258px;
          height: 50px;
          background-color: var(--pearl);
          margin-bottom: 10px;
      }

      .mycard-expiration {
          position: absolute;
          left: 28px;
          bottom: 18px;
      }

      .mycard-message {
          width: 325px;
          padding: 20px 20px 0 0;
      }
  `],
})
export class MyCardComponent implements OnInit {
  public readonly organizationSelectors = OrganizationSelectors.Auth;
  public readonly patronSelectors = PatronSelectors.Auth;
  public readonly branchSelectors = BranchSelectors.Auth;
  title = 'Patrons';

  patronAccountStatus: Observable<PatronAccountStatus>;
  protected readonly PatronLedgerService = PatronLedgerService;

  constructor(public patronService: PatronService,
              private patronLedgerService: PatronLedgerService,
              public notificationService: NotificationService,
              public routingService: RoutesService,
              public router: Router,
              public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.patronAccountStatus = this.patronLedgerService.getAccountStatus();
  }

  renewAccount(): void {
    this.patronService.renewAccount({
      successMessage: 'Your account was successfully renewed.',
      errorMessage: {
        409: 'Your account must be expired before attempting to renew.',
        424: () => {
          this.notificationService.showSnackbarError('Your account must be under the balance limit to be eligible for renewal.');
          this.router.navigate(['/profile'], {fragment: 'billing'});
        },
        default: 'Error renewing your account. Please contact library staff.',
      }
    });
  }

}

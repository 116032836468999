import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {camelCaseToTitleCase} from '@store/transformation.helpers';

@Component({
  selector: 'rv-checkbox-form-field',
  template: `
    <!-- Checkbox form fields are indented so they vertically align with the start of the text inside an input, as opposed to the left edge of the input. -->
    <mat-checkbox [formControl]="control" color="primary" class="ms-1.5">
      {{ this.overrideLabel ?? camelCaseToTitleCase(this.fieldName) }}
      <ng-content/>
    </mat-checkbox>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFormFieldComponent implements OnInit {
  @Input() public fieldName: string;
  @Input() public overrideLabel?: string;
  @Input() public formGroup: FormGroup;

  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;
  }

  protected readonly camelCaseToTitleCase = camelCaseToTitleCase;
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState,} from '@angular/cdk/layout';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDrawerMode, MatSidenav} from '@angular/material/sidenav';
import {FormBuilder, FormGroup} from '@angular/forms';
import {filter, Subject, Subscription, takeUntil} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ThemeService} from './theme.service';
import {RoutesService} from '@raven';
import {OrganizationSelectors, PatronSelectors} from '@store/store.selectors';
import {PatronService} from '../services/patron.service';

@Component({
  selector: 'rn-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  public readonly organizationSelectors = OrganizationSelectors.Auth;
  public readonly patronSelectors = PatronSelectors.Auth;
  options: FormGroup;
  opened: boolean;
  sideNavMode: MatDrawerMode = 'over';
  mobile = false;
  breakpointSubscription: Subscription;

  // the menu
  @ViewChild(MatSidenav, {static: true}) sidenav: MatSidenav;
  navbarVisible = true;
  footerVisible = true;

  destroy$ = new Subject();

  constructor(
    public patronService: PatronService,
    public breakpointObserver: BreakpointObserver,
    public fb: FormBuilder,
    public routingService: RoutesService,
    public router: Router,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public iconRegistry: MatIconRegistry,
    public themeService: ThemeService
  ) {
    this.options = fb.group({
      bottom: 0,
      fixed: false,
      top: 0,
    });
    //
    // // create the icon registry for faster isLoading
    // this.iconRegistry.addSvgIcon('mx_logo', sanitizer.bypassSecurityTrustResourceUrl('assets/mx.svg'));
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route.snapshot),
      startWith(this.route.snapshot), //required since navigationEnd doesn't fire for initial page
      map(route => {
        while (route.firstChild) { // traverse route tree to end
          route = route.firstChild;
        }
        return route;
      }),
      takeUntil(this.destroy$)
    )
      .subscribe((endRoute: ActivatedRouteSnapshot) => {
        //also option to take a false from any node in the route, not just final
        this.footerVisible = endRoute.data['footerVisible'] ?? true;
        this.navbarVisible = endRoute.data['navbarVisible'] ?? true;
      });

    this.breakpointSubscription = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobile = true;
        } else {
          this.opened = false;
          this.mobile = false;
        }
      });
  }

  blur() {
    const elm = document.activeElement;
    if (elm && elm instanceof HTMLElement) {
      elm.blur();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goToDashboard(hasPatron: boolean): Promise<boolean> {
    if (hasPatron) {
      return this.router.navigateByUrl('/dashboard');
    }
    return this.router.navigateByUrl('/');
  }

}

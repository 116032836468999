import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {combineLatest, Observable, shareReplay, startWith, Subject, takeUntil} from 'rxjs';
import {map} from 'rxjs/operators';
import {ContactUsDialogComponent} from './components/contact-us-dialog.component';
import {CHARGE_STATUS, PATRON_LEDGER_STATUS, PatronAccountStatus, PatronCharge, PatronLedger, PatronLedgerService, PatronPayment} from '@raven';

type PatronTransaction = PatronCharge | PatronPayment;

@Component({
  selector: 'rn-balance-and-payments',
  templateUrl: './balance-and-payments.html',
  styleUrls: ['./balance-and-payments.scss'],
})
export class BalanceAndPayments implements OnInit, OnDestroy {
  ledgers$: Observable<Array<PatronTransaction>>;
  filteredLedgers$: Observable<Array<PatronLedger>>;
  templateData$: Observable<object>;
  patronAccountStatus: Observable<PatronAccountStatus>;

  activityFilterChanges = new Subject<object>();
  sortActivity$ = new Subject<object>();
  destroy$ = new Subject<boolean>();
  isMobile = false;

  desktopColumns = [
    'date',
    'type',
    'description-item',
    // 'barcode',
    'person',
    'charges',
    'payments',
    'status',
  ];
  mobileColumns = [
    'mobile'
  ];
  displayColumns = this.desktopColumns;

  constructor(protected patronLedgerService: PatronLedgerService,
              private breakpointObserver: BreakpointObserver,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const filterChanges$ = this.activityFilterChanges.asObservable();

    this.ledgers$ = this.patronLedgerService.patronLedgers.pipe(
      map((t) => (t === null ? [] : [...t.payments, ...t.charges]))
    );
    this.patronAccountStatus = this.patronLedgerService.getAccountStatus();
    this.filteredLedgers$ = combineLatest([
      this.ledgers$,
      filterChanges$.pipe(startWith({})), // fire immediately, don't wait
      this.sortActivity$.pipe(
        startWith({active: 'created', direction: 'desc'})
      ),
    ])
      .pipe(map(this.sortLedgers.bind(this)))
      .pipe(map(this.applyActivityFilters.bind(this)));

    // Initialize all streams with null to ensure we fire immediately
    this.templateData$ = combineLatest([
      this.patronAccountStatus,
      this.ledgers$.pipe(map(this.getRecentCharges.bind(this))),
    ]).pipe(
      map(([account, recentCharges]) => {
        return {
          account: account,
          recentCharges: recentCharges,
        };
      }),
      shareReplay({refCount: false, bufferSize: 1}),
    );

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntil(this.destroy$))
      .subscribe((breakpointState: BreakpointState) => {
        if (breakpointState.matches) {
          this.isMobile = true;
          this.displayColumns = this.mobileColumns;
        } else {
          this.isMobile = false;
          this.displayColumns = this.desktopColumns;
        }
      });

    // TODO it's an open question whether we want to do this, so leaving it commented here for now
    // this.ledgers$ = this.markChargesAsPaid(this.ledgers$);
  }

  sortLedgers([ledgers, filters, sort]): Array<PatronLedger> {
    const compareFns = {
      created: (a, b) => {
        const aVal = new Date(a.created).getTime();
        const bVal = new Date(b.created).getTime();
        return sort?.direction === 'asc' ? aVal - bVal : bVal - aVal;
      },
      patronName: (a, b) => {
        const aVal = `${a.patronLastName}, ${a.patronFirstName}`;
        const bVal = `${b.patronLastName}, ${b.patronFirstName}`;
        return sort?.direction === 'asc'
          ? ('' + aVal).localeCompare(bVal)
          : -('' + aVal).localeCompare(bVal);
      },
    };

    const cmp = compareFns[sort?.active];
    const sorted = cmp && sort?.direction ? ledgers.slice().sort(cmp) : ledgers;
    return [sorted, filters, sort];
  }

  applyActivityFilters([ledgers, filters, _sort]): Array<PatronLedger> {
    if (Object.keys(filters).length == 0) {
      return ledgers;
    }
    return ledgers
      .filter(
        (l) =>
          filters.lastXDays === 'ALL' ||
          this.inLastXDays(new Date(l.created), filters.lastXDays)
      )
      .filter((l) =>
        this.dateInRange(new Date(l.created), filters.dateRange)
      )
      .filter((l) => this.filterTransactionType(l, filters.transactionType));
  }

  dateInRange(date: Date, range: string): boolean {
    if (!(range?.includes('..'))) {
      return true;
    }
    const rangeArr = range.split('..');
    const start = new Date(rangeArr[0]).valueOf()
    const end = new Date(rangeArr[1]).valueOf()
    const inMillis = date.valueOf();
    return inMillis > start && inMillis <= end;
  }

  filterTransactionType(
    ledger: PatronTransaction,
    transactionType: string
  ): boolean {
    if (transactionType === 'ALL') return true;
    if (transactionType === 'CHARGE' && this.isCharge(ledger)) return true;
    return transactionType === 'PAYMENT' && !this.isCharge(ledger);
  }

  getRecentCharges(ledgers: Array<PatronTransaction>): number {
    return ledgers
      .filter((l) => {
        return this.isCharge(l) && this.inLastXDays(new Date(l.created), 7);
      })
      .reduce((runningSum, ledger) => {
        return runningSum + ledger.amount;
      }, 0);
  }

  getTotalDue(ledgers: Array<PatronTransaction>): number {
    const charges = ledgers
      .filter((l) => this.isCharge(l) && l.status !== CHARGE_STATUS.WAIVED)
      .reduce((sum, l) => sum + l.amount, 0);
    const payments = ledgers
      .filter((l) => !this.isCharge(l))
      .reduce((sum, l) => sum + l.amount, 0);

    return charges - payments;
  }

  // apply all payments to the oldest charges first and move forward in time
  // charges that are covered by payments have their status updated to 'PAID'
  markChargesAsPaid(
    ledgers$: Observable<Array<PatronLedger>>
  ): Observable<Array<PatronLedger>> {
    return ledgers$.pipe(
      map((ledgers) => {
        let totalPayments = ledgers
          .filter((l) => l.type === 'PAYMENT')
          .filter((l) => l.status !== 'WAVED')
          .reduce((sum, l) => sum + l.amount, 0);
        const charges = ledgers
          .filter((l) => l.type === 'CHARGE')
          .filter((l) => l.status !== 'WAVED');

        charges.reverse().forEach((l) => {
          if (totalPayments > l.amount) {
            l.status = PATRON_LEDGER_STATUS.PAID;
            totalPayments -= l.amount;
          }
        });

        return ledgers;
      })
    );
  }

  inLastXDays(date: Date, days: number) {
    const millis = days * 60 * 60 * 24 * 1000; // seconds,minutes,hours,milliseconds
    const now = Date.now();
    return date.getTime() > now - millis;
  }

  getStatusDisplayValue(ledger: PatronTransaction): string {
    const status = this.isCharge(ledger) ? ledger.status : null;
    const statusMap = {
      PAID: 'Paid',
      WAIVED: 'Waived',
      OPEN: 'Open',
    };

    return !this.isCharge(ledger) ? 'Payment' : statusMap[status] || 'UNKNOWN';
  }

  getStatusColorClass(ledger: PatronTransaction): string {
    const status = this.isCharge(ledger) ? ledger.status : null;
    const colorMap = {
      PAID: 'blue-pill',
      WAIVED: 'green-pill',
      OPEN: 'red-pill',
    };

    return !this.isCharge(ledger)
      ? 'green-pill'
      : colorMap[status] || 'red-pill';
  }

  contactUs(): void {
    this.dialog.open(ContactUsDialogComponent, {
      autoFocus: false,
    });
  }

  sort($event): void {
    this.sortActivity$.next($event);
  }

  isCharge(transaction: PatronTransaction): transaction is PatronCharge {
    return 'chargeType' in transaction;
  }

  refreshLedgersAndStatus(): void {
    this.patronLedgerService.refreshLedgers();
    this.patronLedgerService.refreshStatus();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

import {Component, inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {PatronActions} from '@store/patron/patron.actions';
import {CommonValidators} from '@store/common/common.validators';

@Component({
  selector: 'rn-lookup-account-form',
  templateUrl: './registration-lookup-account-form.component.html',
  styleUrls: ['./registration-lookup-account-form.component.scss'],
})
export class RegistrationLookupAccountFormComponent {
  public readonly lookupForm = inject(FormBuilder).group({
    cardNumber: ['', [Validators.required]],
    PIN: ['', [Validators.required, CommonValidators.pattern(/^\d{4,10}$/, {mustBePIN: true})]],
  });
  public readonly store = inject(Store);

  public onClickNext() {
    this.store.dispatch(PatronActions.lookupAccount(this.lookupForm.value.cardNumber, this.lookupForm.value.PIN));
  }
}

import {MxSelector} from './store.types';
import {Observable, take} from 'rxjs';
import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {SelectOptions} from './common/common.types';
import {PickKeysByTypeFrom} from './utility-type.helpers';

/**
 * If you're calling this from a construction context, the store is automatically injected for you, so you can leave out the second argument.  For use in other
 * locations, you can inject the store yourself and pass it in.
 */
export function select<T>(selector: MxSelector<T>, store?: Store): Observable<T> {
  return (store ?? inject(Store)).select(selector);
}

/**
 * DON'T use this!!! ...unless you need to.  But check to make sure you REALLY can't do what you need to do without resorting to a snapshot.
 *
 * If you're calling this from a construction context, the store is automatically injected for you, so you can leave out the second argument.  For use in other
 * locations, you can inject the store yourself and pass it in.
 */
export function snapshot<T>(selector: MxSelector<T>, store?: Store): T {
  let value: T;

  select(selector, store).pipe(take(1)).subscribe(selected => value = selected);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- We know that the following @ts-ignore is safe.
  // @ts-ignore - We know that the value will be assigned before we get here because the above Store subscription is synchronous.
  return value;
}

/**
 * Given an array of some data object, returns an array of `{ displayName, value }` suitable for use with select form controls.
 *
 * @param objects The array of objects to transform.
 * @param displayNameKey The key from each object to use as the `displayName` value in the result.
 * @param valueKey The key from each object to use as the `value` value in the result.
 * @param displayNameOverrides OPTIONAL - An object containing names to display instead of what is specified by displayNameKey.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This extends a POJO.
export function convertToSelectOptions<ObjectType extends { [key: string]: any }, ValueType>(
  objects: ObjectType[],
  displayNameKey: PickKeysByTypeFrom<ObjectType, string>,
  valueKey: PickKeysByTypeFrom<ObjectType, ValueType>,
  displayNameOverrides?: Map<string, string>
): SelectOptions<ValueType> {
  return objects.map(object => {
    const displayName: string = object[displayNameKey];

    return {
      displayName: displayNameOverrides?.get(displayName) ?? displayName,
      value: object[valueKey]
    };
  });
}

<div class="card-body" *ngIf="!hide">
  <div *ngIf="front; else cardBack" class="card-front flex-col">
    <div class="card-top-row flex-row flex-center-space-between">
      <mat-chip-set>
        <mat-chip class="mat-standard-chip" *ngIf="isPrimary">Primary</mat-chip>
      </mat-chip-set>
      <mat-icon class="pointer" (click)="front = !front">more_horiz</mat-icon>
    </div>
    <div class="card-middle-row flex-row flex-center-space-between">
      <span>**** **** **** {{ details.last4 }}</span>
      <span>{{ details.expMonth }}/{{ details.expYear }}</span>
    </div>
    <div class="card-bottom-row flex-row flex-center-space-between">
      <span>{{ details.cardholderName }}</span>
      <span *ngIf="details.cardBrand == 'VISA'"><img alt="VISA" src="/assets/visa-dark@3x.png" style="height: 46px"/></span>
      <span *ngIf="details.cardBrand == 'MASTERCARD'"><img alt="MASTERCARD" src="/assets/master-card-dark@3x.png" style="height: 46px"/></span>
      <span *ngIf="details.cardBrand == 'AMERICAN_EXPRESS'"><img alt="AMERICAN EXPRESS" src="/assets/american-express-dark@3x.png" style="height: 46px"/></span>
      <span *ngIf="details.cardBrand == 'DISCOVER' || details.cardBrand == 'DISCOVER_DINERS'"><img alt="DISCOVER" src="/assets/discover-dark@3x.png"
                                                                                                   style="height: 46px"/></span>
      <span *ngIf="!hasBrandLogo()">
        {{ details.cardBrand | titlecase }} {{ details.cardType | titlecase }}</span>
    </div>
  </div>
</div>

<ng-template #cardBack>
  <div class="card-back flex-col flex-center-space-between">
    <div class="flex-row flex-center-end">
      <mat-icon class="pointer" (click)="front = !front">close</mat-icon>
    </div>
    <div class="flex-col flex-gap-12">
      <div class="card-back-row flex-row flex-center-start flex-gap-6">
        <ng-container *ngIf="!isPrimary else primary">
          <mat-icon class="pointer" (click)="setPrimary()">radio_button_unchecked</mat-icon>
          <span class="pointer" (click)="setPrimary()">Set as Primary</span>
        </ng-container>
        <ng-template #primary>
          <mat-icon color="primary">star</mat-icon>
          <span>Primary</span>
        </ng-template>
      </div>
      <div class="card-back-row flex-row flex-center-start flex-gap-6">
        <mat-icon class="pointer" (click)="delete()">delete</mat-icon>
        <span class="pointer" (click)="delete()">Remove Card</span>
      </div>
    </div>

  </div>
</ng-template>

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'rv-currency-form-field',
  templateUrl: './currency-form-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyFormFieldComponent implements OnInit {

  @Input() public fieldName: string;
  @Input() public overrideLabel: string;
  @Input() public externalLabel = false;
  @Input() public formGroup: FormGroup;
  @Input() public includeClearButton = true;
  @Input() public alignRight = false;
  @Input() public enabled = true;

  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;
    this.updateFormattingFor(this.control.value);
  }

  public onInputBlur(): void {
    this.updateFormattingFor(this.control.value);
  }

  private updateFormattingFor(value: string | number): void {
    if (!value) return;
    this.control.patchValue(toStringCurrency(value));
  }
}

export function toStringCurrency(value: string | number): string {
  if (!value) return '';
  const stringValue: string = '' + value;
  const numericValue: number = parseFloat(stringValue?.replace(/![\d.]/g, '') ?? '');
  if (isNaN(numericValue)) return '';
  return numericValue.toFixed(2);
}

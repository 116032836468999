import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MxSelector, MxSelectorCreator} from '@store/store.types';
import {Breadcrumbs, INITIAL_SERIALIZED_ROUTER_STATE, ROUTER_FEATURE_KEY, SerializedRouterState} from '@store/router/router.types';
import {RouterReducerState} from '@ngrx/router-store/src/reducer';
import {convertToParamMap, Data, ParamMap, Params} from '@angular/router';
import {CHILD_ROUTE_NAMES_FOR, ROUTER_TAB_INDEX_FOR} from '../../app.routes';
import {isDefined} from '@store/common/typing.helpers';
import {SelectOptions} from '@store/common/common.types';
import {kebabCaseToSentenceCase} from '@store/transformation.helpers';

export const entireState: MxSelector<RouterReducerState<SerializedRouterState>> = createFeatureSelector(ROUTER_FEATURE_KEY);

export const serializedState: MxSelector<SerializedRouterState> = createSelector(
  entireState,
  state => state?.state ?? INITIAL_SERIALIZED_ROUTER_STATE
);

export const url: MxSelector<string> = createSelector(
  serializedState,
  state => state.url
);

export const path: MxSelector<string> = createSelector(
  serializedState,
  state => state.path
);

export const breadcrumbs: MxSelector<Breadcrumbs> = createSelector(
  serializedState,
  state => state.breadcrumbs
);

export const params: MxSelector<Params> = createSelector(
  serializedState,
  state => state.params
);

export const paramsMap: MxSelector<ParamMap> = createSelector(
  params,
  _params => convertToParamMap(_params)
);

export const paramValue: MxSelectorCreator<[string], string | undefined> =
  paramName => createSelector(
    paramsMap,
    paramsMap => paramsMap.get(paramName) ?? undefined
  );

export const paramValueWithFallback: MxSelectorCreator<[string, string], string> =
  (paramName, fallbackValue) => createSelector(
    paramValue(paramName),
    value => value ?? fallbackValue
  );

export const paramValueAsNumber: MxSelectorCreator<[string], number | undefined> =
  paramName => createSelector(
    paramValue(paramName),
    value => {
      const number = Number(value);

      return Number.isNaN(number) ? undefined : number;
    }
  );

export const paramValueAsNumberWithFallback: MxSelectorCreator<[string, number], number> =
  (paramName, fallbackValue) => createSelector(
    paramValueAsNumber(paramName),
    value => value ?? fallbackValue
  );

export const queryParams: MxSelector<Params> = createSelector(
  serializedState,
  state => state.queryParams
);

export const queryParamNames: MxSelector<string[]> = createSelector(
  queryParams,
  _params => Object.keys(_params)
);

export const queryParamsMap: MxSelector<ParamMap> = createSelector(
  queryParams,
  _params => convertToParamMap(_params)
);

export const queryParamValue: MxSelectorCreator<[string], string | undefined> =
  paramName => createSelector(
    queryParamsMap,
    paramsMap => paramsMap.get(paramName) ?? undefined
  );

export const hasQueryParamValue: MxSelectorCreator<[string], boolean> =
  paramName => createSelector(
    queryParamValue(paramName),
    isDefined
  );

export const queryParamValueAsSelectOptions: MxSelectorCreator<[string, MxSelector<SelectOptions<string>>], SelectOptions<string>> =
  (paramName, selectOptionsSelector) => createSelector(
    queryParamValue(paramName),
    selectOptionsSelector,
    (value, selectOptions) => {
      if (!value) return [];

      const queryValues: string[] = value.split(',');

      // A little inefficient, but this keeps the select options in the same order as the selectOptionsSelector instead of the order of the query param values.
      return selectOptions.filter(option => queryValues.some(queryValue => option.value === decodeURIComponent(queryValue)));
    }
  );

export const queryParamValueAsDisplayValue: MxSelectorCreator<[string, MxSelector<SelectOptions<string>>], string> =
  (paramName, selectOptionsSelector) => createSelector(
    queryParamValueAsSelectOptions(paramName, selectOptionsSelector),
    selectOptions => {
      if (selectOptions.length === 0) return kebabCaseToSentenceCase(paramName);

      const firstDisplayName: string = selectOptions[0].displayName;
      const additionalCount: string =
        selectOptions.length > 1
          ? ` +${selectOptions.length - 1}`
          : '';

      return `${firstDisplayName}${additionalCount}`;
    }
  );

export const queryParamValueWithFallback: MxSelectorCreator<[string, string], string> =
  (paramName, fallbackValue) => createSelector(
    queryParamValue(paramName),
    value => value ?? fallbackValue
  );

export const queryParamValueAsNumber: MxSelectorCreator<[string], number | undefined> =
  paramName => createSelector(
    queryParamValue(paramName),
    value => {
      const number = Number(value);

      return Number.isNaN(number) ? undefined : number;
    }
  );

export const queryParamValueAsNumberWithFallback: MxSelectorCreator<[string, number], number> =
  (paramName, fallbackValue) => createSelector(
    queryParamValueAsNumber(paramName),
    value => value ?? fallbackValue
  );

export const data: MxSelector<Data> = createSelector(
  serializedState,
  state => state.data
);

export const routeName: MxSelector<string | undefined> = createSelector(
  data,
  _data => _data?.name ?? 'NO_ROUTE_NAME'
);

export const dataValue: MxSelectorCreator<[string], string | undefined> =
  key => createSelector(
    data,
    _data => _data[key]
  );

export const routeNameMatches: MxSelectorCreator<[string, ...string[]], boolean> =
  (...targetRouteNames) => createSelector(
    routeName,
    currentRouteName => {
      return !!currentRouteName && targetRouteNames.includes(currentRouteName);
    }
  );

export const routeNameMatchesAChildOf: MxSelectorCreator<[string, ...string[]], boolean> =
  (...targetRouteNames) => createSelector(
    routeName,
    currentRouteName => !!currentRouteName && targetRouteNames.some(targetRouteName => CHILD_ROUTE_NAMES_FOR[targetRouteName].includes(currentRouteName))
  );

export const routeNameMatchesIncludingChildren: MxSelectorCreator<[string, ...string[]], boolean> =
  (...targetRouteNames) => createSelector(
    routeNameMatches(...targetRouteNames),
    routeNameMatchesAChildOf(...targetRouteNames),
    (isTarget, isChildOfTarget) => isTarget || isChildOfTarget
  );

export const tabIndex: MxSelector<number | undefined> = createSelector(
  routeName,
  _routeName => _routeName ? ROUTER_TAB_INDEX_FOR[_routeName] : undefined
);

export const initialQueryParams: MxSelector<Params | undefined> = createSelector(
  data,
  ({initialQueryParams}) => initialQueryParams
);

<mat-stepper [disableRipple]="true" [selectedIndex]="patronSelectors.currentLookupAccountStep | select"
             class="stepper rn-stepper-locked" labelPosition="bottom">
  <ng-template matStepperIcon="edit">
    <mat-icon>done</mat-icon> <!--A check mark icon for previously completed steps, rather than the default "edit" pencil icon.-->
  </ng-template>

  <mat-step label="Lookup Account" [completed]="patronSelectors.isComplete('lookupAccount') | select" [editable]="false">
    <div class="registration-page">
      <rn-lookup-account-form/>
    </div>
  </mat-step>
  <mat-step label="Verify Email" [completed]="patronSelectors.isComplete('verifyEmail') | select" [editable]="false">
    <div class="registration-page">
      <ng-template #emailForm>
        <rn-registration-email-form [email]="patronSelectors.email | select"/>
      </ng-template>
      <rn-registration-verify-email-form *ngIf="patronSelectors.isComplete('enterEmail') | select else emailForm"/>
    </div>
  </mat-step>
  <mat-step label="Create Password" [completed]="patronSelectors.isComplete('createPassword') | select" [editable]="false">
    <div class="registration-page">
      <rn-registration-create-password-form *ngIf="!(patronSelectors.isComplete('createPassword') | select)" [forLookupContext]="true"/>
    </div>
  </mat-step>
</mat-stepper>
<rn-registration-complete *ngIf="patronSelectors.isComplete('createPassword') | select"/>

import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {MxSelector} from '@store/store.types';
import {alwaysFalse} from '@store/common/common.selectors';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'rv-input-form-field',
  templateUrl: './input-form-field.component.html',
  styles: [`
      input {
          flex-grow: 1;
          min-width: 0;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFormFieldComponent implements OnInit {
  @Input() public fieldName: string;
  @Input() public inputType = 'text';
  @Input() public overrideLabel?: string;
  @Input() public hintLabel = '';
  @Input() public errorLabel = '';
  @Input() public externalLabel = false;
  @Input() public placeholder = '';
  @Input() public formGroup: FormGroup;
  @Input() public includeSearchIcon = false;
  @Input() public includeDollarSign = false;
  @Input() public postfixText = '';
  @Input() public alignRight = false;
  @Input() public includeClearButton = true;
  @Input() public replaceClearButtonWithSpinner: MxSelector<boolean> = alwaysFalse;  // Ignored when `showClearButton` is `false`.
  @Input() public auxiliaryButtonIconClass = '';
  @Input() public enabled = true;
  @Input() public forceUppercase = false;
  @Input() public customError = false;
  @Input() public subscriptSizing: 'fixed' | 'dynamic' = 'dynamic';

  @Input()
  public get auxiliaryButtonIconName(): string {
    return this._auxiliaryButtonIconName;
  }

  public set auxiliaryButtonIconName(auxiliaryButtonIconName: string) {
    this._auxiliaryButtonIconName = auxiliaryButtonIconName;
    this.showAuxiliaryButton = !!auxiliaryButtonIconName;
  }

  @Output() public readonly keydownEnter: EventEmitter<never> = new EventEmitter<never>();
  @Output() public readonly auxiliaryButtonClick: EventEmitter<never> = new EventEmitter<never>();
  @Output() public readonly inputFocused: EventEmitter<never> = new EventEmitter<never>();
  @Output() public readonly inputBlurred: EventEmitter<never> = new EventEmitter<never>();

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.
  public showClearButton = false;
  public showAuxiliaryButton = false;
  public focused = false;

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;

    if (!this.enabled) this.control.disable();

    if (this.includeClearButton) {
      this.updateClearButtonFor(this.control.value);

      this.control.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => this.updateClearButtonFor(value));
    }
  }

  public onFocusInput(): void {
    this.focused = true;
    this.inputFocused.emit();
  }

  public onBlurInput(): void {
    this.focused = false;

    this.inputBlurred.emit();
    if (this.forceUppercase) this.control.patchValue(this.control.value.toUpperCase());
  }

  public onKeydownEnter(): void {
    this.keydownEnter.emit();
  }

  public onClickClearButton(): void {
    this.control.reset('');
  }

  public onClickAuxiliaryButton(): void {
    this.auxiliaryButtonClick.emit();
  }

  private _auxiliaryButtonIconName: string;
  private readonly destroyed: Subject<void> = new Subject();

  private updateClearButtonFor(value: unknown): void {
    this.showClearButton = String(value)?.length > 0;
  }
}

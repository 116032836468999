import {NgModule} from '@angular/core';
import {RegistrationEmailFormComponent} from './email-form/registration-email-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CreateAccountComponent} from './create-account.component';
import {LookupAccountComponent} from './lookup-account.component';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {RegistrationVerifyEmailFormComponent} from './verify-email-form/registration-verify-email-form.component';
import {RegistrationLookupAccountFormComponent} from './lookup-account-form/registration-lookup-account-form.component';
import {RegistrationNotificationsFormComponent} from './notifications-form/registration-notifications-form.component';
import {RegistrationDetailsFormComponent} from './details-form/registration-details-form.component';
import {RegistrationCreateAccountFormComponent} from './create-account-form/registration-create-account-form.component';
import {RegistrationCreatePasswordFormComponent} from './create-password/registration-create-password-form.component';
import {SelectPipeModule} from '../shared/pipes/select-pipe.module';
import {RegistrationCompleteComponent} from './registration-complete/registration-complete.component';
import {RvFormsModule} from '../shared/form-fields/rv-forms.module';
import {RegistrationAgeValidationComponent} from './registration-age-validation.component';
import {TermsComponent} from './terms.component';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    ReactiveFormsModule,
    RouterModule,
    RvFormsModule,
    SelectPipeModule,
  ],
  declarations: [
    CreateAccountComponent,
    LookupAccountComponent,
    RegistrationCreateAccountFormComponent,
    RegistrationLookupAccountFormComponent,
    RegistrationEmailFormComponent,
    RegistrationVerifyEmailFormComponent,
    RegistrationDetailsFormComponent,
    RegistrationNotificationsFormComponent,
    RegistrationCreatePasswordFormComponent,
    RegistrationCompleteComponent,
    RegistrationAgeValidationComponent,
    TermsComponent,
  ],
  providers: [],
})
export class RegistrationModule {
}

import {inject, Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {first, mergeMap} from 'rxjs';
import {Environment} from '@raven';
import {AuthService} from '@store/patron/auth.service';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  private readonly authService: AuthService = inject(AuthService);
  private readonly environment: Environment = inject(Environment);

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (req.url.startsWith(this.environment.apiUrl)) {
      return this.authService.getAsyncToken().pipe(
        first(),
        mergeMap(token => {
          const authReq = token ? req.clone({
            setHeaders: {Authorization: 'Bearer ' + token},
          }) : req;
          return next.handle(authReq);
        }),
      );
    }
    return next.handle(req);
  }
}

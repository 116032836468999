<ng-container *ngIf="(accountStatus$ | async) && statusMessage">
  <div class="full-warning-banner" *ngIf="context === 'banner'">
    <ng-container *ngTemplateOutlet="warnBanner"></ng-container>
  </div>

  <ng-container *ngIf="context === 'placeHold'">
    <ng-container *ngTemplateOutlet="warnBanner"></ng-container>
  </ng-container>

  <ng-template #warnBanner>
    <div class="warn-banner-body">
      <div class="warn-banner-title">{{statusTitle}}</div>
      <span>{{statusMessage}} </span>
      <a class="pointer" *ngIf="statusActionLink" (click)="statusActionLink()">{{statusActionLabel}}</a>
    </div>
  </ng-template>

  <div class="warn-dropdown-wrapper" *ngIf="context === 'dropdown'">
    <button mat-button [matMenuTriggerFor]="statusAlertMenu" class="warn-dropdown">
      <mat-icon color="warn">error</mat-icon>
    </button>
    <mat-menu #statusAlertMenu="matMenu" [overlapTrigger]="false" class="remove-max-width remove-padding">
      <div class="alert-wrapper">
        <div class="alert-menu-header flex-row flex-center-start">
          <span class="header-icon-container">
            <mat-icon>error_outline</mat-icon>
          </span>
          <h4 class="header-title">Alerts</h4>
        </div>
        <h4 class="title-row flex-row flex-center-start">
          <span class="message-icon-container">
            <mat-icon color="warn">error</mat-icon>
          </span>
          <span class="title">{{statusTitle}}</span>
        </h4>
        <div class="message-text">
          {{statusMessage}} <a class="pointer" *ngIf="statusActionLink" (click)="statusActionLink()">{{statusActionLabel}}</a>
        </div>
      </div>
    </mat-menu>
  </div>

</ng-container>

import {isString} from './common/typing.helpers';

export function omitPropertiesByKeyFrom<T extends object>(object: T, ...propertyKeysToOmit): T {
  return Object.entries(object).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      ...!propertyKeysToOmit.includes(key) && {[key]: value}
    }),
    {} as T
  );
}

export function omitPropertiesByValueFrom<T extends object>(object: T, ...valuesToOmit): T {
  return Object.entries(object).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      ...!valuesToOmit.includes(value) && {[key]: value}
    }),
    {} as T
  );
}

export function extractPropertiesByKeyFrom<T extends object, K extends keyof T>(object: T, ...propertyKeysToExtract: K[]): Pick<T, K> {
  return Object.entries(object).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      ...propertyKeysToExtract.includes(key as K) && {[key]: value}
    }),
    {} as Pick<T, K>
  );
}

export function capitalize(string: string): string {
  if (!isString(string)) return '';

  return string.replace(/^\w/, firstCharacter => firstCharacter.toUpperCase());
}

export function toTitleCase(string: string): string {
  if (!isString(string)) return '';
  const words = string.split(' ');
  return words.map((word) => {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }).join(' ');
}

export const lowerCamelCaseToUpperCamelCase = capitalize;

export function camelCaseToTitleCase(string: string): string {
  if (!isString(string)) return '';

  return capitalize(string.replace(/([A-Z])/g, ' $1'));
}

export function kebabCaseToSentenceCase(string: string): string {
  if (!isString(string)) return '';

  return string.replace(/-/g, ' ');
}

export function enumCaseToTitleCase(string: string): string {
  if (!isString(string)) return '';
  const words = string.split('_');

  return words.map((word) => {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }).join(' ');
}

export function enumCaseToSentenceCase(string: string): string {
  if (!isString(string)) return '';

  return string
    .split('_')
    .map((word, index) =>
      index === 0
        ? word[0].toUpperCase() + word.substring(1).toLowerCase()
        : word.toLowerCase()
    )
    .join(' ');
}

export function truncateTo(maximumCharacters: number, string: string, suffix: string = '...'): string {
  if (!isString(string)) return '';

  const trimmedString: string = string.trim();

  return trimmedString.length <= maximumCharacters ? trimmedString : `${trimmedString.slice(0, maximumCharacters)}${suffix}`;
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'rv-textarea-form-field',
  template: `
    <rv-base-form-field [fieldName]="fieldName" [overrideLabel]="overrideLabel" [formGroup]="formGroup" [control]="control"
                        [ngClass]="{ 'pointer-events-none': readonly }" [hintLabel]="hintLabel">
      <!-- The minimum height style is used when the user resizes the box -- at a minimum, the <textarea>'s height matches the height of a standard input. -->
      <textarea matInput [formControl]="control" [placeholder]="placeholder" rows="{{ initialRows }}" [readonly]="readonly"
                [attr.maxLength]="maxLength > 0 ? maxLength : null">
      </textarea>
    </rv-base-form-field>
  `,
  styles: [`
      textarea {
          width: 100%;
          min-height: 1.5rem;
          margin-block-end: 0.5rem;
      }

      textarea:has([readonly]) {
          resize: none;
          overflow: hidden;
      }

      .pointer-events-none {
          pointer-events: none;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaFormFieldComponent implements OnInit {
  @Input() public fieldName: string;
  @Input() public overrideLabel?: string;
  @Input() public placeholder = '';
  @Input() public initialRows = 4;
  @Input() public formGroup: FormGroup;
  @Input() public readonly = false;
  @Input() public hintLabel = '';
  @Input() public maxLength?: number;

  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;
  }
}

import {ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATED} from '@ngrx/router-store';
import {combineLatestWith, filter, map, OperatorFunction, pipe, withLatestFrom} from 'rxjs';
import {Action} from '@ngrx/store';
import {BranchSelectors, PatronSelectors, RouterSelectors} from '@store/store.selectors';
import {MxSelector} from '@store/store.types';
import {select} from '@store/store.helpers';

export function noAuthWhenRouterHasNavigatedTo<A extends Action>(targetRouteName: string, ...otherTargetRouteNames: string[]): OperatorFunction<A, A> {
  return pipe(
    ofType(ROUTER_NAVIGATED),
    skipUnless(RouterSelectors.routeNameMatches(targetRouteName, ...otherTargetRouteNames))
  );
}

export function whenRouterHasNavigatedTo<A extends Action>(targetRouteName: string, ...otherTargetRouteNames: string[]): OperatorFunction<A, A> {
  return pipe(
    ofType(ROUTER_NAVIGATED),
    skipUnless(RouterSelectors.routeNameMatches(targetRouteName, ...otherTargetRouteNames)),
    waitForAuthResult()
  );
}

export function whenRouterHasNavigatedToAChildOf<A extends Action>(targetRouteName: string, ...otherTargetRouteNames: string[]): OperatorFunction<A, A> {
  return pipe(
    ofType(ROUTER_NAVIGATED),
    skipUnless(RouterSelectors.routeNameMatchesAChildOf(targetRouteName, ...otherTargetRouteNames)),
    waitForAuthResult()
  );
}

export function whenRouterHasNavigatedToIncludingChildren<A extends Action>(targetRouteName: string, ...otherTargetRouteNames: string[]): OperatorFunction<A, A> {
  return pipe(
    ofType(ROUTER_NAVIGATED),
    skipUnless(RouterSelectors.routeNameMatchesIncludingChildren(targetRouteName, ...otherTargetRouteNames)),
    waitForAuthResult()
  );
}

export function waitForAuthResult<A extends Action>(): OperatorFunction<A, A> {
  return pipe(
    combineLatestWith(
      select(PatronSelectors.Auth.finishedLoading),
      select(BranchSelectors.Auth.finishedLoading)
    ),
    filter(([, userLoaded, branchLoaded]) => userLoaded && branchLoaded),
    revertToOriginalAction(),
  );
}

export function revertToOriginalAction<A extends Action>(): OperatorFunction<[A, ...unknown[]], A> {
  return map(([action]) => action);
}

export type MxSelectorInputTuple<T> = {
  [K in keyof T]: MxSelector<T[K]>;
};

/*
  It's weird to have exactly one override for a function, but this is based on withLatestFrom(), so it mirrors that interface...

  ...except without the version with the projector function (so far).  So this override arrangement makes the typing work nicely even before we completely
   implement that projector functionality (which we can do when it's needed).
*/
export function withLatestFromSelectors<T, O extends unknown[]>(...inputs: [...MxSelectorInputTuple<O>]): OperatorFunction<T, [T, ...O]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is the intended use of the "any" type.
export function withLatestFromSelectors<T, R>(...inputs: any[]): OperatorFunction<T, R | any[]> {
  return withLatestFrom(...inputs.map(input => select(input)));
}

export function skipIf<A extends Action>(selector: MxSelector<boolean>): OperatorFunction<A, A> {
  return pipe(
    withLatestFromSelectors(selector),
    filter(([, booleanValue]) => !booleanValue),
    revertToOriginalAction()
  );
}

export function skipUnless<A extends Action>(selector: MxSelector<boolean>): OperatorFunction<A, A> {
  return pipe(
    withLatestFromSelectors(selector),
    filter(([, booleanValue]) => booleanValue),
    revertToOriginalAction()
  );
}


import {Branch} from './branch.types';

export const FALLBACK_BRANCH: Branch = {
  id: 0,
  organizationId: 0,
  name: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  phone: '',
  url: '',
};

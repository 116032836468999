import { CatalogRecord } from '../catalog-record';
import {PatronItemRequestComment} from './patron-item-request-comment';

export class PatronItemRequest {
  id: number;
  organizationId: number;
  requestedBranchId: number;
  patronId: number;
  status?: PATRON_ITEM_REQUEST_STATUS;
  title: string;
  author: string;
  isbn?: string;
  hasUnreadStaffComments?: boolean;
  notes?: string;

  created?: string;
  modified?: string;
  publicationDate?: string;
  comments?: PatronItemRequestComment[];
  pickupBranchName: string;

  materialTypeDesc: string;
  active: boolean;
  linkedCatalogRecord?: CatalogRecord | null;
  hasLinkedCatalogRecord?: boolean;
}

export type PatronItemRequestKeys = keyof PatronItemRequest;

export enum PATRON_ITEM_REQUEST_STATUS {
  REQUESTED = 'REQUESTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED'
}

<mat-dialog-content class="submit-request-wrapper">
  <ng-container *ngTemplateOutlet="header"></ng-container>
  <div class="body">
    <ng-container *ngTemplateOutlet="importantNote"></ng-container>
    <ng-container *ngTemplateOutlet="requestFormTemplate"></ng-container>
  </div>
</mat-dialog-content>

<ng-template #header>
  <div class="header">
    <div class="dialog-close-row flex-row flex-center-space-between">
      <h3>Item Request</h3>
      <mat-icon style="margin-bottom: 16px;" class="pointer" (click)="cancel()">close</mat-icon>
    </div>
    <span>Name: {{ patronSelectors.firstName | select }} {{ patronSelectors.lastName | select }}</span>
  </div>
</ng-template>

<ng-template #importantNote>
  <div class="important-note flex-row">
    <div class="info-icon">
      <em class="ri-information-line" matTooltip="THIS IS IMPORTANT"></em>
    </div>
    <div class="disclaimer">
      Note: Patrons may submit up to 3 item requests per month.
    </div>
  </div>
</ng-template>

<ng-template #requestFormTemplate>
  <form [formGroup]="requestForm" class="request-form">
    <div class="flex-row-col-responsive flex-center-space-between">
      <div class="request-form-item request-form-title-row">
        <rv-input-form-field fieldName="itemTitle" [externalLabel]="true" overrideLabel="Item Title"
                             [formGroup]="requestForm" subscriptSizing="fixed"/>
      </div>
    </div>

    <div class="flex-row-col-responsive flex-center-space-between">
      <div class="request-form-item">
        <rv-input-form-field fieldName="author" [externalLabel]="true" overrideLabel="Author"
                             [formGroup]="requestForm" subscriptSizing="fixed"/>
      </div>
      <div class="request-form-item">
        <rv-input-form-field fieldName="isbn" [externalLabel]="true" overrideLabel="ISBN (not required)"
                             [formGroup]="requestForm" subscriptSizing="fixed"/>
      </div>
    </div>
    <div class="flex-row-col-responsive flex-center-space-between">
      <div class="request-form-item">
        <rv-input-form-field fieldName="materialTypeDesc" [externalLabel]="true" overrideLabel="Material Type"
                             [formGroup]="requestForm" subscriptSizing="fixed"/>
      </div>
      <div class="request-form-item">
        <rv-select-form-field fieldName="pickupLocation" [externalLabel]="true" overrideLabel="Location for Item Pickup"
                              [formGroup]="requestForm" subscriptSizing="fixed"
                              [options]="branchSelectors.branchesSelectOptions | select" [includeUndefinedOption]="false"/>
      </div>

    </div>
    Notes
    <rv-textarea-form-field fieldName="notes" [formGroup]="requestForm"
                            overrideLabel="Add any helpful notes about your request (i.e. Item Format, Publication Date, Edition, etc.)"/>

    <mat-checkbox formControlName="agreeToPolicies">
      I agree that I have searched the catalog and it is not in the library's system already
    </mat-checkbox>

    <div class="button-row flex-row flex-center-end">
      <button mat-stroked-button class="save-button-group button-cancel" (click)="cancel()" data-cy="on-hold-button">
        Cancel
      </button>
      <button mat-raised-button color="primary" class="save-button-group" mat-dialog-close="true"
              [disabled]="!requestForm.valid" (click)="submit()">
        <span>Submit</span>
      </button>
    </div>
  </form>
</ng-template>

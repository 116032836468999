import {BaseRouterStoreState} from '@ngrx/router-store';
import {Data, Params} from '@angular/router';
import {isNumber, isObject, isPopulatedString} from '@store/common/typing.helpers';

export const ROUTER_FEATURE_KEY = 'router';

export interface SerializedRouterState extends BaseRouterStoreState {
  url: string;
  path: string;
  breadcrumbs: Breadcrumbs;
  params: Params;
  queryParams: Params;
  data: Data;
}

export const INITIAL_SERIALIZED_ROUTER_STATE: SerializedRouterState = {
  url: '',
  path: '',
  breadcrumbs: [],
  params: {},
  queryParams: {},
  data: {}
}

export interface Breadcrumb {
  title: string;
  path: string;
  name: string;
  params: Params;
}

export type Breadcrumbs = Breadcrumb[];

export interface NamedRoutePaths {
  [routeName: string]: string;
}

export interface NamedRouteChildNames {
  [routeName: string]: string[];
}

export interface RouterTabDatum {
  index: number;
  parentRouteName: string;
  routeName: string;
  label: string;
}

export function isRouterTabDatum(thing: unknown): thing is RouterTabDatum {
  return isObject(thing)
    && isNumber(thing.index)
    && isPopulatedString(thing.parentRouteName)
    && isPopulatedString(thing.routeName)
    && isPopulatedString(thing.label);
}

export type RouterTabData = RouterTabDatum[];

export interface NamedRouteTabData {
  [routeName: string]: RouterTabData;
}

export interface NamedRouteTabIndices {
  [routeName: string]: number;
}

<div class="flex-row flex-start-start">
  <div class="flex-1">
    <form [formGroup]="notificationsForm" novalidate class="registration-form">
      <div class="mat-headline-3 registration-title">Notification Preferences</div>
      <h4 class="registration-description">Select how you would like to receive notifications. If you wish to change your notification email, the email you
        use to login will not change.</h4>
      <div class="flex-col flex-gap-20">
        <rv-input-form-field fieldName="notificationEmail" [externalLabel]="true" overrideLabel="Notification Email" [formGroup]="notificationsForm"/>
        <rv-phone-form-field fieldName="mobilePhone" [externalLabel]="true" overrideLabel="Mobile Phone Number" [formGroup]="notificationsForm"/>
        <div>
          <h4 class="form-label">I prefer to be contacted by:</h4>
          <rv-radio-group-form-field fieldName="notifyMethod" [options]="NOTIFICATION_OPTIONS" [formGroup]="notificationsForm"/>
        </div>
        <button mat-raised-button color="primary" class="next-button" (click)="onClickNext()" [disabled]="!notificationsForm.valid">Next</button>
      </div>
    </form>
  </div>
  <div class="set-notifications-img flex-1"></div>
</div>

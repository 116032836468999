<rn-account-status-warning context="notification"></rn-account-status-warning>
<div class="catalog-home">
  <div class="catalog-home-banner hide-xs">
    <div class="mat-headline-2 catalog-home-title">
      Explore our<br/>
      collection
    </div>
    <h1 class="catalog-home-subtitle">
      Search, browse and place items on<br/>
      hold with our library catalog.
    </h1>
  </div>
  <div class="catalog-mobile-header mat-headline-4 hide-gt-xs">
    Catalog
  </div>
  <div class="search-bar">
    <div class="search-center flex-row" [formGroup]="searchForm">
      <mat-select formControlName="searchType">
        <mat-option value="keyword" selected>Keyword</mat-option>
        <mat-option value="title">Title</mat-option>
        <mat-option value="author">Author</mat-option>
        <mat-option value="isbn">ISBN</mat-option>
      </mat-select>
      <input (keydown.enter)="doSearch()" formControlName="searchTerm" placeholder="Search term"/>
      <button (click)="doSearch()">
        <img alt="magnifying glass" src="/assets/magnifying-glass.svg"/>
      </button>
    </div>
  </div>
  <!--//NOSONAR  <div class="catalog-categories flex-row-wrap flex-center-center hide-xs">-->
  <!--//NOSONAR    <button mat-stroked-button class="ButtonSmallBlack" *ngFor="let category of CATALOG_CATAGORIES | keyvalue">-->
  <!--//NOSONAR      {{ category.value }}-->
  <!--//NOSONAR    </button>-->
  <!--//NOSONAR  </div>-->
  <!--//NOSONAR  <mat-expansion-panel hideToggle class="catalog-mobile-categories-panel">-->
  <!--//NOSONAR    <mat-expansion-panel-header class="catalog-mobile-categories-title hide-gt-xs">-->
  <!--//NOSONAR      <img alt="" src="/assets/browse.svg" class="browse-icon">-->
  <!--//NOSONAR      <span class="browse-title">Browse Sections</span>-->
  <!--//NOSONAR      <img alt="" src="/assets/chevron-bottom.svg" class="browse-chevron">-->
  <!--//NOSONAR    </mat-expansion-panel-header>-->
  <!--//NOSONAR    <div class="catalog-categories flex-row-wrap flex-center-space-around">-->
  <!--//NOSONAR      <button mat-stroked-button class="ButtonSmallBlack" *ngFor="let category of CATALOG_CATAGORIES | keyvalue">-->
  <!--//NOSONAR        {{ category.value }}-->
  <!--//NOSONAR      </button>-->
  <!--//NOSONAR    </div>-->
  <!--//NOSONAR  </mat-expansion-panel>-->

  <div *ngIf="!(loading$ | async); else loading_template">
    <div class="carousel-container">
      <ng-container *ngFor="let carousel of carouselDisplays; let isLast = last">
        <rn-book-carousel [catalogRecords]="carousel.catalogRecords" [title]="carousel.title"></rn-book-carousel>
        <hr *ngIf="!isLast" class="catalog-hr"/>
      </ng-container>
      <!--//NOSONAR      <rn-author-carousel [authors]="popularAuthors" title="Popular Authors"></rn-author-carousel>-->
      <!--//NOSONAR      <hr class="catalog-hr"/>-->
      <!--//NOSONAR      <rn-popular-subject-carousel [subjects]="popularSubjects" title="Popular Subjects"></rn-popular-subject-carousel>-->
    </div>
  </div>
  <rn-catalog-footer></rn-catalog-footer>
  <ng-template #loading_template>
    <div class="flex-container" style="position: relative">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </ng-template>
</div>

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {isDate, isUndefined} from '@store/common/typing.helpers';
import {DateOnlyIsoString} from '@store/common/common.types';
import {calculateMaxDateFromValidatorsFor, calculateMinDateFromValidatorsFor} from './date.form-validators';
import {toDateOnly, toDateOnlyIsoString, toLocalMidnight} from '@store/common/date.helpers';

@Component({
  selector: 'rv-date-form-field',
  template: `
    <rv-base-form-field [fieldName]="fieldName" [overrideLabel]="overrideLabel" [externalLabel]="externalLabel" [formGroup]="formGroup" [control]="control"
                        [enabled]="enabled" [subscriptSizing]="subscriptSizing">
      <div class="flex flex-row justify-between w-full">
        <!-- This <input> element is connected to the form control, but it's hidden in the UI.  It holds the actual form field value. -->
        <input matInput type="text" [formControl]="control" class="hidden"/>

        <input matInput type="text" [matDatepicker]="picker" class="min-w-0 grow" [min]="minDate" [max]="maxDate" [value]="dateValue"
               (dateChange)="onDateChange($event)"/>

        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon class="material-icons-outlined">calendar_today</mat-icon>
        </mat-datepicker-toggle>
      </div>
    </rv-base-form-field>

    <mat-datepicker #picker panelClass="hk-date-form-field-picker"/>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFormFieldComponent implements OnInit {

  @Input() public fieldName: string;
  @Input() public overrideLabel?: string;
  @Input() public externalLabel = false;
  @Input() public formGroup: FormGroup;
  @Input() public enabled = true;
  @Input() public subscriptSizing: 'fixed' | 'dynamic' = 'dynamic';

  /*
    NOTE:  minDate/maxDate are purposely not exposed as inputs.  When using matDatepicker [min] and [max] inputs, or even [matDatepickerFilter], we can't really
    express what we mean (such as specifically "a future day", as opposed to "minimum = a day that happens to be tomorrow"), and we get hidden validators that
    don't give us the context we need to show good validation error messages (like "Must be in the future" vs. the not-as-specific "Must be 01/01/2024 or later"
    when tomorrow is 01/01/2024).

    So, we should add our own validators to our DateValidators namespace (which can then be visible in the form field's definition like normal).  And the
    presence of appropriate validators will then cause this class's ngOnInit() to set minDate/maxDate to properly disable dates in the picker. Then we can
    write messages appropriate to our validators in FieldErrorComponent.  (Angular Material's built-in validation messages will still also be present in the
    errors object, but as long as we capture our own validation errors first, we can safely ignore the built-in ones.)
  */
  public minDate?: Date;
  public maxDate?: Date;

  public dateValue?: Date | string;  // String only at first because we initialize it that way.  Once the user interacts, it's always a Date.
  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.

  private initialized = false;  // Prevents event emissions when initializing the form control.

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;
    this.minDate = calculateMinDateFromValidatorsFor(this.control);
    this.maxDate = calculateMaxDateFromValidatorsFor(this.control);

    if (this.control.value) {
      this.syncValuesFrom(this.control.value);
    }

    this.initialized = true;
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.syncValuesFrom(event.value);
  }

  private syncValuesFrom(value: Date | string | null): void {
    this.dateValue = value ? toLocalMidnight(value) : undefined;
    this.updateFormFieldValue();
  }

  private updateFormFieldValue(): void {
    this.control.patchValue(this.newFormFieldValue, {emitEvent: this.initialized});
    this.control.markAsTouched();  // We have to do this manually since the display <input> is not the same as the form-connected <input>.
    this.control.markAsDirty();
  }

  private get newFormFieldValue(): DateOnlyIsoString {
    if (isUndefined(this.dateValue)) return '';
    if (isDate(this.dateValue)) return toDateOnlyIsoString(this.dateValue);

    return toDateOnly(this.dateValue);
  }
}

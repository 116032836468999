import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

/**
 * SAVE HISTORY DIALOG
 */
@Component({
  selector: 'mx-save-history-dialog',
  template: `
    <mat-dialog-content class="dialog-wrapper flex-col">
      <h1>Save Borrowing History</h1>
      <h4>
        If you choose to save your borrowing history, you will be able to view the title, author and checkout date for all items you have checked out from the
        library. You may turn your Borrowing History ON or OFF any time when you login to your account. If you choose to activate your Borrowing History, and
        later turn it off, all stored records will be deleted from the system.
      </h4>
      <h4>
        Choosing to save your borrowing history is voluntary and constitutes your consent to the storage of information about items you check out. This
        information is subject to the judicial process.
      </h4>
      <mat-divider></mat-divider>
      <div class="dialog-button-row">
        <button mat-stroked-button class="save-button-group button-cancel" (click)="onNoClick()">Go Back</button>
        <button mat-raised-button color="primary" class="save-button-group save-button" (click)="onSubmit()">
          Save Borrowing History
        </button>
      </div>
    </mat-dialog-content>
  `,
  styles: [`
      mat-dialog-content.dialog-wrapper {
          width: 31.25rem;
          padding-bottom: 1rem;
      }

      div.dialog-button-row {
          margin-top: 0.75rem;
      }

      button.save-button-group {
          height: 3.5rem;
      }
  `,
  ],
})
export class SaveHistoryDialogComponent {

  constructor(public dialogRef: MatDialogRef<SaveHistoryDialogComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}

import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {passwordConfirmValidator} from '@raven';
import {PatronActions} from '@store/patron/patron.actions';
import {CommonValidators} from '@store/common/common.validators';

/**
 * PASSWORD UPDATE DIALOG COMPONENT
 */
@Component({
  selector: 'fn-password-update-dialog',
  template: `
    <div mat-dialog-content>
      <mat-card-title><h1>Update Password</h1></mat-card-title>
      <mat-card-content>
        <form [formGroup]="passwordUpdateForm" (ngSubmit)="!formSubmitted && onSubmit()" class="flex-col flex-start-end">

          <rv-password-form-field fieldName="currentPassword" [externalLabel]="true" overrideLabel="Current Password" [includeClearButton]="false"
                                  [formGroup]="passwordUpdateForm"/>
          <rv-password-form-field fieldName="newPassword" placeholder="New Password" [includeClearButton]="false" [formGroup]="passwordUpdateForm"
                                  [showAllPasswordRequirements]="true"/>
          <rv-password-form-field fieldName="newPasswordConfirm" placeholder="Confirm New Password" [includeClearButton]="false"
                                  [formGroup]="passwordUpdateForm"/>
          <mat-card-actions align="end" style="margin-bottom: 0; margin-top: 15px;" class="button-row-right">
            <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel" (click)="onNoClick()" data-cy="on-hold-button">
              Cancel
            </button>
            <button mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                    [disabled]="!this.passwordUpdateForm.valid" (click)="onSubmit(); $event.stopPropagation()">
              Update Password
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </div>
  `,
  styles: [`
      form {
          padding-top: 20px;
      }

      rv-password-form-field {
          width: 100%;
      }
  `]
})
export class PasswordUpdateDialog {
  passwordUpdateForm: FormGroup;
  formSubmitted = false;

  constructor(private readonly dialogRef: MatDialogRef<PasswordUpdateDialog>,
              private readonly fb: FormBuilder,
              private readonly store: Store) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.passwordUpdateForm = this.fb.group(
      {
        currentPassword: ['', {validators: [Validators.required]}],
        newPassword: ['', {validators: [Validators.required, ...CommonValidators.passwordValidators]}],
        newPasswordConfirm: ['', {validators: [Validators.required]}],
      },
      {
        validators: passwordConfirmValidator(
          'newPassword',
          'newPasswordConfirm'
        ),
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if (!this.passwordUpdateForm.valid || this.formSubmitted) {
      return;
    }
    this.formSubmitted = true;
    const newPassword = this.passwordUpdateForm.value.newPassword;
    const currentPassword = this.passwordUpdateForm.value.currentPassword;
    this.store.dispatch(PatronActions.changePassword(currentPassword, newPassword));
    this.dialogRef.close(true);
  }
}

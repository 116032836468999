<mat-stepper [disableRipple]="true" [selectedIndex]="patronSelectors.currentCreateAccountStep | select"
             class="stepper rn-stepper-locked" labelPosition="bottom">

  <mat-step label="Create Account" [completed]="patronSelectors.isComplete('enterEmail') | select" [editable]="false">
    <div class="registration-page">
      <rn-registration-email-form/>
    </div>
  </mat-step>
  <mat-step label="Verify Email" [completed]="patronSelectors.isComplete('verifyEmail') | select" [editable]="false">
    <div class="registration-page">
      <rn-registration-verify-email-form/>
    </div>
  </mat-step>
  <mat-step label="Details" [completed]="patronSelectors.isComplete('enterDetails') | select" [editable]="false">
    <div class="registration-page">
      <rn-registration-details-form/>
    </div>
  </mat-step>
  <mat-step label="Notifications" [completed]="patronSelectors.isComplete('setNotifications') | select" [editable]="false">
    <div class="registration-page">
      <rn-registration-notifications-form [email]="patronSelectors.email | select"/>
    </div>
  </mat-step>
  <mat-step label="Create Password" [completed]="patronSelectors.isComplete('createPassword') | select" [editable]="false">
    <div class="registration-page">
      <rn-registration-create-password-form *ngIf="!(patronSelectors.isComplete('createPassword') | select)" [branchId]="patronSelectors.branchId | select"/>
    </div>
  </mat-step>
</mat-stepper>
<rn-registration-complete *ngIf="patronSelectors.isComplete('createPassword') | select"/>

import {MxSelector} from '@store/store.types';
import {createSelector} from '@ngrx/store';

export const alwaysTrue: MxSelector<boolean> = createSelector(
  () => true
);

export const alwaysFalse: MxSelector<boolean> = createSelector(
  () => false
);

export const alwaysEmptyString: MxSelector<string> = createSelector(
  () => ''
);

export const alwaysEmptyObject: MxSelector<object> = createSelector(
  () => ({})
);

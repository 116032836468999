import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PatronNotificationCenterComponent} from './patron-notification-center.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [
    PatronNotificationCenterComponent,
  ],
  declarations: [
    PatronNotificationCenterComponent,
  ],
})
export class PatronNotificationsModule {
}

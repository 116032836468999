import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Environment} from '../model/environment';
import {OpenLibraryAuthorSearch} from '../model/open-library/open-library-author-search';

@Injectable({
  providedIn: 'root',
})
export class ItemRequestService {

  constructor(private environment: Environment, private http: HttpClient) {
  }

  searchGoogleAPIByISBN(isbn: string): any {
    const url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`;
    return this.http.get(url).pipe(
      tap((result) => console.log(result)),
      catchError((err: unknown) => throwError(err))
    );
  }

  searchGoogleAPIByQuery(q: string): any {
    const url = `https://www.googleapis.com/books/v1/volumes?q=${q}`;
    return this.http.get(url).pipe(
      tap((result) => console.log(result)),
      catchError((err: unknown) => throwError(err))
    );
  }

  /**
   * Searches Open Library API for author
   * @param author
   */
  searchOpenLibraryAuthor(author: string): Observable<OpenLibraryAuthorSearch> {
    author = author.replace(/ /g, '%20');
    const url = `https://openlibrary.org/search/authors.json?q=${author}`;
    return this.http.get<OpenLibraryAuthorSearch>(url);
  }
}

import {EMPTY, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NotificationService} from './notification.service';
import {Environment} from '../model/environment';
import {Registration, RegistrationExistingCard} from '@raven';

const headers = new HttpHeaders({'Content-Type': 'application/json'});

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private environment: Environment,
              private router: Router,
              private notificationService: NotificationService,
              private http: HttpClient) {
  }

  register(organizationId: number, registration: Registration): Observable<Registration> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/register`;
    return this.http.post(url, JSON.stringify(registration), {headers: headers});
  }

  registerEmail(registration: Registration): Observable<Registration> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/register/email`;
    return this.http.post(url, JSON.stringify(registration), {headers: headers});
  }

  validateExistingCard(barcode: string, pin: string): Observable<string> {
    const url = `${this.environment.apiUrl}/public/v1/patrons/lookup?barcode=${barcode}&pin=${pin}`;
    return this.http.get(url, {headers: headers, responseType: 'text'}).pipe(
      catchError((error: unknown) => {
        console.log('Error message', error)
        if (error instanceof HttpErrorResponse) {
          if (error.status == 409) {
            this.notificationService.showSnackbarError('This account is already set up for online access');
            this.router.navigate(['login']);
          } else if (error.status == 404) {
            this.notificationService.showSnackbarError('Unrecognised card number and pin, Please check your barcode and pin and try again.');
          } else if (error.status == 429) {
            this.notificationService.showSnackbarError('Your account has been locked out due to too many failed lookup attempts, please contact a librarian to recover your PIN.');
          } else {
            this.notificationService.showSnackbarError('Error looking up your account. Please contact customer support.');
          }
        }
        return EMPTY;
      })
    )
  }

  registerExistingCard(registration: RegistrationExistingCard): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.environment.apiUrl}/public/v1/patrons/register/existing`,
      JSON.stringify(registration), {headers: headers}
    ).pipe(catchError((error: unknown) => {
      console.log('Error message', error)
      if (error instanceof HttpErrorResponse) {
        if (error.status == 409) {
          this.notificationService.showSnackbarError('Error: this account already exists');
        } else {
          this.notificationService.showSnackbarError('Error registering your account. Please contact customer support.');
        }
      }
      return EMPTY;
    }));
  }
}

import {Component, inject, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {PatronEmailAvailableValidator} from '@store/patron/patrons.validators';
import {Store} from '@ngrx/store';
import {PatronActions} from '@store/patron/patron.actions';

@Component({
  selector: 'rn-registration-email-form',
  templateUrl: './registration-email-form.component.html',
  styleUrls: ['./registration-email-form.component.scss'],
})
export class RegistrationEmailFormComponent {
  @Input() forLookupContext = false;

  @Input() set email(email: string) {
    this.emailField.patchValue(email);
  }

  public readonly store = inject(Store);
  public readonly emailForm = inject(FormBuilder).group({
    email: ['', {
      validators: [Validators.required, Validators.email, Validators.maxLength(256)],
      asyncValidators: inject(PatronEmailAvailableValidator).validate
    }],
  });
  public readonly emailField = this.emailForm.get('email') as FormControl;

  public onClickNext() {
    this.store.dispatch(PatronActions.requestVerificationEmail(this.emailForm.value.email));
  }

}

import {Injectable} from '@angular/core';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {OpenLibraryAuthorSearch} from '../model/open-library/open-library-author-search';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {PatronItemRequest} from '../model/item-request/patron-item-request';
import {ResponseBase} from '../model/response-base/response-base';
import {Environment} from '../model/environment';
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class PatronItemRequestService {
  organizationId: number;
  branchId: number;
  patronId: number;

  constructor(
    private environment: Environment,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    this.notificationService = notificationService;
  }

  cancel(patronItemRequestId: number): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v1/requests/${patronItemRequestId}/cancel`;
    return this.http.patch<PatronItemRequest>(url, '').pipe(
      map(() => {
        return true;
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            // NOT_FOUND - Patron Item Request ID was not found in DB
            this.notificationService.showSnackbarError('Invalid Item Request, please refresh and try again.');
          } else if (error.status === 403) {
            // FORBIDDEN - Patron is attempting to modify a patron item request record they do not own
            this.notificationService.showSnackbarError('Item Request not found or you are not the owner of the hold, please refresh and try again.');
          }
        }
        return of(false);
      })
    );
  }

  getPatronRequest(patronItemRequestId: number): Observable<PatronItemRequest> {
    const url = `${this.environment.apiUrl}/patrons/v1/requests/${patronItemRequestId}`;
    return this.http.get<ResponseBase<PatronItemRequest>>(url)
      .pipe(map(response => response.objects[0]));
  }

  getAllPatronRequests(): Observable<Array<PatronItemRequest>> {
    const url = `${this.environment.apiUrl}/patrons/v1/requests`;
    return this.http.get<ResponseBase<PatronItemRequest>>(url).pipe(
      map((responseBase) => {
        return responseBase.objects;
      })
    );
  }

  markStaffCommentsRead(patronItemRequestId: number): Observable<boolean> {
    const url = `${this.environment.apiUrl}/patrons/v1/requests/${patronItemRequestId}/comments/read`;
    return this.http.patch<void>(url, {}, {observe: 'response'}).pipe(
      map((httpsResponse) => {
        return httpsResponse.status == 200;
      })
    );
  }

  getAllRequestComments(): any {
    return {};
  }

  createPatronComment(patronItemRequestId: number, comment: string): Observable<boolean> {
    return this.http.post(`${this.environment.apiUrl}/patrons/v1/requests/${patronItemRequestId}/comments`, comment)
      .pipe(map(() => true))
      .pipe(catchError(() => {
        this.notificationService.showSnackbarError('Comment could not be saved, please try again');
        return of(false);
      }))
  }

  submitItemRequest(title: string, author: string, pickupLocationId: number, notes: string, isbn?: string, materialTypeDesc?: string): Observable<PatronItemRequest> {
    const patronItemRequestParams = {
      itemTitle: title,
      requestedBranchId: pickupLocationId,
      itemAuthor: author,
      notes,
      isbn,
      materialTypeDesc
    };

    const url = `${this.environment.apiUrl}/patrons/v1/patrons/requests`;
    return this.http.post<PatronItemRequest>(url, {}, {params: patronItemRequestParams});
  }

  searchByISBN(isbn: string): any {
    const url = `https://www.googleapis.com/books/v1/volumes?q=${isbn}`;
    return this.http.get(url).pipe(
      tap((result) => console.log(result)),
      catchError((err: unknown) => throwError(err))
    );
  }

  /**
   * Searches Open Library API for author
   * @param author
   */
  searchOpenLibraryAuthor(author: string): Observable<OpenLibraryAuthorSearch> {
    author = author.replace(/ /g, '%20');
    const url = `https://openlibrary.org/search/authors.json?q=${author}`;
    return this.http.get<OpenLibraryAuthorSearch>(url);
  }

}

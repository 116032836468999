import {Component, Inject, inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {PatronActions} from '@store/patron/patron.actions';

@Component({
  selector: 'rn-forgot-password-dialog',
  template: `
    <mat-dialog-content class="dialog">
      <h3>
        {{ !submitted ? 'Reset Password' : 'Check your email' }}
      </h3>
      <div>
        <form *ngIf="!submitted" [formGroup]="emailForm">
          <div style="margin-bottom: 16px">Get instructions sent to this email that explain how to reset your password</div>
          <rv-input-form-field fieldName="email" [externalLabel]="true" overrideLabel="Email address" [formGroup]="emailForm"/>
          <button *ngIf="!submitted" mat-raised-button color="primary" class="cta-button"
                  [disabled]="emailForm.invalid"
                  (click)="forgotPassword()">Reset Password
          </button>
        </form>
        <ng-container *ngIf="submitted">
          <span>Follow the instructions sent to {{ email }} to reset your password</span>
          <button *ngIf="submitted" mat-raised-button color="primary" class="cta-button"
                  [disabled]="emailForm.invalid"
                  (click)="closeDialog()">Close
          </button>
        </ng-container>
      </div>
    </mat-dialog-content>
  `,
  styles: [`
      .dialog {
          max-width: 320px;
      }

      .cta-button {
          width: min(325px, 100%);
          height: 65px;
          margin: 16px 0 0 0;
          padding: 1px 0 0;
          color: white;
          text-align: center;
      }
  `]
})
export class ForgotPasswordDialogComponent implements OnInit {
  private readonly store = inject(Store);
  emailForm: FormGroup
  submitted = false;
  email: string;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { email: string }
  ) {
  }

  ngOnInit() {
    this.emailForm = this.fb.group(({
      email: [this.data.email ? this.data.email : '', {validators: [Validators.email]}],
    }));
  }

  async forgotPassword() {
    if (!this.emailForm.valid) {
      return
    }
    this.store.dispatch(PatronActions.resetPatronPassword(this.emailForm.value.email));
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {filter, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Environment} from '../model/environment';
import {ChiliFreshProReviewsResponse, ChiliFreshRatingsResponse, ChiliFreshResponseBase} from '../model/chili-fresh/chili-fresh-response';
import {ChiliFreshRating} from '../model/chili-fresh/chili-fresh-rating';
import {ChiliFreshProReview} from '../model/chili-fresh/chili-fresh-pro-review';

@Injectable({
  providedIn: 'root'
})
export class ChiliFreshService {

  readonly REVIEWS_RESPONSE_SUCCESS = 110;
  readonly RATINGS_RESPONSE_SUCCESS = 100;

  constructor(private http: HttpClient,
              private environment: Environment,) {
  }

  getCombinedRating(identifiers: string[]): Observable<ChiliFreshRating> {
    const url = `${this.environment.apiUrl}/public/v1/catalog-records/ratings`
    const params = new HttpParams({fromObject: {identifiers: identifiers.toString()}});
    return this.http.get<ChiliFreshResponseBase>(url, {params})
      .pipe(
        catchError(() => of({response: null})),
        filter(r => r && r.response),
        map(r => r.response as ChiliFreshRatingsResponse),
        filter(ratingsResponse => ratingsResponse.response_code == this.RATINGS_RESPONSE_SUCCESS),
        map(ratingsResponse => {
          const item = ratingsResponse.results.item;
          return Array.isArray(item) ? item.map(i => new ChiliFreshRating(i)) : new ChiliFreshRating(item);
        }),
        map(item => {
          return Array.isArray(item) ? ChiliFreshRating.combineRatings(item) : item;
        }),
      )
  }

  getRatings(identifiers: string[]): Observable<ChiliFreshRating[]> {
    const url = `${this.environment.apiUrl}/public/v1/catalog-records/ratings`
    const params = new HttpParams({fromObject: {identifiers: identifiers.toString()}});
    return this.http.get<ChiliFreshResponseBase>(url, {params})
      .pipe(
        catchError(() => of({response: null})),
        filter(r => r && r.response),
        map(r => r.response as ChiliFreshRatingsResponse),
        filter(ratingsResponse => ratingsResponse.response_code == this.RATINGS_RESPONSE_SUCCESS && ratingsResponse.header && ratingsResponse.header.item_count > 0),
        map(ratingsResponse => {
          const item = ratingsResponse.results.item;
          return Array.isArray(item) ? item.map(i => new ChiliFreshRating(i)) : new ChiliFreshRating(item);
        }),
        map(item => item as ChiliFreshRating[]),
      )
  } // maybe returning a dict of identifier => rating object would be more useful

  getProReviews(identifier: string): Observable<ChiliFreshProReview[]> {
    const url = `${this.environment.apiUrl}/public/v1/catalog-records/pro-reviews`
    const params = new HttpParams({fromObject: {identifier: identifier}});
    return this.http.get<ChiliFreshResponseBase>(url, {params})
      .pipe(
        catchError(() => of({response: null})),
        filter(r => r && r.response),
        map(r => r.response as ChiliFreshProReviewsResponse),
        filter(reviewsResponse => reviewsResponse.response_code == this.REVIEWS_RESPONSE_SUCCESS),
        map(reviewsResponse => {
          if (!reviewsResponse.item || reviewsResponse.item.total_review_count == 0) {
            return [];
          }
          const item = reviewsResponse.results.item;
          return Array.isArray(item) ? item : [item];
        }),
      )
  }
}



import {createActionGroup} from '@ngrx/store';
import {ROUTER_FEATURE_KEY} from '@store/router/router.types';
import {Sort} from '@angular/material/sort';
import {Params} from '@angular/router';
import {OneOrArrayOf} from '@store/utility-type.helpers';

export const RouterActions = createActionGroup({
  source: ROUTER_FEATURE_KEY,
  events: {
    'Navigate To': (routeName: string, params?: Params) => ({routeName, params: params ?? {}}),
    'Replace Route With': (routeName: string, params?: Params) => ({routeName, params: params ?? {}}),

    'Update Search Params':
      (query: string, queryType: string, destinationRouteName: string) => ({query, queryType, destinationRouteName}),
    'Update Pagination Params': (page: number, pageSize: number) => ({page, pageSize}),
    'Update Sort Params': (angularSortState: Sort, entityId?: number) => ({angularSortState, entityId}),
    'Add Filter Param': (name: string, value: string) => ({name, value}),
    'Remove Filter Param': (name: string) => ({name}),
    'Remove Query Params': (names: OneOrArrayOf<string>) => ({names})
  }
});

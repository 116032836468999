import {Component, Input} from '@angular/core';
import {Checkout, CHECKOUT_STATUS, CLAIM_STATUS} from '@raven';

@Component({
  selector: 'rn-checkout-status',
  template: `
    <mat-chip-set style="display: inline-block">
      <mat-chip class="blue-pill" *ngIf="hasClaim(checkout)">
        {{ getClaimStatus(checkout.claimType) }}
      </mat-chip>
      <mat-chip class="red-pill" *ngIf="inTheRed(checkout)">
        {{ dateFromNow(checkout) }}
      </mat-chip>
      <mat-chip class="yellow-pill" *ngIf="inTheYellow(checkout)">
        {{ dateFromNow(checkout) }}
      </mat-chip>
      <mat-chip class="green-pill" *ngIf="inTheGreen(checkout)">
        {{ dateFromNow(checkout) }}
      </mat-chip>
    </mat-chip-set>
  `,
  styles: [`
  `],
})
export class PatronCheckoutStatusComponent {
  @Input() checkout: Checkout;

  hasClaim(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.CLAIMED_RETURNED
      || checkout.status == CHECKOUT_STATUS.CLAIMED_NEVER_HAD
      || checkout.status == CHECKOUT_STATUS.CLAIMED_MISSING_PARTS;
  }

  inTheRed(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.OVERDUE ||
      (checkout.status == CHECKOUT_STATUS.DUE && checkout.dueDays <= 2);
  }

  inTheYellow(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.DUE && checkout.dueDays > 2 && checkout.dueDays <= 7;
  }

  inTheGreen(checkout: Checkout): boolean {
    return checkout.status == CHECKOUT_STATUS.CHECKED_IN ||
      (checkout.status == CHECKOUT_STATUS.DUE && checkout.dueDays > 7);
  }

  dateFromNow(checkout: Checkout): string {
    if (checkout.status == CHECKOUT_STATUS.CHECKED_IN) {
      return `Returned`;
    }
    if (checkout.dueDays > 0) {
      return `Due in ${checkout.dueDays} days`;
    }
    if (checkout.dueDays < 0) {
      return `Overdue by ${-checkout.dueDays} days`;
    }
    return 'Due';
  }

  getClaimStatus(status: CLAIM_STATUS) {
    if (!status) {
      return '';
    }
    const statusStr = status.replace(/_/g, ' ');
    return statusStr[0] + statusStr.slice(1).toLowerCase();
  }
}

import {Component, DestroyRef, inject, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {SelectOptions} from '@store/common/common.types';
import {PatronActions} from '@store/patron/patron.actions';
import {PatronSelectors} from '@store/store.selectors';
import {isPopulatedString} from '@store/common/typing.helpers';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CommonValidators} from '@store/common/common.validators';

@Component({
  selector: 'rn-registration-notifications-form',
  templateUrl: './registration-notifications-form.component.html',
  styleUrls: ['./registration-notifications-form.component.scss'],
})
export class RegistrationNotificationsFormComponent {
  public readonly patronSelectors = PatronSelectors.Registration;
  public readonly notificationsForm = inject(FormBuilder).group({
    notificationEmail: ['', [Validators.required]],
    mobilePhone: ['', [CommonValidators.phoneNumber]],
    notifyMethod: ['EMAIL'],
  });
  public readonly emailField = this.notificationsForm.get('notificationEmail') as FormControl;
  public readonly phoneField = this.notificationsForm.get('mobilePhone') as FormControl;
  public readonly notifyMethodField = this.notificationsForm.get('notifyMethod') as FormControl;
  public readonly store = inject(Store);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  public readonly NOTIFICATION_OPTIONS: SelectOptions<string> = [
    {displayName: 'Email', value: 'EMAIL'},
    {displayName: 'Text', value: 'TEXT'}
  ];

  @Input({required: true}) set email(email: string) {
    this.emailField.patchValue(email);
  }

  constructor() {
    this.notifyMethodField.disable();
    this.phoneField.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.notifyMethodField[this.hasValidPhone() ? 'enable' : 'disable']());
  }

  public onClickNext() {
    const value = this.notificationsForm.value;
    const hasValidPhone = this.hasValidPhone();
    this.store.dispatch(PatronActions.updateRegistration({
      notificationEmail: value.notificationEmail,
      mobilePhone: value.mobilePhone,
      notifyText: hasValidPhone && value.notifyMethod === 'TEXT',
      notifyEmail: (!hasValidPhone) || value.notifyMethod === 'EMAIL',
    }));
    this.store.dispatch(PatronActions.completeRegistrationStep('setNotifications'));
  }

  public hasValidPhone(): boolean {
    return isPopulatedString(this.phoneField.value) && this.phoneField.valid;
  }
}

import {MemoizedSelector} from '@ngrx/store';
import {LoadStatus} from './common/common.types';
import {PickKeysByTypeFrom} from './utility-type.helpers';

export type MxSelector<T> = MemoizedSelector<object, T>;
export type MxSelectorCreator<P extends unknown[], T> = (...params: P) => MxSelector<T>;

export type MxSelectorOrSimply<T> = MxSelector<T> | T;

/* eslint-disable @typescript-eslint/no-explicit-any -- This is literally "any"! */
export type MxSelectorCreatorBaseObjectType<M> = M extends MxSelectorCreator<[infer BaseObjectType], any> ? BaseObjectType : never;
export type MxSelectorCreatorReturnType<M> = M extends MxSelectorCreator<any, infer ReturnType> ? ReturnType : never
/* eslint-enable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This literally means a selector creator whose projector returns any type.
export type AnyMxSelectorCreatorMatching<T> = MxSelectorCreator<[T], any>;

export type MxSelectorNameMatching<T, SelectorsContainer extends object> =
  PickKeysByTypeFrom<SelectorsContainer, MxSelector<T>>;

export type MxSelectorCreatorNameMatching<T, SelectorsContainer extends object> =
  PickKeysByTypeFrom<SelectorsContainer, AnyMxSelectorCreatorMatching<T>>;

export type MxSelectorCreatorNameMatchingWithReturnType<T, SelectorsContainer extends object, ReturnType> =
  PickKeysByTypeFrom<SelectorsContainer, MxSelectorCreator<[T], ReturnType>>;

type GeneratedSelectorNameFrom<SelectorName, NamePrefix extends string> =
  NamePrefix extends ''
    ? SelectorName  // No prefix, so we already have the name.
    : SelectorName extends string // Must check this explicitly, or TypeScript won't allow the type conversion on the next line.
      ? `${NamePrefix}${Capitalize<SelectorName>}` // 'prefix' + 'selectorName' => 'prefixSelectorName'
      : never;

export type GeneratedSelectorsFor<ObjectType, NamePrefix extends string> = {
  [SelectorName in keyof ObjectType as GeneratedSelectorNameFrom<SelectorName, NamePrefix>]: MxSelector<ObjectType[SelectorName]>;
};

type GeneratedSelectorCreatorNameFrom<SelectorCreatorName, NamePrefix extends string> =
  NamePrefix extends ''
    ? SelectorCreatorName extends string // Must check this explicitly, or TypeScript won't allow the type conversion on the next line.
      ? `${SelectorCreatorName}For` // No prefix, so just add our suffix to the name.
      : never
    : SelectorCreatorName extends string // Must check this explicitly, or TypeScript won't allow the type conversion on the next line.
      ? `${NamePrefix}${Capitalize<SelectorCreatorName>}For` // 'prefix' + 'selectorName' => 'prefixSelectorNameFor'
      : never;

export type GeneratedSelectorCreatorsFor<ObjectType, NamePrefix extends string, BaseObjectType> = {
  [SelectorName in keyof ObjectType as GeneratedSelectorCreatorNameFrom<SelectorName, NamePrefix>]: // key index
  MxSelectorCreator<[BaseObjectType], ObjectType[SelectorName]>; // value
};

export const NO_NAME_PREFIX = ''; // For use with selector generators and selector creator generators.

export type NumberHelperSelectorType = 'IS_NEGATIVE' | 'IS_ZERO' | 'IS_POSITIVE';
export type NumberHelperProjector = (number: number) => boolean;

export type ArrayHelperSelectorType = 'IS_EMPTY' | 'IS_POPULATED';
export type ArrayHelperProjector = (array: unknown[]) => boolean;

export type LoadStatusSubstate = {
  loadStatus: LoadStatus;
};

export const INITIAL_LOAD_STATUS_SUBSTATE: LoadStatusSubstate = {
  loadStatus: 'PRELOAD'
};

export interface LoadStatusSelectors {
  loadStatus: MxSelector<LoadStatus>;
  preload: MxSelector<boolean>;
  loading: MxSelector<boolean>;
  awaitingFirstLoad: MxSelector<boolean>;
  reloading: MxSelector<boolean>;
  awaitingAnyLoad: MxSelector<boolean>;
  loaded: MxSelector<boolean>;
  loadFailed: MxSelector<boolean>;
  finishedLoading: MxSelector<boolean>;
}

export interface PersonNameSelectors {
  firstAndLastName: MxSelector<string>;
  firstAndLastNameWithPronouns: MxSelector<string>;
  preferredFullNameWithPronouns: MxSelector<string>;
  firstNameIfNotPreferredName: MxSelector<string>
  preferredOrFirstName: MxSelector<string>;
  fullName: MxSelector<string>;
  firstInitialAndLastName: MxSelector<string>;
}

export interface PersonNameSelectorCreators<T> {
  firstAndLastNameFor: MxSelectorCreator<[T], string>;
  abbreviatedName: MxSelectorCreator<[T], string>;
}


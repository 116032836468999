import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, of, switchMap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BranchService} from './branch.service';
import {PatronActions} from '../patron/patron.actions';
import {BranchActions} from './branch.actions';
import {OrganizationActions} from '@store/organization/organization.actions';

@Injectable({providedIn: 'root'})
export class BranchEffects {

  private readonly actions: Actions = inject(Actions);
  private readonly service: BranchService = inject(BranchService);

  loadAuthBranch = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.loadAuthPatronSuccess),
      switchMap(({patron}) => this.service.getAuthBranch(patron.branchId).pipe(
        map(response => BranchActions.loadAuthBranchSuccess(response)),
        catchError((error: unknown) => of(BranchActions.loadAuthBranchFailure(error)))
      ))
    )
  );

  public loadActiveBranches = createEffect(() =>
    this.actions.pipe(
      ofType(OrganizationActions.initializeApp),
      switchMap(() => this.service.getBranches().pipe(
        map(response => BranchActions.loadActiveBranchesSuccess(response)),
        catchError((error: unknown) => of(BranchActions.loadActiveBranchesFailure(error)))
      ))
    )
  );

}

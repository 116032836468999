<rv-base-form-field [fieldName]="fieldName" [overrideLabel]="overrideLabel" [externalLabel]="externalLabel" [formGroup]="formGroup" [control]="control">
  <div (click)="onClick()" class="flex-row flex-center-space-between full-width" [ngClass]="{ 'cursor-pointer': !disabled }">
    <!-- This <input> element is connected to the form control, but it's hidden in the UI.  It holds the actual form field value. -->
    <input matInput type="text" [formControl]="control" class="hidden"/>

    <!--
      This <mat-date-range-input> element is connected to the date picker, but not to the form control.  It displays the nicely formatted version of the
      picked date range.  <mat-date-range-input> does not play well with form controls (especially with validation and error display), presumably because
      it has no value of its own, and is really just a wrapper around two different <input> elements.  We could hook those up as separate form controls,
      but then we wouldn't have a convenient single form control value like a custom form field component should.

      Thus, we use this standard element to facilitate the picker, and the <input> above to manage the form control and its single value.  Methods in this
      component take care of keeping both updated.
    -->
    <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate" [disabled]="disabled" [ngClass]="{ 'show-separator': !!startDate }">
      <input matStartDate readonly type="text" tabIndex="-1" [value]="startDate" (dateChange)="onDateChangeForStart($event)"/>
      <input matEndDate readonly type="text" tabIndex="-1" [value]="endDate" (dateChange)="onDateChangeForEnd($event)"/>
    </mat-date-range-input>

    <!-- We purposely skip the standard [for] attribute here because we're controlling the picker open/close via onClick() in the container <div>. -->
    <mat-datepicker-toggle matSuffix [disabled]="disabled">
      <mat-icon matDatepickerToggleIcon class="material-icons-outlined">calendar_today</mat-icon>
    </mat-datepicker-toggle>
  </div>

  <mat-date-range-picker #picker [restoreFocus]="false" panelClass="hk-date-range-form-field-picker" (opened)="onOpenedPicker()" (closed)="onClosedPicker()"/>
</rv-base-form-field>

<div style="height: 100%; display: flex; align-items: center;">
  <button mat-button class="notification-dropdown" #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="notificationMenu" (menuOpened)="OnNotificationMenuOpened()">
    <div class="flex-row flex-center-center">
      <img *ngIf="!(hasUnread$ | async)" alt="notifications" src="/assets/slicons/notification.svg"/>
      <img *ngIf="hasUnread$ | async" style="margin-top: 1px" alt="notifications" src="/assets/slicons/notification-in-basket.svg"/>
    </div>
  </button>
</div>
<mat-menu #notificationMenu="matMenu" [overlapTrigger]="false" class="remove-max-width remove-padding">
  <div class="notification-menu" (click)="$event.stopPropagation()">
    <div class="notification-menu-header flex-row flex-center-start">
      <img class="header-icon header-bell" alt="" src="/assets/slicons/notification.svg"/>
      <h4 class="header-title">Notifications</h4>
    </div>
    <ng-container *ngIf="(notifications$ | async) as notifications; else loading_template">
      <mat-tab-group #notificationMenuTabGroup dynamicHeight animationDuration="0" class="header-tabs">
        <mat-tab>
          <ng-template mat-tab-label>
            <h3>Unread</h3>
          </ng-template>
          <div *ngIf="!(hasUnread$ | async); else mark_all_template" class="empty-message flex-col flex-center-start">
            <img class="caught-up-bell" alt="" src="/assets/slicons/notification.svg"/>
            <h1>You are all caught up!</h1>
            <h4>You don't have any unread notifications.</h4>
          </div>
          <ng-template #mark_all_template>
            <h4 class="mark-all pointer flex-row flex-center-start" (click)="markAllRead(notifications)">
              <em class="mark-all-icon ri-eye-line"></em>
              Mark all as read
            </h4>
          </ng-template>
          <ng-container *ngFor="let n of notifications">
            <ng-container *ngIf="!n.viewed">
              <mat-divider></mat-divider>
              <h4 class="title-row flex-row flex-center-start">
                <span class="message-icon-container">
                  <span class="exclamation-circle">!</span>
                </span>
                <span class="title">{{ PATRON_NOTIFICATION_TITLES[n.notificationType] }}</span>
                <em class="mark-one ri-eye-line pointer" (click)="markRead(n)" matTooltip="Mark Read"></em>
              </h4>
              <div class="message-text" [innerHTML]="n.message"></div>
              <div class="message-date"><img class="date-bell" alt="" src="/assets/slicons/notification.svg"/> {{ n.created | date }}</div>
            </ng-container>
          </ng-container>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <h3>Read</h3>
          </ng-template>
          <div *ngIf="!(hasRead$ | async); else delete_all_template" class="empty-message flex-col flex-center-start">
            <img class="caught-up-bell" alt="" src="/assets/slicons/notification.svg"/>
            <h1>You don't have any read notifications.</h1>
          </div>
          <ng-template #delete_all_template>
            <h4 class="mark-all pointer flex-row flex-center-start" (click)="softDeleteAll(notifications)">
              <em class="mark-all-icon ri-delete-bin-line"></em>
              Delete All
            </h4>
          </ng-template>
          <ng-container *ngFor="let n of notifications">
            <ng-container *ngIf="n.viewed">
              <mat-divider></mat-divider>
              <h4 class="title-row flex-row flex-center-start">
                <span class="message-icon-container">
                  <span class="exclamation-circle">!</span>
                </span>
                <span class="title">{{ PATRON_NOTIFICATION_TITLES[n.notificationType] }}</span>
                <em class="mark-one ri-delete-bin-line pointer" (click)="softDelete(n)" matTooltip="Delete"></em>
              </h4>
              <div class="message-text" [innerHTML]="n.message"></div>
              <div class="message-date"><img class="date-bell" alt="" src="/assets/slicons/notification.svg"/> {{ n.created | date }}</div>
            </ng-container>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #loading_template>
      <div class="flex-container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</mat-menu>

import {ComponentRef, Directive, ElementRef, Input, Optional, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoadingTemplateDirective} from "./loading-template.directive";
import {LoadingWrapperComponent} from "./loading-wrapper/loading-wrapper.component";

@Directive({
  selector: '[rvIsLoading]'
})
export class IsLoadingDirective {

  _mainView: HTMLElement[];
  _loadingView: HTMLElement[];

  _loading = true;
  _loadingWrapper: ComponentRef<LoadingWrapperComponent>;

  @Input() set rvIsLoading(val) {
    this._loading = !!val;
    if (this._loadingWrapper) {
      this._loadingWrapper.instance.loading = !!val;
    } else {
      this.updateViewState(!!val);
    }
  }

  @Input() templateType: undefined | 'table';

  @Input() set rvIsLoadingTarget(targetEl) {
    this._mainView = [targetEl];
    this.updateViewState(this._loading);
  }

  constructor(
    private _el: ElementRef,
    private _vcr: ViewContainerRef,
    @Optional() private _template: TemplateRef<any>,
    private _loadingTmpl: LoadingTemplateDirective,
  ) {
    (window as any).loading = this;
    const loadingTemplate = this.templateType === 'table' ? _loadingTmpl.rvLoadingTemplateTable : _loadingTmpl.rvLoadingTemplate;

    if (this._template) {
      const wrapper = this._loadingWrapper = _vcr.createComponent(LoadingWrapperComponent);
      wrapper.instance.mainContent = _template;
      wrapper.instance.loadingIndicator = loadingTemplate;
    } else {
      this._mainView = [_el.nativeElement];
      this._loadingView = _vcr.createEmbeddedView(loadingTemplate).rootNodes;
    }
  }

  updateViewState(loading: boolean) {
    // debugger;
    const mainViewVisibility = loading ? 'none' : 'block';
    const loadingViewVisibility = loading ? 'block' : 'none';

    const rect = this._mainView[0].getBoundingClientRect();

    this._mainView.forEach((node: HTMLElement) => {
      node.style.display = mainViewVisibility;
      // node.style.position = 'relative';
    });

    this._loadingView.forEach((node: HTMLElement) => {
      node.style.display = loadingViewVisibility;
      node.style.width = rect.width + 'px';
      node.style.height = rect.height + 'px';
      node.style.position = 'fixed';
      node.style.top = rect.top + 'px';
      node.style.left = rect.left + 'px';
      node.style.opacity = '0.5';
    });
  }
}

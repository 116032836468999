<ng-container *ngIf="catalogRecord$ | async as catalogRecord; else loading_template">
  <ng-container *ngIf="!loading; else loading_template">
    <ng-container *ngIf="catalogRecord.marcDetails; else marcError">
      <ng-container *ngTemplateOutlet="itemDetails; context: { catalogRecord: catalogRecord}"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #itemDetails let-cr="catalogRecord">
  <rn-background-accent setHeightFromSelector=".circulation"></rn-background-accent>
  <div class="catalog-item-details-container">
    <div class="above-tabs flex-row-wrap flex-start-space-around">
      <div class="left-column">
        <rn-fallback-image alt="Book Cover" [imageUrls]="CatalogRecord.getImageUrls(cr, coverImageStrategy)"></rn-fallback-image>
      </div>
      <div class="right-column flex-col">
        <div class="title-row center-xs">
          <h2 class="title">{{ (cr.title ? cr.title : 'Not Available') | titlecase }}</h2>
          <div *ngIf="!mobile" class="item-status">
            <rn-hold-status *ngIf="hold" [hold]="hold"></rn-hold-status>
          </div>
        </div>
        <div class="center-xs" style="margin-bottom: 12px;">
          <div>
            <div class="author">Author: {{ cr.marcDetails.author ? cr.marcDetails.author : 'Not Available' }}</div>
            <div class="published">Published:
              {{ (cr.marcDetails && cr.marcDetails.publicationDate) ? (cr.marcDetails.publicationDate) : 'Not available' }}
            </div>
            <div class="format">Format: {{ getMaterialTypeDisplayString(cr.materialTypeMatches) }}</div>
          </div>
        </div>
        <ng-container *ngIf="mobile">
          <rn-hold-status *ngIf="hold" [hold]="hold" class="center-xs"></rn-hold-status>
        </ng-container>
        <div class="circulation center-xs">
          <ng-container *ngTemplateOutlet="circulationInfo; context: { item: cr }"></ng-container>
        </div>
        <div class="actions">
          <div class="rating-and-favorite flex-row-wrap" style="gap: min(1.5vw, 45px)">
            <ng-container *ngTemplateOutlet="starRating"></ng-container>
            <ng-container *ngTemplateOutlet="addToFavorites"></ng-container>
            <a class="share-trigger pointer" [matMenuTriggerFor]="shareMenu">
              <mat-icon>share</mat-icon>
              <span>&nbsp;Share</span>
            </a>
            <mat-menu #shareMenu>
              <div style="padding: 8px 16px">
                <button mat-button mat-raised-button color="primary" (click)="copyLink()" style="margin: 0; height: 40px">Copy link</button>
              </div>

            </mat-menu>
          </div>
          <rn-account-status-warning context="placeHold"></rn-account-status-warning>
          <div class="flex-row flex-gap-16" style="width: 100%">
            <button (click)="placeHold()" *ngIf="!hold" [disabled]="!patronCanPlaceHold" class="hold" color="primary" mat-flat-button>
              Place a Hold
            </button>
            <ng-container *ngIf="hold && isInTransitStatus(hold)">
              <div class="flex-col" style="width: 100%">
                <div class="not-available flex-row">
                  <em class="ri-error-warning-fill" aria-hidden="false"></em>
                  <span>Item is "In Transit" and cannot be canceled</span>
                </div>
                <button disabled mat-flat-button>
                  Cancel Hold
                </button>
              </div>
            </ng-container>
            <button *ngIf="hold && isReadyForPickupStatus(hold)" disabled="true" class="secondary"
                    style="cursor: default;">
              <div matTooltip="Cannot Pause or Cancel" matTooltipPosition="above">
                <ng-container *ngIf="isReadyForPickupStatus(hold)">Pick up by {{ hold.expireDate | date: 'MMM d' }}</ng-container>
              </div>
            </button>
            <button (click)="cancelHold()" *ngIf="hold && isCancellableStatus(hold)" class="secondary">
              Cancel Hold
            </button>
            <button (click)="pauseHold()" *ngIf="hold && isRequestedStatus(hold)" class="secondary">
              Pause Hold
            </button>
            <button (click)="unpauseHold()" *ngIf="hold && isPausedStatus(hold)" class="secondary">
              Unpause Hold
            </button>
          </div>
          <div class="center-center" *ngIf="!(patronSelectors.hasPatron | select)">
            <span>To place a hold, please
              <a class="pointer" (click)="patronService.openLoginDialog()">
                login to your account.
              </a>
              Don’t have an account?
              <a class="pointer" routerLink="/register">
                Register here.
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="tabGroup; context: {cr: cr}"></ng-container>
    <div class="related-titles-carousel">
      <rn-book-carousel *ngIf="relatedTitles$ | async as relatedTitles" [catalogRecords]="relatedTitles" title="Related Titles"></rn-book-carousel>
    </div>
  </div>
</ng-template>

<ng-template #tabGroup let-cr="cr">
  <div class="tabs">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab>
        <ng-template mat-tab-label>
          <h3>Summary</h3>
        </ng-template>
        <ng-container *ngIf="meta$ | async as metadata">
          <rn-catalog-details-summary-tab
            [catalogRecord]="cr"
            [metadata]="metadata"
          ></rn-catalog-details-summary-tab>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <h3>Availability</h3>
        </ng-template>
        <ng-template matTabContent>
          <rn-catalog-details-availability-tab
            [catalogRecord]="cr"
          ></rn-catalog-details-availability-tab>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <h3>Reviews</h3>
        </ng-template>
        <ng-template matTabContent>
          <rn-catalog-details-reviews-tab
            [catalogRecord]="cr"
          ></rn-catalog-details-reviews-tab>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #circulationInfo let-item="item">
  <rn-availability-count-pill [available]="item.availability.availableCount"
                              [copies]="item.availability.allBranchCount"
                              [holds]="item.availability.onHoldCount">
  </rn-availability-count-pill>
</ng-template>

<ng-template #starRating>
  <span class="rating" *ngIf="chiliFreshRating$ | async as rating" matTooltip="Ratings provided by ChiliFresh">
    <span [class.rating-stars]="rating.ratingCount > 0">
      <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">
        <mat-icon *ngIf="i <= rating.averageRating">star</mat-icon>
        <mat-icon *ngIf="i > rating.averageRating && i - 1 < rating.averageRating">star_half</mat-icon>
        <mat-icon *ngIf="i - 1 >= rating.averageRating">star_border</mat-icon>
      </ng-container>
    </span>
    <span>&nbsp;{{ rating.ratingCount }} Ratings</span>
  </span>
</ng-template>

<ng-template #addToFavorites>
  <span *ngIf="favoriteService?.favorites | async as favorites" class="favorite">
    <span *ngIf="!favorites[catalogRecordId]" (click)="addFavorite()" class="pointer">
      <mat-icon class="favorite-heart">favorite_border</mat-icon>
      <span>&nbsp;Add to Favorites</span>
    </span>
    <span *ngIf="!!favorites[catalogRecordId]" (click)="deleteFavorite()" class="pointer">
      <mat-icon class="favorite-heart">favorite</mat-icon>
      <span>&nbsp;Favorite</span>
    </span>
  </span>
</ng-template>

<ng-template #loading_template>
  <div class="flex-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #marcError>
  <div class="empty-list center-center">
    The requested catalog item could not be loaded at this time.
  </div>
</ng-template>

import {DateTimeIsoString} from '../common/common.types';

export const ORGANIZATION_FEATURE_KEY = 'organization';

export interface Organization {
  readonly id: number;
  readonly googleStaffTenantId: string;
  readonly googlePatronTenantId: string;
  readonly name: string;
  readonly slug: string;
  readonly websiteUrl: string;
  readonly staffUrl: string;
  readonly opacUrl: string;
  readonly iconUrl: string;
  readonly bannerUrl: string;
  readonly manualAddressVerificationUrl: string;
  readonly policyUrl: string;
  readonly invitationEmail: string;
  readonly contactEmail: string;
  readonly arcgisBoundaryUrl: string;
  readonly phoneNumber: string;
  readonly hooplaLibraryId: string;
  readonly coverImageSource: CoverImageSource;
  readonly active: boolean;
  readonly created: DateTimeIsoString;
  readonly modified: DateTimeIsoString;

  readonly demoOrganization: boolean;
  readonly demoStaffUsername: string;
  readonly demoStaffPassword: string;
  readonly demoOpacUsername: string;
  readonly demoOpacPassword: string;
}

export type CoverImageSource = 'CHILIFRESH' | 'BOWKER';


import {Component, inject} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';
import {PatronActions} from '@store/patron/patron.actions';
import {PatronSelectors} from '@store/store.selectors';
import {CommonValidators} from '@store/common/common.validators';

@Component({
  selector: 'rn-registration-verify-email-form',
  templateUrl: './registration-verify-email-form.component.html',
  styleUrls: ['./registration-verify-email-form.component.scss'],
})
export class RegistrationVerifyEmailFormComponent {
  public readonly patronSelectors = PatronSelectors.Registration;
  public readonly store = inject(Store);
  public readonly verifyForm = inject(FormBuilder).group({
    verificationCode: ['', [CommonValidators.pattern(/^\d{6,}$/, {mustBeVerificationCode: true})]],
  });

  public onClickResend() {
    this.store.dispatch(PatronActions.resendVerificationEmail());
  }

  public onClickNext() {
    this.store.dispatch(PatronActions.verifyEmail(this.verifyForm.value.verificationCode));
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, Observable, Subject, switchMap} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {PATRON_PAYMENT_METHOD, PatronAccountStatus, PatronCardPaymentMethod, PatronLedgerService, PatronPaymentService,} from '@raven';
import {PaymentConfirmationDialogComponent} from './payment-confirmation-dialog/payment-confirmation-dialog.component';
import {PaymentSetupDialogComponent} from './payment-setup-dialog/payment-setup-dialog.component';
import {snapshot} from '@store/store.helpers';
import {OrganizationSelectors} from '@store/store.selectors';
import {SelectOptions} from '@store/common/common.types';
import {DatePipe, TitleCasePipe} from '@angular/common';

@Component({
  selector: 'rn-make-a-payment-form',
  template: `
    <rn-expansion-panel [isCollapsed]="!startExpanded">
      <h2 expansionHeader>Make a Payment</h2>
      <form *ngIf="paymentMethods$ | async as paymentMethods" [formGroup]="makeAPaymentForm"
            class="makeAPayment flex-row-col-responsive flex-stretch-space-between" style="gap: 2vw">
        <div class="flex-col payment-source">
          <rv-select-form-field fieldName="paymentSource" overrideLabel="Payment Source" [externalLabel]="true"
                                [options]="paymentMethodOptions" [includeUndefinedOption]="false" [formGroup]="makeAPaymentForm"/>
        </div>
        <div class="flex-col payment-amount">
          <rv-currency-form-field fieldName="paymentAmount" overrideLabel="Payment Amount" [externalLabel]="true" [formGroup]="makeAPaymentForm"/>
        </div>
        <div class="flex-col payment-date">
          <rv-input-form-field fieldName="paymentDate" [externalLabel]="true" overrideLabel="Payment Date" [enabled]="false"
                               [formGroup]="makeAPaymentForm" [includeClearButton]="false"/>
        </div>
        <div class="flex-col payment-submit">
          <button mat-flat-button color="primary" class="make-payment-button" [disabled]="!this.makeAPaymentForm.valid || this.makeAPaymentForm.pristine"
                  (click)="submit()">
            Submit&nbsp;Payment
          </button>
        </div>
      </form>
    </rn-expansion-panel>
  `,
  styles: [`
      .make-payment-button {
          height: 50px;
      }

      @media screen and (max-width: 599px) {
          .make-payment-button {
              margin-bottom: 16px;
          }
      }

      @media screen and (min-width: 600px) {
          .make-payment-button {
              margin-top: 22px;
          }
      }

      .payment-source,
      .payment-amount,
      .payment-date,
      .payment-submit {
          flex: 1 1 100%;
      }

      @media screen and (min-width: 960px) {
          .payment-source,
          .payment-date,
          .payment-submit {
              max-width: 23.5%;
          }

          .payment-amount {
              flex: 1 5 100%;
              max-width: 23.5%;
          }
      }

      @media screen and (min-width: 600px) and (max-width: 959px) {
          .payment-source {
              max-width: 32%
          }

          .payment-amount {
              flex: 1 5 100%;
              max-width: 20%;
          }

          .payment-date,
          .payment-submit {
              max-width: 20%;
          }
      }
  `],
})
export class MakeAPaymentFormComponent implements OnInit, OnDestroy {
  @Input() startExpanded = true;
  @Input() patronAccountStatus: PatronAccountStatus;
  @Output() paymentCreated = new EventEmitter();
  makeAPaymentForm: FormGroup;
  paymentMethods$: Observable<PatronCardPaymentMethod[]>;
  firstSource: any = '';
  allSources: PatronCardPaymentMethod[] = [];
  organization = snapshot(OrganizationSelectors.Auth.organization);
  PATRON_PAYMENT_METHOD = PATRON_PAYMENT_METHOD;
  destroy$ = new Subject<boolean>();
  canApplePay = false;

  private readonly staticPaymentMethodOptions: SelectOptions<PATRON_PAYMENT_METHOD> = [
    {value: PATRON_PAYMENT_METHOD.GPAY, displayName: 'Google Pay'},
    {value: PATRON_PAYMENT_METHOD.APPLE, displayName: 'Apple Pay'},
    {value: PATRON_PAYMENT_METHOD.SQUARE, displayName: 'Manually enter a payment card'},
  ];
  public paymentMethodOptions: SelectOptions<PATRON_PAYMENT_METHOD | number> = this.staticPaymentMethodOptions;

  constructor(
    protected patronLedgerService: PatronLedgerService,
    private patronPaymentService: PatronPaymentService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    if (typeof ApplePaySession !== 'undefined') {
      try {
        this.canApplePay = ApplePaySession.canMakePayments();
      } catch (e) {
        console.log('Apple Pay unavailable');
      }
    }
    this.paymentMethods$ = this.patronPaymentService.getAllPaymentMethods()
      .pipe(tap(methods => {
        if (methods && methods.length > 0) {
          this.firstSource = methods[0];
          this.allSources = methods;
          this.paymentMethodOptions = [
            ...methods.map((method, index) => ({
              value: index,
              displayName: `${this.titleCasePipe.transform(method.cardBrand)} ${method.last4}`
            })),
            ...this.staticPaymentMethodOptions
          ];
        }
        if (this.makeAPaymentForm === undefined) {
          this.makeAPaymentForm = this.initMakeAPaymentForm(this.fb);
        }
      }));
  }

  initMakeAPaymentForm(fb: FormBuilder): FormGroup {
    const paymentValidators = [Validators.required, Validators.min(0.01)];
    if (this.patronAccountStatus) {
      paymentValidators.push(Validators.max(this.patronAccountStatus.currentAccountBalance));
    }
    return fb.group({
      paymentSource: [this.firstSource, [Validators.required]],
      paymentAmount: ['', paymentValidators],
      paymentDate: [{value: this.today(), disabled: true}, {validators: [Validators.required]}],
    });
  }

  today(): string {
    return this.datePipe.transform(new Date());
  }

  async submit() {
    this.makeAPaymentForm.updateValueAndValidity();
    if (!this.makeAPaymentForm.valid) {
      return;
    }
    const amount = Number(this.makeAPaymentForm.value.paymentAmount);
    const date = this.makeAPaymentForm.get('paymentDate').value;
    const sourceVal = this.makeAPaymentForm.value.paymentSource;
    const source = Object.values(PATRON_PAYMENT_METHOD).includes(sourceVal) ?
      sourceVal : this.allSources[sourceVal];
    let paymentType: PATRON_PAYMENT_METHOD;
    let savedCard = false;
    if (source instanceof Object) {
      paymentType = PATRON_PAYMENT_METHOD.SQUARE;
      savedCard = true;
    } else if (source instanceof String || typeof source === 'string') {
      paymentType = source as PATRON_PAYMENT_METHOD;
    } else {
      // unknown payment type
      return;
    }

    const dialogData = {
      source: source,
      paymentType: paymentType,
      savedCard: savedCard,
      paymentAmount: amount.toFixed(2),
      token: '', //gets set by PaymentSetupDialog depending on payment type
      sourceDisplayString: '',
      paymentDate: date,
      organizationName: this.organization.name,
    };

    const setupDialogRef = this.dialog.open(PaymentSetupDialogComponent, {
      data: dialogData,
      maxWidth: '95vw'
    });
    setupDialogRef.afterClosed()
      .pipe(
        filter(data => !!data && !!data.token),
        switchMap(data => {
          return this.dialog.open(PaymentConfirmationDialogComponent, {
            data: data,
            maxWidth: '95vw'
          }).afterClosed()
        }),
        take(1),
        filter(confirmation => !!confirmation && !!dialogData.token),
        switchMap(() => this.patronPaymentService.makePayment(paymentType, dialogData.token, amount, date)),
      )
      .subscribe(result => {
        if (result) {
          this.paymentCreated.emit(true);
          const paymentAmountControl = this.makeAPaymentForm.get('paymentAmount');
          paymentAmountControl.setValue('');
          paymentAmountControl.setErrors(null);
          this.makeAPaymentForm.markAsPristine();
          this.makeAPaymentForm.markAsUntouched();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

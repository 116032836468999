import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDatepickerInputEvent, MatDateRangePicker} from '@angular/material/datepicker';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {calculateMaxDateFromValidatorsFor, calculateMinDateFromValidatorsFor} from '../date-form-field/date.form-validators';
import {toDateOnlyRangeIsoString, toDateRangeObject, toLocalMidnight} from '@store/common/date.helpers';

@Component({
  selector: 'rv-date-range-form-field',
  templateUrl: './date-range-form-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFormFieldComponent implements OnInit {
  @ViewChild('picker') public picker: MatDateRangePicker<Date>;

  @Input() public fieldName: string;
  @Input() public overrideLabel?: string;
  @Input() public externalLabel = false;
  @Input() public formGroup: FormGroup;

  // See commentary for these fields in DateFormFieldComponent.  Both this component and that one calculate these values based on the same validators.
  public minDate?: Date;
  public maxDate?: Date;

  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.
  public disabled: boolean; // For disabling elements that the form control is unaware of.
  public startDate?: Date;
  public endDate?: Date;

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;
    this.minDate = calculateMinDateFromValidatorsFor(this.control);
    this.maxDate = calculateMaxDateFromValidatorsFor(this.control);

    this.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        ({start: this.startDate, end: this.endDate} = toDateRangeObject(value));
      });

    this.control.registerOnDisabledChange(disabled => this.disabled = disabled);
  }

  public onClick(): void {
    if (!this.disabled) this.picker.open();  // No need to worry about closing... the picker is modal, so clicking anywhere outside of it will close it.
  }

  public onOpenedPicker(): void {
    /*
      Technically, when the user opens the date picker (and there wasn't a date range value already), focus has left this control's element and there's
      no value yet.  So this field naturally displays an error if it has a required validator.

      But for this particular component, it's too early to show the error in that case (the user wasn't given a chance to pick a range yet), so we mark this
      control as untouched, which clears the error state.
    */
    if (!this.control.value) this.control.markAsUntouched();
  }

  public onClosedPicker(): void {
    /*
      When the user dismisses the picker, we manually mark the control as touched so we can show an error state if they dismissed the picker without
      picking a date range.  This undoes the meddling we needed to do in onOpenedPicker().
    */
    this.control.markAsTouched();
    if (this.startDate && !this.endDate) {
      this.endDate = this.startDate;
    }
    // there might be an easier way to emit an event to trigger an onChanges subscription when the picker is closed, but this works for now
    this.control.patchValue(toDateOnlyRangeIsoString(this.startDate, this.endDate), {emitEvent: true});
  }

  public onDateChangeForStart(event: MatDatepickerInputEvent<Date>): void {
    this.startDate = event.value ? toLocalMidnight(event.value) : undefined;

    this.updateFormFieldValue();
  }

  public onDateChangeForEnd(event: MatDatepickerInputEvent<Date>): void {
    this.endDate = event.value ? toLocalMidnight(event.value) : undefined;

    this.updateFormFieldValue();
  }

  private updateFormFieldValue(): void {
    this.control.patchValue(toDateOnlyRangeIsoString(this.startDate, this.endDate), {emitEvent: false});
  }
}

import {
  ArrayHelperProjector,
  ArrayHelperSelectorType,
  GeneratedSelectorCreatorsFor,
  GeneratedSelectorsFor,
  LoadStatusSelectors,
  LoadStatusSubstate,
  MxSelector,
  MxSelectorCreator,
  MxSelectorCreatorBaseObjectType,
  MxSelectorCreatorReturnType,
  NumberHelperProjector,
  NumberHelperSelectorType,
  PersonNameSelectorCreators,
  PersonNameSelectors
} from './store.types';
import {LoadStatus} from './common/common.types';
import {createSelector} from '@ngrx/store';
import {lowerCamelCaseToUpperCamelCase} from './transformation.helpers';
import {ValueFrom} from './utility-type.helpers';
import {firstAndLastNameFrom, firstAndLastNameWithPronounsFrom, firstInitialAndLastNameFrom} from './common/person.helpers';
import {isPopulatedString} from './common/typing.helpers';

export function generateSelectorsDependentOn<ObjectType extends object, NamePrefix extends string>(
  dependencySelector: MxSelector<ObjectType>, fallbackObject: ObjectType, namePrefix: NamePrefix
): GeneratedSelectorsFor<ObjectType, NamePrefix> {
  return Object.entries(fallbackObject).reduce(
    (accumulator, [fallbackPropertyName, fallbackValue]: [string, ValueFrom<ObjectType>]) => {
      const selectorName = namePrefix ? `${namePrefix}${lowerCamelCaseToUpperCamelCase(fallbackPropertyName)}` : fallbackPropertyName;

      const selector: MxSelector<ValueFrom<ObjectType>> =
        createSelector(
          dependencySelector,
          parentObject => parentObject[fallbackPropertyName] ?? fallbackValue
        );

      return {
        ...accumulator,
        [selectorName]: selector
      };
    },
    {} as GeneratedSelectorsFor<ObjectType, NamePrefix>
  );
}

export function generateNumberHelperSelectorDependentOn(numberSelector: MxSelector<number>, type: NumberHelperSelectorType): MxSelector<boolean> {
  return createSelector(
    numberSelector,
    generateNumberHelperProjectorFor(type)
  );
}

function generateNumberHelperProjectorFor(type: NumberHelperSelectorType): NumberHelperProjector {
  switch (type) {
    case 'IS_NEGATIVE':
      return number => number < 0;

    case 'IS_ZERO':
      return number => number === 0

    case 'IS_POSITIVE':
      return number => number > 0
  }
}

export function generateArrayHelperSelectorDependentOn(arraySelector: MxSelector<unknown[]>, type: ArrayHelperSelectorType): MxSelector<boolean> {
  return createSelector(
    arraySelector,
    generateArrayHelperProjectorFor(type)
  );
}

function generateArrayHelperProjectorFor(type: ArrayHelperSelectorType): ArrayHelperProjector {
  switch (type) {
    case 'IS_EMPTY':
      return array => array.length === 0;

    case 'IS_POPULATED':
      return array => array.length > 0;
  }
}

export function generateIndependentSelectorCreatorsFor<ObjectType extends object, NamePrefix extends string>(
  fallbackObject: ObjectType, namePrefix: NamePrefix
): GeneratedSelectorCreatorsFor<ObjectType, NamePrefix, ObjectType> {
  return Object.entries(fallbackObject).reduce(
    (accumulator, [fallbackPropertyName, fallbackValue]: [string, ValueFrom<ObjectType>]) => {
      const selectorCreatorName = namePrefix ? `${namePrefix}${lowerCamelCaseToUpperCamelCase(fallbackPropertyName)}For` : `${fallbackPropertyName}For`;

      const selectorCreator: MxSelectorCreator<[ObjectType], ValueFrom<ObjectType>> =
        parentObject => createSelector(
          () => parentObject[fallbackPropertyName] ?? fallbackValue
        );

      return {
        ...accumulator,
        [selectorCreatorName]: selectorCreator
      };
    },
    {} as GeneratedSelectorCreatorsFor<ObjectType, NamePrefix, ObjectType>
  );
}

export function generateSelectorCreatorsDependentOn<
  DependencySelectorCreator extends MxSelectorCreator<[any], any>, // eslint-disable-line @typescript-eslint/no-explicit-any -- These are literally "any"!
  ObjectType extends object,
  NamePrefix extends string
>(
  dependencySelectorCreator: DependencySelectorCreator, fallbackObject: ObjectType, namePrefix: NamePrefix
): GeneratedSelectorCreatorsFor<ObjectType, NamePrefix, MxSelectorCreatorBaseObjectType<DependencySelectorCreator>> {
  type BaseObjectType = MxSelectorCreatorBaseObjectType<typeof dependencySelectorCreator>;
  type ReturnType = MxSelectorCreatorReturnType<typeof dependencySelectorCreator>;

  return Object.entries(fallbackObject).reduce(
    (accumulator, [key, fallbackValue]: [string, ValueFrom<ObjectType>]) => {
      const selectorCreatorName = namePrefix ? `${namePrefix}${lowerCamelCaseToUpperCamelCase(key)}For` : `${key}For`;

      const selectorCreator: MxSelectorCreator<[BaseObjectType], ReturnType> =
        parentObject => createSelector(
          dependencySelectorCreator(parentObject),
          dependencyValue => dependencyValue[key] ?? fallbackValue
        );

      return {
        ...accumulator,
        [selectorCreatorName]: selectorCreator
      };
    },
    {} as GeneratedSelectorCreatorsFor<ObjectType, NamePrefix, BaseObjectType>
  );
}

export function generateNumberHelperSelectorCreatorDependentOn<ObjectType extends object>(
  numberSelectorCreator: MxSelectorCreator<[ObjectType], number>, type: NumberHelperSelectorType
): MxSelectorCreator<[ObjectType], boolean> {
  return object => createSelector(
    numberSelectorCreator(object),
    generateNumberHelperProjectorFor(type)
  );
}

export function generateArrayHelperSelectorCreatorDependentOn<ObjectType extends object>(
  arraySelectorCreator: MxSelectorCreator<[ObjectType], unknown[]>, type: ArrayHelperSelectorType
): MxSelectorCreator<[ObjectType], boolean> {
  return object => createSelector(
    arraySelectorCreator(object),
    generateArrayHelperProjectorFor(type)
  );
}

export function generateLoadStatusSelectorsFor<T extends LoadStatusSubstate>(substateSelector: MxSelector<T>): LoadStatusSelectors {
  const loadStatus: MxSelector<LoadStatus> = createSelector(
    substateSelector,
    ({loadStatus}) => loadStatus
  );

  return {
    loadStatus,

    preload: createSelector(
      loadStatus,
      status => status === 'PRELOAD'
    ),

    loading: createSelector(
      loadStatus,
      status => status === 'LOADING'
    ),

    awaitingFirstLoad: createSelector(
      loadStatus,
      status => ['PRELOAD', 'LOADING'].includes(status)
    ),

    reloading: createSelector(
      loadStatus,
      status => status === 'RELOADING'
    ),

    awaitingAnyLoad: createSelector(
      loadStatus,
      status => ['PRELOAD', 'LOADING', 'RELOADING'].includes(status)
    ),

    loaded: createSelector(
      loadStatus,
      status => status === 'LOADED'
    ),

    loadFailed: createSelector(
      loadStatus,
      status => status === 'FAILED'
    ),

    finishedLoading: createSelector(
      loadStatus,
      status => ['LOADED', 'FAILED'].includes(status)
    )
  };
}

export function generatePersonNameSelectorsFrom(firstName: MxSelector<string>, lastName: MxSelector<string>, pronounsString: MxSelector<string>, preferredName: MxSelector<string>): PersonNameSelectors {
  const preferredOrFirstName: MxSelector<string> = createSelector(
    preferredName,
    firstName,
    (preferred, first) => isPopulatedString(preferred) ? preferred : first
  )

  return {
    firstAndLastName: createSelector(
      firstName,
      lastName,
      firstAndLastNameFrom
    ),
    firstAndLastNameWithPronouns: createSelector(
      firstName,
      lastName,
      pronounsString,
      firstAndLastNameWithPronounsFrom
    ),
    preferredFullNameWithPronouns: createSelector(
      preferredOrFirstName,
      lastName,
      pronounsString,
      firstAndLastNameWithPronounsFrom
    ),
    firstNameIfNotPreferredName: createSelector(
      firstName,
      preferredName,
      (first, preferred) => isPopulatedString(preferred) ? first : ''
    ),
    preferredOrFirstName,
    fullName: createSelector(
      preferredOrFirstName,
      lastName,
      firstAndLastNameFrom
    ),
    firstInitialAndLastName: createSelector(
      preferredOrFirstName,
      lastName,
      firstInitialAndLastNameFrom
    )
  }
}

export function generatePersonNameSelectorCreatorsFor<T>(firstNameFor: MxSelectorCreator<[T], string>, lastNameFor: MxSelectorCreator<[T], string>): PersonNameSelectorCreators<T> {
  return {
    firstAndLastNameFor: _person => createSelector(
      firstNameFor(_person),
      lastNameFor(_person),
      firstAndLastNameFrom
    ),
    abbreviatedName: _person => createSelector(
      firstNameFor(_person),
      lastNameFor(_person),
      firstInitialAndLastNameFrom
    )
  }
}

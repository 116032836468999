import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {filter, map, Observable, switchMap, tap} from 'rxjs';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {NotificationService} from '@raven';
import {PATH_FOR} from '../../app.routes';
import {Store} from '@ngrx/store';
import {PatronSelectors} from '@store/store.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  private readonly angularFireAuthGuard: AngularFireAuthGuard = inject(AngularFireAuthGuard);
  private readonly router: Router = inject(Router);
  private readonly notificationService: NotificationService = inject(NotificationService);
  private readonly store = inject(Store);

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(PatronSelectors.Auth.finishedLoading).pipe(
      filter((loaded) => loaded),
      switchMap(() => this.angularFireAuthGuard.canActivate(route, state)),
      tap(canActivate => {
        if (!canActivate)
          this.notificationService.showSnackbarInfo('Please login.');
      }),
      map(canActivate => canActivate || this.router.createUrlTree([PATH_FOR['LOGIN']]))
    );
  }
}
